import { Close, Save } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import React from 'react';
import { useConvoDashboardStore } from 'store';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const EditDialogWrapper = ({
  children,
  dialogType,
  title,
  onSubmit,
}) => {
  const dialogState = useConvoDashboardStore((state) => state.editDialog);

  const changeDialogState = useConvoDashboardStore(
    (state) => state.setEditDialog
  );

  const handleClose = () => {
    changeDialogState({ isOpen: false, forItem: 'team' });
  };

  return (
    <Dialog
      open={dialogState.isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={false}
      fullScreen={false}
      component="form"
      onSubmit={onSubmit}
      PaperProps={{
        sx: {
          width: '50vw',
          height: '100vh',
          maxHeight: '100vh',
          position: 'absolute',
          right: 0,
          margin: 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="span">
          {title}
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>{children}</DialogContent>

      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          sx={{ marginRight: 'auto' }}
          type="submit"
        >
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose} startIcon={<Close />}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialogWrapper;
