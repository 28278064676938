export const colorPalette = {
  primary: {
    main: '#29A87E', //'#4c12a1',
  },
  success: {
    main: '#29A87E',
  },
  background: {
    paper: '#FFFFFF',
  },
};
