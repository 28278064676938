import { NoteAlt, QuestionAnswer, TipsAndUpdates } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  keyframes,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { MainContent } from 'components/sidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Dashboard.module.css';

import { RemoveCircleOutline } from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';

import { ActionsDropdown } from 'components/Table';
import { CustomTextField } from 'components/TextField';
import { useLoggedUser } from 'contexts/UserContext';
import { useAssessmentStore } from 'store';
import { getOriginalZonedDate } from 'utils/date';
import { ConsentDialog } from './components/ConsentDialog';
import { StudentDashboardProvider } from './context/dashboardContext';

const RemoveSectionModal = ({
  selectedAssessment,
  open,
  setOpen,
  setLoading,
  getAssessment,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteAssessment(
        selectedAssessment._id
      );
      await getAssessment();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Section</h5>
          <p>
            Are you sure you want to delete this section? This action cannot be
            undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const CreateModal = ({ open, setOpen, setLoading, navigate }) => {
  const [disableButton, setDisableButton] = useState(true);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const [assessmentName, setAssessmentName] = useState('');

  const createAssessment = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      assessmentName,
    };
    try {
      const response = await organizationAPI.createAssessment(data);
      navigate(`/coach/psych/details/${response.data._id}`);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpen(false);
  };

  useEffect(() => {
    if (assessmentName.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [assessmentName]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <h3>Create New Assessment</h3>
        </Typography>

        <div className={styles.modal_buttons}>
          <CustomTextField
            label="Assessment Name"
            placeholder={'Enter Assessment Name'}
            value={assessmentName}
            onChange={(e) => setAssessmentName(e.target.value)}
          />
          <button
            onClick={createAssessment}
            className={styles.create}
            disabled={disableButton}
          >
            Start Now
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const navigate = useNavigate();
  const { setAssessmentSummaryTab } = useAssessmentStore();
  const { user } = useLoggedUser();
  const [assessmentList, setAssessmentList] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [latestItem, setLatestItem] = useState({
    progress: null,
    completed: null,
  });

  function getLatestByStatus(data, status) {
    return data
      .filter((item) => item.status === status)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
  }

  const getAssessmentList = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getStudentAssessmentDashboard();
      const { data } = response;
      setAssessmentList(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (assessmentList.length) {
      const latestCompleted = getLatestByStatus(assessmentList, 'completed');
      const latestProgress = getLatestByStatus(assessmentList, 'progress');
      setLatestItem({ progress: latestProgress, completed: latestCompleted });
    }
  }, [assessmentList]);

  useEffect(() => {
    getAssessmentList();
  }, []);

  const dropAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); 
  }
`;

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}
        <CreateModal
          open={createModalOpen}
          setOpen={setCreateModalOpen}
          setLoading={setLoading}
          getAssessmentList={getAssessmentList}
          navigate={navigate}
        />
        <RemoveSectionModal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          setLoading={setLoading}
          selectedAssessment={selectedAssessment}
          getAssessment={getAssessmentList}
        />

        <div className={styles.content}>
          <StudentDashboardProvider>
            <ConsentDialog />
          </StudentDashboardProvider>
          <div className={styles.learner_header}>
            <h1>{user.firstName + ' ' + user.lastName}</h1>
          </div>
          <div className={styles.border}></div>

          <Stack flexDirection={'row-reverse'} gap={2}>
            <Button
              color={latestItem.progress ? 'error' : 'success'}
              variant="contained"
              size="large"
              sx={{
                animation: `${dropAnimation} 1s ease-in-out infinite`,
                '&:hover': {
                  animation: 'none',
                },
              }}
              onClick={() => {
                if (latestItem.progress) {
                  navigate(
                    `/student/psych/assessment/${latestItem.progress._id}`
                  );
                } else {
                  setAssessmentSummaryTab('tip');
                  navigate(
                    `/student/psych/assessmentsummary/${user._id}/${latestItem.completed._id}`
                  );
                }
              }}
            >
              {latestItem.progress ? 'Take Assessment' : 'Show Tips'}
            </Button>
          </Stack>

          <div className={styles.coach_roaster}>
            <div className={styles.learner_table}>
              {assessmentList.length > 0 && (
                <table className="styled_table">
                  <thead className="table_headers">
                    <tr>
                      <th>Assessment</th>
                      <th>Status</th>
                      <th>Created at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessmentList.map((item, index) => {
                      const status = item.status.toUpperCase();
                      return (
                        <tr className={styles.coachrow} key={item._id}>
                          <td className={styles.data}>{item.assessmentName}</td>

                          <td className={styles.data}>
                            <Chip
                              variant="outlined"
                              size="small"
                              color={
                                status === 'COMPLETED' ? 'success' : 'info'
                              }
                              label={status}
                            />
                          </td>
                          <td>
                            <Typography>
                              {getOriginalZonedDate({ date: item.createdAt })}
                            </Typography>
                          </td>
                          <td className={styles.data}>
                            <ActionsDropdown
                              actions={
                                item.status === 'completed'
                                  ? [
                                      {
                                        label: 'Answers',
                                        icon: <QuestionAnswer color="info" />,
                                        onClick: () => {
                                          setAssessmentSummaryTab('answer');
                                          navigate(
                                            `/student/psych/assessmentsummary/${user._id}/${item._id}`
                                          );
                                        },
                                      },
                                      {
                                        label: 'Tips',
                                        icon: (
                                          <TipsAndUpdates color="success" />
                                        ),
                                        onClick: () => {
                                          setAssessmentSummaryTab('tip');
                                          navigate(
                                            `/student/psych/assessmentsummary/${user._id}/${item._id}`
                                          );
                                        },
                                      },
                                    ]
                                  : [
                                      {
                                        label: 'Take Assessment',
                                        icon: <NoteAlt color="success" />,
                                        onClick: () => {
                                          navigate(
                                            `/student/psych/assessment/${item._id}`
                                          );
                                        },
                                      },
                                    ]
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              {assessmentList.length === 0 && (
                <div className={styles.empty_table}>
                  <p>You dont have any assessment yet...</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
