//Set Envionment
//local, qa, prod
const envValue = 'prod';

exports.getEnv = () => {
  return envValue;
};

exports.getBaseUrl = () => {
  if (envValue === 'prod') {
    return 'https://www.tieroneai.com';
  } else if (envValue === 'qa') {
    return 'https://qatieroneai.com';
  } else if (envValue === 'local') {
    return 'http://localhost:5000';
  } else {
    throw new Error('Invalid eniornment');
  }
};
