import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Dashboard.module.css';
import { MainContent, Sidebar } from 'components/sidebar';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Person from '../../../images/Ellipse.png';
import Person2 from '../../../images/person2.png';
import Person3 from '../../../images/person3.png';
import Dial from '../../../images/dial.png';
import Menu from '../../../images/menubar.png';
import Role from '../../../images/role.png';
import LoadingOverlay from 'components/LoadingOverlay';
import { MenuIcon } from 'svg/CustomSVGIcon';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';


const DocumentDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [onCall, setOnCall] = useState(false);
  const [user, setUser] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const handleView = () => {
    navigate('/student/coversationalai/transcript');
  };

  const [name, setName] = useState('');
  const [personalities, setPersonalities] = useState('');
  const [img, setImg] = useState('');
  return (
    <>
      {loading && <LoadingOverlay />}
      <MainContent active="canvas" user={user}>
        <div className={styles.content}>
          <h1>Conversational AI</h1>
          <div className={styles.border}></div>

          <div className={styles.coach_roaster}>
            <div className={styles.coach_roaster_left}>
              <h3>Available Business Plan Judge Personalities</h3>
              <div className={styles.list_section}>
                <div className={styles.personalities}>
                  <div className={styles.profile}>
                    <img src={Person} alt="" />

                    <div className={styles.profile_name}>
                      <h4>Mark Cuban</h4>
                      <p>Shark, Shark Tank</p>
                    </div>
                  </div>
                  <div className={styles.profile_right}>
                    <span>Cordial</span>
                    <img
                      src={Dial}
                      alt=""
                      onClick={() => {
                        setName('Mark Cuban');
                        setPersonalities('Cordial');
                      }}
                    />

                    <img src={Menu} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.list_section}>
                <div className={styles.personalities}>
                  <div className={styles.profile}>
                    <img src={Person3} alt="" />

                    <div className={styles.profile_name}>
                      <h4>Lori Greiner</h4>
                      <p>Shark, Shark Tank</p>
                    </div>
                  </div>
                  <div className={styles.profile_right}>
                    <span>Rude</span>
                    <img
                      src={Dial}
                      alt=""
                      onClick={() => {
                        setName('Lori Greiner');
                        setPersonalities('Rude');
                      }}
                    />

                    <img src={Menu} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.list_section}>
                <div className={styles.personalities}>
                  <div className={styles.profile}>
                    <img src={Person2} alt="" />

                    <div className={styles.profile_name}>
                      <h4>Daymond John</h4>
                      <p>Shark, Shark Tank</p>
                    </div>
                  </div>
                  <div className={styles.profile_right}>
                    <span>Depressive</span>
                    <img
                      src={Dial}
                      alt=""
                      onClick={() => {
                        setName('Daymond John');
                        setPersonalities('Depressive');
                      }}
                    />

                    <img src={Menu} alt="" />
                  </div>
                </div>
              </div>

              <div className={styles.past_calls}>
                <h6>Past Calls</h6>
                <div className={styles.past_call_list}>
                  <div className={styles.call_left}>
                    <img src={Person} alt="" />

                    <h4>Mark Cuban</h4>
                  </div>
                  <div className={styles.call_right}>
                    <DateRangeOutlinedIcon />

                    <span>3:32 PM, Sun, Mar 17, 2024</span>
                    <CallOutlinedIcon />
                    <span>9m 16s</span>
                    <RemoveRedEyeOutlinedIcon
                      className={styles.view_icon}
                      onClick={handleView}
                    />
                  </div>
                </div>
                <div className={styles.past_call_list}>
                  <div className={styles.call_left}>
                    <img src={Person} alt="" />

                    <h4>Mark Cuban</h4>
                  </div>
                  <div className={styles.call_right}>
                    <DateRangeOutlinedIcon />

                    <span>3:32 PM, Sun, Mar 17, 2024</span>
                    <CallOutlinedIcon />
                    <span>9m 16s</span>
                    <RemoveRedEyeOutlinedIcon
                      onClick={handleView}
                      className={styles.view_icon}
                    />
                  </div>
                </div>
                <div className={styles.past_call_list}>
                  <div className={styles.call_left}>
                    <img src={Person} alt="" />

                    <h4>Mark Cuban</h4>
                  </div>
                  <div className={styles.call_right}>
                    <DateRangeOutlinedIcon />

                    <span>3:32 PM, Sun, Mar 17, 2024</span>
                    <CallOutlinedIcon />
                    <span>9m 16s</span>
                    <RemoveRedEyeOutlinedIcon
                      onClick={handleView}
                      className={styles.view_icon}
                    />
                  </div>
                </div>
                <div className={styles.past_call_list}>
                  <div className={styles.call_left}>
                    <img src={Person} alt="" />

                    <h4>Mark Cuban</h4>
                  </div>
                  <div className={styles.call_right}>
                    <DateRangeOutlinedIcon />

                    <span>3:32 PM, Sun, Mar 17, 2024</span>
                    <CallOutlinedIcon />
                    <span>9m 16s</span>
                    <RemoveRedEyeOutlinedIcon
                      onClick={handleView}
                      className={styles.view_icon}
                    />
                  </div>
                </div>

                {isExpanded && (
                  <>
                    <div className={styles.past_call_list}>
                      <div className={styles.call_left}>
                        <img src={Person} alt="" />

                        <h4>Mark Cuban</h4>
                      </div>
                      <div className={styles.call_right}>
                        <DateRangeOutlinedIcon />

                        <span>3:32 PM, Sun, Mar 17, 2024</span>
                        <CallOutlinedIcon />
                        <span>9m 16s</span>
                        <RemoveRedEyeOutlinedIcon
                          className={styles.view_icon}
                          onClick={handleView}
                        />
                      </div>
                    </div>
                    <div className={styles.past_call_list}>
                      <div className={styles.call_left}>
                        <img src={Person} alt="" />

                        <h4>Mark Cuban</h4>
                      </div>
                      <div className={styles.call_right}>
                        <DateRangeOutlinedIcon />

                        <span>3:32 PM, Sun, Mar 17, 2024</span>
                        <CallOutlinedIcon />
                        <span>9m 16s</span>
                        <RemoveRedEyeOutlinedIcon
                          onClick={handleView}
                          className={styles.view_icon}
                        />
                      </div>
                    </div>
                    <div className={styles.past_call_list}>
                      <div className={styles.call_left}>
                        <img src={Person} alt="" />

                        <h4>Mark Cuban</h4>
                      </div>
                      <div className={styles.call_right}>
                        <DateRangeOutlinedIcon />

                        <span>3:32 PM, Sun, Mar 17, 2024</span>
                        <CallOutlinedIcon />
                        <span>9m 16s</span>
                        <RemoveRedEyeOutlinedIcon
                          onClick={handleView}
                          className={styles.view_icon}
                        />
                      </div>
                    </div>
                    <div className={styles.past_call_list}>
                      <div className={styles.call_left}>
                        <img src={Person} alt="" />

                        <h4>Mark Cuban</h4>
                      </div>
                      <div className={styles.call_right}>
                        <DateRangeOutlinedIcon />

                        <span>3:32 PM, Sun, Mar 17, 2024</span>
                        <CallOutlinedIcon />
                        <span>9m 16s</span>
                        <RemoveRedEyeOutlinedIcon
                          onClick={handleView}
                          className={styles.view_icon}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className={styles.expand}>
                  <button onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? (
                      <KeyboardArrowUpOutlinedIcon />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon />
                    )}
                    {isExpanded ? 'VIEW LESS' : 'VIEW ALL'}
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.coach_roaster_right}>
              <h3>Business Plan Mentor/Coach/Volunteer</h3>

              {name ? (
                <div className={styles.roleplay}>
                  <h5>AI Roleplay Instructions</h5>
                  <p>
                    A lifelong entrepreneur and investor, Cuban has started and
                    built multiple industry-changing organizations.
                  </p>
                  {onCall && <p>9m 16s</p>}
                  <img src={Role} alt="" />
                  <h6>{name}</h6>
                  <p>Shark, Shark Tank</p>
                  <span>{personalities}</span>

                  <div className={styles.call_button}>
                    {onCall ? (
                      <button
                        className={styles.end_button}
                        onClick={handleView}
                      >
                        End Call
                      </button>
                    ) : (
                      <button onClick={() => setOnCall(true)}>
                        Start Competition/Call
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.roleplay}>
                  <h5>AI Roleplay Instructions</h5>
                  <p>
                    A lifelong entrepreneur and investor, Cuban has started and
                    built multiple industry-changing organizations.
                  </p>
                  <h1>Make A Call</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default DocumentDashboard;
