import { MainContent } from 'components/sidebar';
import { useEffect, useState } from 'react';
import styles from './styles/ClassSummary.module.css';

import organizationAPI from 'api/api';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingOverlay from 'components/LoadingOverlay';

import { Assessment, Checklist, ChevronLeft } from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';

import { CustomTextField } from 'components/TextField';

const Tips = ({ tipsBreakdown, totalStudents }) => {
  return (
    <div className={styles.grade_section}>
      <div className={styles.grade_section_body}>
        <div className={styles.assessment_answer_body}>
          {tipsBreakdown &&
            tipsBreakdown
              .sort(
                (a, b) =>
                  b.count - a.count || a.tipText.localeCompare(b.tipText)
              )
              .map((item, index) => (
                <div className={styles.assessment_answer_progress_item}>
                  <div
                    className={styles.assessment_answer_progress_bar}
                    style={{
                      // max width is 100%
                      width:
                        Math.min((item.count * 100) / totalStudents, 100) + '%',
                    }}
                  ></div>
                  <p>{`${item.count}: ${
                    item.tipText.length > 200
                      ? item.tipText.substring(0, 200) + '...'
                      : item.tipText
                  }`}</p>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

const AssessmentAnswer = ({ assessmentAnswer, totalStudents }) => {
  return (
    <div className={styles.grade_section}>
      {assessmentAnswer &&
        Object.keys(assessmentAnswer).map((itemKey, index) => (
          <div className={styles.assessment_answer_list} key={index}>
            <div className={styles.assessment_body}>
              <div className={styles.assement_answer_header}>
                <h3>{itemKey}</h3>
                {/* <p>Score Subtotal: 17/20</p> */}
              </div>
              <div className={styles.border}></div>
            </div>
            <div className={styles.assessment_mcq}>
              {assessmentAnswer[itemKey].map((item, questionIndex) => (
                <div>
                  <h2 className={styles.review_title_h2}>
                    {`Question ${questionIndex + 1}: ${item.question}`}
                  </h2>
                  <div className={styles.assessment_answer_body}>
                    {item.breakdown.map((question, index) => (
                      <div className={styles.assessment_answer_progress_item}>
                        <div
                          className={styles.assessment_answer_progress_bar}
                          style={{
                            // max width is 100%
                            width:
                              Math.min(
                                (question.count * 100) / totalStudents,
                                100
                              ) + '%',
                          }}
                        ></div>
                        <p>{`${question.count}: ${question.selection}`}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

const GradeSummary = ({ gradeSummary, totalStudents }) => {
  const [sortedGradeSummary, setSortedGradeSummary] = useState(null);
  useEffect(() => {
    if (gradeSummary) {
      const sorted = gradeSummary.sort((a, b) => {
        if (a.totalStudents > b.totalStudents) {
          return -1;
        }
        if (a.totalStudents < b.totalStudents) {
          return 1;
        }
        return 0;
      });
      setSortedGradeSummary(sorted);
    }
  }, [gradeSummary]);
  return (
    <div className={styles.grade_section}>
      <div className={styles.grade_section_body}>
        {sortedGradeSummary &&
          sortedGradeSummary.map((item, index) => (
            <div className={styles.grade_summary_progress_item}>
              <div
                className={styles.grade_summary_progress_bar}
                style={{
                  width:
                    Math.min((item.totalStudents * 100) / totalStudents, 100) +
                    '%',
                }}
              ></div>
              <p>{`${item.totalStudents}: ${item.title}`}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [activeMenu, setActiveMenu] = useState('grade');
  const [gradeSummary, setGradeSummary] = useState(null);

  const location = useLocation();
  const assessmentId = location.pathname.split('/')[5];
  const courseId = location.pathname.split('/')[4];

  const handleCandidateNavigation = (e) => {
    navigate(`/coach/psych/candidates/${assessmentId}`);
  };

  const getAssessmentSummary = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentCourseGradeSummary(
        assessmentId,
        courseId
      );

      setGradeSummary(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getAssessmentSummary();
  }, []);

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}

        <div className={styles.content}>
          <div className={styles.learner_header}>
            <h1>Class Summary</h1>

            <div className={styles.learner_header_buttons}>
              <button
                c
                className={styles.back}
                onClick={handleCandidateNavigation}
              >
                <ChevronLeft />
                Return to Candidate List
              </button>
              {/* <button className={styles.summarize_button}>
                <Refresh />
                Enable Retaking
              </button> */}
            </div>
          </div>
          <div className={styles.border}></div>
          <div className={styles.assessment_summary_header}>
            <CustomTextField
              label="Assessment Name"
              value={`${gradeSummary?.assessmentName}`}
              readOnly
              fullWidth
            />
          </div>

          <div className={styles.grade_section_header_card}>
            <div className={styles.header_card_right}>
              <h5>COURSE</h5>
              <p>{gradeSummary?.courseName}</p>
            </div>

            <div className={styles.header_card_right}>
              <h5>ASSESSMENT TAKEN BY</h5>
              <p>{`${gradeSummary?.totalCompleted} candidates`}</p>
            </div>

            <div className={styles.header_card_right}>
              <h5>ASSESSMENT NOT TAKEN BY</h5>
              <p>{`${gradeSummary?.totalIncomplete} candidates`}</p>
            </div>

            <div className={styles.header_card_right}>
              <h5>RETAKING ENABLED</h5>
              <p>No</p>
            </div>
          </div>

          <div className={styles.assessment_summary_nav}>
            <ul>
              <li
                className={activeMenu === 'grade' ? styles.active : ''}
                onClick={() => setActiveMenu('grade')}
              >
                <Assessment />
                <p>Grade Summary</p>
              </li>
              <li
                className={activeMenu === 'answer' ? styles.active : ''}
                onClick={() => setActiveMenu('answer')}
              >
                <Checklist />
                <p>Assessment Answers</p>
              </li>
              <li
                className={activeMenu === 'tip' ? styles.active : ''}
                onClick={() => setActiveMenu('tip')}
              >
                <Checklist />
                <p>Tips</p>
              </li>
            </ul>
          </div>

          {activeMenu === 'grade' && (
            <GradeSummary
              gradeSummary={gradeSummary?.courseBreakdown}
              totalStudents={gradeSummary?.totalStudents}
            />
          )}
          {activeMenu === 'answer' && (
            <AssessmentAnswer
              assessmentAnswer={gradeSummary?.questionsBreakdown}
              totalStudents={gradeSummary?.totalStudents}
            />
          )}
          {activeMenu === 'tip' && (
            <Tips
              tipsBreakdown={gradeSummary?.tipsBreakdown}
              totalStudents={gradeSummary?.totalStudents}
            />
          )}
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
