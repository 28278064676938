import { Stack } from '@mui/material';
import { CandidateInfo, ProgressChart, ProgressReport } from '.';
export const Performance = () => {
  return (
    <Stack spacing={3} gap={2}>
      <CandidateInfo />
      <ProgressChart />
      <ProgressReport />
    </Stack>
  );
};

export default Performance;
