import { useCallback } from 'react';

const useFormattedTime = () => {
  const formatTime = useCallback((timestamp) => {
    if (!timestamp) return '00:00';

    const date = new Date(timestamp);
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${minutes}:${seconds}`;
  }, []);

  return { formatTime };
};

export default useFormattedTime;
