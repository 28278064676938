import Box from '@mui/material/Box';
const EmptyState = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '99%',
        height: '180px',
        border: '1px solid #DAE8E7',
        borderRadius: '8px',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          fontFamily: 'Lexend, sans-serif',
          fontWeight: 300,
          fontSize: '20px',
          lineHeight: '30px',
          color: '#5B706F',
        }}
      >
        No data available yet.
      </Box>
    </Box>
  );
};

export default EmptyState;
