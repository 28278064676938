import React from 'react';
import './styles/App.css';
import 'rsuite/DateRangePicker/styles/index.css';
import { AppRoutes } from 'routes';
import AppContextProvider from 'contexts/AppContext';

function App() {
  return (
    <AppContextProvider>
      <AppRoutes />
    </AppContextProvider>
  );
}

export default App;
