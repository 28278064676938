import organizationAPI from 'api/api';
import {
  CallHistoryCard,
  EmptyState,
  RaderChartLegend,
  SearchBar,
  SummaryInfo,
  UploadAudio,
  Widget,
} from 'components/CustomMUI';
import RadarChart from 'components/CustomMUI/RaderChart';
import LoadingOverlay from 'components/LoadingOverlay';
import { MainContent } from 'components/sidebar';
import { useApi, useGetMe } from 'hooks';
import useNotification from 'hooks/useNotification';
import prettyMilliseconds from 'pretty-ms';
import { useEffect, useState } from 'react';
import useNotificationStore from 'store/useNotificationStore';
import { PhoneIcon } from 'svg/CustomSVGIcon';
import styles from './styles/Style.module.css';

const Header = ({ title, children }) => {
  return (
    <div className={styles.header}>
      <div className={styles.header_container}>
        <h1>{title}</h1>
        {children}
      </div>
      <div className={styles.border2}></div>
    </div>
  );
};

const ConvoLeaderboard = () => {
  const { user, loading, setLoading } = useGetMe({});
  const [statsData, setStatsData] = useState();
  const [callHistoryData, setCallHistoryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [spiderChartData, setSpiderChartData] = useState([]);
  const { show, NotificationComponent } = useNotification();
  const { notification } = useNotificationStore();
  const [individualStrengths, setIndividualStrengths] = useState();
  const [individualWeaknesses, setIndividualWeaknesses] = useState();
  const [teamStrengths, setTeamStrengths] = useState();
  const [teamWeaknesses, setTeamWeaknesses] = useState();

  const { call: getUserStatsCall } = useApi({
    fetcher: organizationAPI.getUserStats,
    successMessage: 'User Stats Fetched Successfully!',
  });

  const { call: getCallHistoryCall } = useApi({
    fetcher: organizationAPI.getCallHistory,
    successMessage: 'User Stats Fetched Successfully!',
  });

  const { call: getSpiderChartCall } = useApi({
    fetcher: organizationAPI.getSpiderChart,
    successMessage: 'User Stats Fetched Successfully!',
  });

  const { call: getIndividualPerformanceAnalyzerCall } = useApi({
    fetcher: organizationAPI.individualPerformanceAnalyzer,
    successMessage:
      'Individual Performance Analyzer Data Fetched Successfully!',
  });
  const { call: getTeamPerformanceAnalyzerCall } = useApi({
    fetcher: organizationAPI.teamPerformanceAnalyzer,
    successMessage: 'Team Performance Analyzer Data Fetched Successfully!',
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = callHistoryData.filter(
    (item) =>
      item.team.toLowerCase().includes(searchTerm) ||
      item.persona.some((persona) =>
        persona.name.toLowerCase().includes(searchTerm)
      )
  );

  useEffect(() => {
    const fetchUserStatsResponseData = async () => {
      try {
        setLoading(true);
        const response = await getUserStatsCall();
        setStatsData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchCallHistoryResponseData = async () => {
      try {
        setLoading(true);
        const response = await getCallHistoryCall();
        setCallHistoryData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchSpiderChartResponseData = async () => {
      try {
        setLoading(true);
        const response = await getSpiderChartCall();
        setSpiderChartData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchIndividualPerformanceAnalyzerResponseData = async () => {
      try {
        setLoading(true);
        const response = await getIndividualPerformanceAnalyzerCall();
        if (response) {
          const responseStrengthData = {
            title: `${response.data.performanceAnalysis.type} Strengths`,
            analysis: response.data.performanceAnalysis.analysis.strengths,
          };
          const responseWeaknessData = {
            title: `${response.data.performanceAnalysis.type} Weaknesses`,
            analysis: response.data.performanceAnalysis.analysis.weaknesses,
          };
          setIndividualStrengths(responseStrengthData);
          setIndividualWeaknesses(responseWeaknessData);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchTeamPerformanceAnalyzerResponseData = async () => {
      try {
        setLoading(true);
        const response = await getTeamPerformanceAnalyzerCall();
        if (response) {
          const responseStrengthData = {
            title: `${response.data.performanceAnalysis.type} Strengths`,
            analysis: response.data.performanceAnalysis.analysis.strengths,
          };
          const responseWeaknessData = {
            title: `${response.data.performanceAnalysis.type} Weaknesses`,
            analysis: response.data.performanceAnalysis.analysis.weaknesses,
          };
          setTeamStrengths(responseStrengthData);
          setTeamWeaknesses(responseWeaknessData);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserStatsResponseData();
    fetchCallHistoryResponseData();
    fetchSpiderChartResponseData();
    fetchIndividualPerformanceAnalyzerResponseData();
    fetchTeamPerformanceAnalyzerResponseData();
  }, []);

  useEffect(() => {
    if (notification) {
      show(notification.message, notification.type);
    }
  }, [notification, show]);

  return (
    <MainContent active={'query'} user={user}>
      {!notification && loading ? <LoadingOverlay></LoadingOverlay> : null}
      {notification && !loading ? (
        <NotificationComponent
          notification={notification.message}
          type={notification.type}
        />
      ) : null}
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              <h1>Dashboard </h1>
              <div className={styles.nav_right}>
                {/* <div className={styles.nav_btn1}>
                  <CustomCheckbox label={'AI Roleplay'} color={'#2aa87e'} />
                  <CustomCheckbox label={'Live Calls'} color={'#2aa87e'} />
                </div>
                <CustomDatepicker label={'Select date range'} /> */}
                <UploadAudio />
                <a className={styles.nav_btn2} href="/airoleplaycall">
                  <PhoneIcon fill={'#00706c'} />
                  <p>Start AI Roleplay Call</p>
                </a>
              </div>
            </div>
            <div className={styles.border}></div>
          </div>
          <div className={styles.container}>
            {user && (
              <Header title={`${user.firstName} ${user.lastName}: My Stats`} />
            )}
            {statsData ? (
              <div className={styles.widget_container}>
                <Widget
                  title={'Total Calls Duration'}
                  info={prettyMilliseconds(statsData.totalCallDuration.value)}
                  percentage={statsData.totalCallDuration.percentage}
                  increase={statsData.totalCallDuration.hasIncreased}
                />
                <Widget
                  title={'Total Calls'}
                  info={statsData.totalCalls.value}
                  percentage={statsData.totalCalls.percentage}
                  increase={statsData.totalCalls.hasIncreased}
                />
                <Widget title={'My Team'} info={statsData.teamMembers} />
                <Widget
                  title={'Average Scorecard'}
                  info={`${statsData.averageScore.value} / ${statsData.averageMaxScore}`}
                  percentage={statsData.averageScore.percentage}
                  increase={statsData.averageScore.hasIncreased}
                />
              </div>
            ) : (
              <EmptyState />
            )}

            <Header title={'Performance Summary'} />
            <div className={styles.summary_container}>
              <div className={styles.summary_left}>
                <div className={styles.chart}>
                  {spiderChartData ? (
                    <RadarChart
                      labels={spiderChartData.labels}
                      datasets={spiderChartData.datasets}
                    />
                  ) : (
                    <EmptyState />
                  )}
                </div>

                {spiderChartData && spiderChartData.datasets && (
                  <div className={styles.summary_left_nav}>
                    <RaderChartLegend
                      color={'rgba(0, 112, 108, 1)'}
                      title={spiderChartData.datasets[0].label}
                    />
                    <RaderChartLegend
                      color={'rgba(54, 162, 235, 1)'}
                      title={spiderChartData.datasets[1].label}
                    />
                  </div>
                )}
              </div>
              {individualStrengths && teamStrengths ? (
                <div className={styles.summary_right}>
                  {individualStrengths && (
                    <SummaryInfo data={individualStrengths} />
                  )}
                  {individualWeaknesses && (
                    <SummaryInfo data={individualWeaknesses} />
                  )}
                  {teamStrengths && <SummaryInfo data={teamStrengths} />}
                  {teamWeaknesses && <SummaryInfo data={teamWeaknesses} />}
                </div>
              ) : (
                <EmptyState />
              )}
            </div>
            <Header title={'Call History'}>
              <div className={styles.nav_right}>
                <SearchBar
                  handleSearch={handleSearchChange}
                  searchText={searchTerm}
                />
                {/* <button className={styles.nav_btn2}>
                  <PhoneIcon fill={'#00706c'} />
                  <p>Review Call Records</p>
                </button> */}
              </div>
            </Header>

            {!searchTerm && callHistoryData ? (
              callHistoryData.map((item, index) => (
                <CallHistoryCard key={index} data={item} />
              ))
            ) : (
              <EmptyState />
            )}

            {searchTerm &&
              filteredData.map((item, index) => (
                <CallHistoryCard key={index} data={item} />
              ))}
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default ConvoLeaderboard;
