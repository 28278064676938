import { Check, WarningAmber } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Collapse } from '@mui/material';
import apis from 'api/api';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useStudentDashboardContext } from './context/dashboardContext';

export const Acknowledgement = () => {
  const [isAcknowledged, setIsAcknowledged] = useState(undefined);
  const { setData, loggedUserInfo } = useStudentDashboardContext();

  const { user: loggedUser } = useLoggedUser();

  const { call: setAsAcknowledged } = useApi({
    fetcher: () => apis.setAcknowledgement({ userId: loggedUser._id }),
    successMessage: 'Acknowledgment sent successfully',
  });

  useEffect(() => {
    if (loggedUserInfo?.user) {
      setIsAcknowledged(loggedUserInfo?.user?.isConsentAcknowledged ?? false);
    }
  }, [loggedUserInfo]);

  useEffect(() => {
    setData((prev) => ({ ...prev, isAcknowledged: isAcknowledged }));
  }, [isAcknowledged]);

  return (
    <Collapse in={isAcknowledged === false}>
      <Alert
        variant="outlined"
        severity="success"
        icon={<WarningAmber />}
        sx={{ '& .MuiAlert-action': { alignItems: 'center' } }}
        action={
          <Button
            variant="outlined"
            startIcon={<Check />}
            onClick={async () => {
              await setAsAcknowledged();
              setIsAcknowledged(true);
            }}
          >
            Acknowledge
          </Button>
        }
      >
        <AlertTitle>Guardian Consent Required!</AlertTitle>
        Be sure to get permission from your guardian (such as your mom or dad)!
        Ask your guardian to contact us directly if they have any questions or
        concerns.
      </Alert>
    </Collapse>
  );
};
