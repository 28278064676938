import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IndividualCallHistory from './IndividualCallHistory';

export const CallHistoryCard = ({ data }) => {
  return (
    <Card
      sx={{
        border: '1px solid #dae8e7',
        borderRadius: '8px',
        width: '100%',
        boxShadow: 'none',
        height: '100%',
      }}
    >
      {data && (
        <CardContent sx={{ padding: '20px' }}>
          <Typography
            variant="h4"
            sx={{
              color: '#40806a',
              fontFamily: 'Lexend',
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.team}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              alignItems: 'start',
              width: '100%',
              mt: '15px',
            }}
          >
            {data.persona.map((item, index) => (
              <IndividualCallHistory
                image={item.avatar}
                username={item.name}
                key={index}
                data={item}
              />
            ))}
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default CallHistoryCard;
