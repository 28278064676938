import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import CheckIcon from '@mui/icons-material/Check';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { keyframes, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

/**
 * @typedef {Object} ChecklistItem
 * @property {string} id - Unique identifier for the item
 * @property {string} label - Text to display for the item
 * @property {boolean} isComplete - Whether the item is complete
 */

/**
 * A loading spinner component with a dynamic checklist
 * @param {Object} props
 * @param {boolean} props.isLoading - Whether the loading overlay is visible
 * @param {ChecklistItem[]} props.checklistItems - Array of checklist items to display
 * @param {string} [props.title="Generating Response..."] - Title to display above the checklist
 */

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    color: transparent;
  }
  50% {
    transform: scale(1.2);
    color: #00FF9DFF; /* Change this color as desired */
  }
  100% {
    transform: scale(1);
    color: #00FF9DFF;
  }
`;

const AnimatedIcon = styled(AutoAwesomeSharpIcon)(({ theme }) => ({
  animation: `${pulseAnimation} 1.5s infinite ease-in-out`,
  fontSize: '3rem',
  marginBottom: '10px',
}));

export const DynamicLoadingChecklist = ({
  isLoading,
  checklistItems = [],
  title = 'Generating Response...',
}) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: '100%',
        height: '100%',
      }}
      open={isLoading}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 1,
          p: 3,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '20px 40px',
            borderRadius: '8px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <CircularProgress size={16} color="success" />
            <Typography variant="h6" component="div" color="#000" gutterBottom>
              {title}
            </Typography>
          </Box>
          {checklistItems.length > 0 && (
            <List>
              {checklistItems.map(({ id, label, isComplete }) => (
                <ListItem key={id}>
                  <ListItemIcon>
                    {isComplete ? (
                      <CheckIcon sx={{ color: green[500] }} />
                    ) : (
                      <CircularProgress size={16} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    color="#000"
                    sx={{ color: '#000' }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        {/* <AnimatedIcon /> */}
      </Box>
    </Backdrop>
  );
};

export default DynamicLoadingChecklist;
