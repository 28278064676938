import organizationAPI from 'api/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import io from 'socket.io-client';
import usePersonaStore from 'store/personaStore';

const useDeepgram = (token, socketUrl, isMuted, personaId) => {
  const [microphone, setMicrophone] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [audioBuffer, setAudioBuffer] = useState(null);
  const socketRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const [llmResponse, setLlmResponse] = useState('');
  const [aiGeneratedResponse, setAiGeneratedResponse] = useState('');
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [humanResponse, setHumanResponse] = useState('');
  const [isIdle, setIsIdle] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [docId, setDocId] = useState('');
  const [error, setError] = useState(null);
  const { selectedPersona } = usePersonaStore();
  const audioChunksRef = useRef([]);

  const combinedAudioBuffersRef = useRef([]);
  const [isSaving, setIsSaving] = useState(false);

  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  const imageUrl =
    selectedPersona.avatar && selectedPersona.avatar !== 'invalid'
      ? selectedPersona.avatar
      : selectedPersona.gender === 'male'
      ? maleImage
      : femaleImage;

  const formatTime = useMemo(
    () => (timestamp) => {
      const date = new Date(timestamp);
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      return `${minutes}:${seconds}`;
    },
    []
  );

  const removeSilence = useCallback(
    (audioBuffer, threshold = 0.005, minSilenceDuration = 0.3) => {
      const numberOfChannels = audioBuffer.numberOfChannels;
      const sampleRate = audioBuffer.sampleRate;
      const silenceThresholds = Array.from(
        { length: numberOfChannels },
        (_, channel) => {
          const channelData = audioBuffer.getChannelData(channel);
          const maxAmplitude = channelData.reduce(
            (max, value) => Math.max(max, Math.abs(value)),
            0
          );
          return threshold * maxAmplitude;
        }
      );

      const minSilenceSamples = minSilenceDuration * sampleRate;

      let nonSilentRanges = [];
      let silenceStart = 0;
      let isInSilence = true;

      for (let i = 0; i < audioBuffer.length; i++) {
        const isSilent = silenceThresholds.every(
          (silenceThreshold, channel) => {
            const amplitude = Math.abs(audioBuffer.getChannelData(channel)[i]);
            return amplitude <= silenceThreshold;
          }
        );

        if (!isSilent) {
          if (isInSilence) {
            if (i - silenceStart >= minSilenceSamples) {
              nonSilentRanges.push([i, i]);
            } else {
              nonSilentRanges.push([silenceStart, i]);
            }
            isInSilence = false;
          }
          nonSilentRanges[nonSilentRanges.length - 1][1] = i;
        } else if (!isInSilence) {
          silenceStart = i;
          isInSilence = true;
        }
      }

      const totalNonSilentSamples = nonSilentRanges.reduce(
        (sum, [start, end]) => sum + (end - start + 1),
        0
      );
      const newBuffer = new AudioContext().createBuffer(
        numberOfChannels,
        totalNonSilentSamples,
        sampleRate
      );

      let offset = 0;
      for (const [start, end] of nonSilentRanges) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
          const channelData = audioBuffer.getChannelData(channel);
          newBuffer.copyToChannel(
            channelData.subarray(start, end + 1),
            channel,
            offset
          );
        }
        offset += end - start + 1;
      }

      return newBuffer;
    },
    []
  );

  useEffect(() => {
    let isSocketActive = true;

    if (isSocketActive) {
      try {
        socketRef.current = io(socketUrl, {
          transports: ['websocket'],
          auth: { token },
        });

        socketRef.current.on('connect', () => {
          console.log(
            `client: connected to websocket, with id: ${socketRef.current.id}`
          );
          socketRef.current.emit('persona-id', personaId);
        });

        socketRef.current.on('connect_error', (err) => {
          setError({ type: 'SOCKET_CONNECTION_ERROR', message: err.message });
        });

        socketRef.current.on('transcript', (newTranscript) => {
          if (newTranscript !== '') {
            setTranscript(newTranscript);
          }
        });

        socketRef.current.on('llm-response', async (data) => {
          if (data) {
            setLlmResponse(data);
            await fetchAndProcessAudio(data.answer);
          }
        });

        socketRef.current.on('ai-generated-answer', async (data) => {
          if (data) {
            setAiGeneratedResponse(data);
          }
        });

        socketRef.current.on('is-speaking', async (data) => {
          if (data) {
            setIsSpeaking(data);
          }
        });

        socketRef.current.on('is-idle', async (data) => {
          if (data) {
            setIsIdle(data);
          }
        });

        socketRef.current.on('human-response', async (data) => {
          if (data) {
            setHumanResponse(data);
          }
        });
        socketRef.current.on('doc-id', async (data) => {
          if (data) {
            setDocId(data);
          }
        });

        socketRef.current.on('microphone-pause', closeMicrophone);
        socketRef.current.on('microphone-resume', resumeMicrophone);

        socketRef.current.on('error', (error) => {
          setError({ type: 'SOCKET_ERROR', message: error.message });
        });

        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
      } catch (err) {
        setError({ type: 'INITIALIZATION_ERROR', message: err.message });
      }
    }
    return () => {
      if (socketRef.current && isSocketActive) {
        socketRef.current.disconnect();
        console.log('Socket disconnected');
      }

      if (
        audioContextRef.current &&
        audioContextRef.current.state !== 'closed'
      ) {
        audioContextRef.current.close();
        console.log('AudioContext closed');
      }

      isSocketActive = false;
    };
  }, []);

  useEffect(() => {
    const addResponseData = (newData) => {
      setResponseData((prevData) => {
        const exists = prevData.some(
          (item) =>
            item.message === newData.message && item.time === newData.time
        );
        return exists ? prevData : [...prevData, newData];
      });
    };

    if (llmResponse) {
      addResponseData({
        type: 'llm-response',
        userAvatar: imageUrl,
        time: formatTime(llmResponse.time),
        message: llmResponse.answer,
      });
    }
    if (humanResponse) {
      addResponseData({
        type: 'human-response',
        userAvatar: '/user.jpg',
        time: formatTime(humanResponse.time),
        message: humanResponse.answer,
        aiGeneratedResponse: {
          type: 'ai-generated-answer',
          time: formatTime(aiGeneratedResponse.time),
          message: aiGeneratedResponse.answer,
          score: aiGeneratedResponse.score,
        },
      });
    }
  }, [llmResponse, humanResponse, aiGeneratedResponse, imageUrl, formatTime]);

  const getMicrophone = useCallback(async () => {
    try {
      const userMedia = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });
      return new MediaRecorder(userMedia, { mimeType: 'audio/webm' });
    } catch (error) {
      setError({ type: 'MICROPHONE_ACCESS_ERROR', message: error.message });
      throw error;
    }
  }, []);

  const openMicrophone = useCallback(async () => {
    try {
      const mic = await getMicrophone();
      if (mic) {
        setMicrophone(mic);
        mic.start(100);

        mic.onstart = () => {
          console.log('client: microphone opened');
          setIsRecording(true);
          audioChunksRef.current = [];
        };

        mic.onstop = () => {
          console.log('client: microphone closed');
          setIsRecording(false);
        };

        mic.ondataavailable = async (e) => {
          console.log('client: sent valid audio data to websocket');
          socketRef.current.emit('packet-sent', e.data);
          audioChunksRef.current.push(e.data);
        };
      }
    } catch (error) {
      setError({ type: 'MICROPHONE_OPEN_ERROR', message: error.message });
    }
  }, [getMicrophone]);

  const closeMicrophone = useCallback(async () => {
    setIsRecording(false);
    if (microphone) {
      try {
        microphone.stop();
      } catch (error) {
        setError({ type: 'MICROPHONE_CLOSE_ERROR', message: error.message });
      }
    }
  }, [microphone]);

  const resumeMicrophone = useCallback(async () => {
    try {
      if (!microphone) {
        await openMicrophone();
      } else {
        microphone.start(100);
      }
      console.log('client: resuming microphone');
      setIsRecording(true);
    } catch (error) {
      setError({ type: 'MICROPHONE_RESUME_ERROR', message: error.message });
    }
  }, [microphone, openMicrophone]);

  const toggleRecording = useCallback(async () => {
    try {
      if (isRecording) {
        await closeMicrophone();
        setMicrophone(null);
      } else {
        if (!microphone) {
          await openMicrophone();
          socketRef.current.emit('call-initiation', 'start-of-call');
          socketRef.current.emit('persona-id', personaId);
        } else {
          await resumeMicrophone();
        }
      }
    } catch (error) {
      setError({ type: 'TOGGLE_RECORDING_ERROR', message: error.message });
    }
  }, [
    isRecording,
    microphone,
    closeMicrophone,
    openMicrophone,
    resumeMicrophone,
    personaId,
  ]);

  const stopAudio = useCallback(() => {
    if (sourceNodeRef.current) {
      try {
        sourceNodeRef.current.stop();
        sourceNodeRef.current.disconnect();
      } catch (error) {
        setError({ type: 'STOP_AUDIO_ERROR', message: error.message });
      }
    }
  }, []);

  const processUserAudio = useCallback(async () => {
    if (audioChunksRef.current.length > 0) {
      try {
        const userAudioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/webm',
        });
        const userAudioArrayBuffer = await userAudioBlob.arrayBuffer();
        const userAudioBuffer = await audioContextRef.current.decodeAudioData(
          userAudioArrayBuffer
        );
        const filteredAudioBuffer = removeSilence(userAudioBuffer);
        combinedAudioBuffersRef.current.push(filteredAudioBuffer);
        console.log(
          'user buffer added',
          combinedAudioBuffersRef.current.length
        );
        audioChunksRef.current = [audioChunksRef.current[0]];
        console.log('User audio processed and added to combined buffers');
      } catch (error) {
        console.error('Error processing user audio:', error);
        setError({ type: 'PROCESS_USER_AUDIO_ERROR', message: error.message });
      }
    }
  }, []);

  const fetchAndProcessAudio = useCallback(
    async (text) => {
      try {
        const response = await fetch(
          `${socketUrl}/api/v1/conversationalAI/getAudio`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({ text, model: selectedPersona.model }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        const decodedAudio = await audioContextRef.current.decodeAudioData(
          arrayBuffer
        );

        setAudioBuffer(decodedAudio);
        await processUserAudio();

        combinedAudioBuffersRef.current.push(decodedAudio);
        console.log(
          'server buffer added',
          combinedAudioBuffersRef.current.length
        );

        console.log('Server audio processed and added to combined buffers');
      } catch (error) {
        console.error('Error fetching and processing audio:', error);
        setError({ type: 'FETCH_AUDIO_ERROR', message: error.message });
      }
    },
    [socketUrl, token, processUserAudio]
  );
  const combineAudioBuffers = useCallback(async (buffers) => {
    if (buffers.length === 0) return null;
    if (buffers.length === 1) return buffers[0];

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    buffers.map((buffer) =>
      console.log('number of channels', buffer.numberOfChannels)
    );
    const numberOfChannels = Math.max(
      ...buffers.map((buffer) => buffer.numberOfChannels)
    );
    const totalLength = buffers.reduce((sum, buffer) => sum + buffer.length, 0);
    const combinedBuffer = audioContext.createBuffer(
      numberOfChannels,
      totalLength,
      audioContext.sampleRate
    );

    let offset = 0;
    for (const buffer of buffers) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        const channelData = combinedBuffer.getChannelData(channel);
        if (channel < buffer.numberOfChannels) {
          channelData.set(buffer.getChannelData(channel), offset);
        }
      }
      offset += buffer.length;
    }

    return combinedBuffer;
  }, []);

  const audioBufferToWav = useCallback((buffer) => {
    const numberOfChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const length = buffer.length * numberOfChannels * 2;
    const arrayBuffer = new ArrayBuffer(44 + length);
    const view = new DataView(arrayBuffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');

    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numberOfChannels * 2, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    const channelData = [];
    for (let i = 0; i < numberOfChannels; i++) {
      channelData.push(buffer.getChannelData(i));
    }

    let offset = 44;
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, channelData[channel][i]));
        view.setInt16(
          offset,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        );
        offset += 2;
      }
    }

    return arrayBuffer;
  }, []);
  const saveRecording = useCallback(async () => {
    if (isSaving) {
      console.log('Already saving, please wait...');
      return;
    }

    setIsSaving(true);
    try {
      console.log('Saving recording...');
      await closeMicrophone();
      console.log(
        `Combining ${combinedAudioBuffersRef.current.length} audio buffers`
      );
      const finalCombinedBuffer = await combineAudioBuffers(
        combinedAudioBuffersRef.current
      );

      if (finalCombinedBuffer) {
        console.log('Creating WAV file...');
        const wavBuffer = await audioBufferToWav(finalCombinedBuffer);
        const combinedBlob = new Blob([wavBuffer], { type: 'audio/wav' });

        console.log('Preparing file for upload...');
        const formData = new FormData();
        const fileName = `conversation-${Date.now()}.wav`;
        formData.append('file', combinedBlob, fileName);
        formData.append('docId', docId);

        console.log('Uploading file...', { fileName, docId });
        const response = await organizationAPI.uploadRecording({
          data: formData,
        });

        console.log('Upload response:', response);

        if (response && response.status === 200) {
          console.log('Recording uploaded successfully');
          combinedAudioBuffersRef.current = [];
        } else {
          throw new Error(
            `Upload failed with status: ${response?.status || 'unknown'}`
          );
        }
      } else {
        console.log('No audio data to save');
      }
    } catch (error) {
      console.error('Failed to save recording:', error);
      setError({
        type: 'SAVE_RECORDING_ERROR',
        message:
          error.message ||
          'An unknown error occurred while saving the recording',
      });
    } finally {
      setIsSaving(false);
    }
  }, [closeMicrophone, combineAudioBuffers, isSaving, audioBufferToWav, docId]);

  const playAudio = useCallback(() => {
    if (audioBuffer && audioContextRef.current) {
      try {
        if (audioContextRef.current.state === 'suspended') {
          audioContextRef.current.resume().then(() => {
            stopAudio();
            sourceNodeRef.current =
              audioContextRef.current.createBufferSource();
            const gainNode = audioContextRef.current.createGain();
            gainNode.gain.value = isMuted ? 0 : 1;
            sourceNodeRef.current.buffer = audioBuffer;
            sourceNodeRef.current
              .connect(gainNode)
              .connect(audioContextRef.current.destination);

            setIsPlayingAudio(true);
            sourceNodeRef.current.start();

            sourceNodeRef.current.onended = () => {
              setIsPlayingAudio(false);
            };
          });
        } else {
          stopAudio();
          sourceNodeRef.current = audioContextRef.current.createBufferSource();
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = isMuted ? 0 : 1;
          sourceNodeRef.current.buffer = audioBuffer;
          sourceNodeRef.current
            .connect(gainNode)
            .connect(audioContextRef.current.destination);

          setIsPlayingAudio(true);
          sourceNodeRef.current.start();

          sourceNodeRef.current.onended = () => {
            setIsPlayingAudio(false);
          };
        }
      } catch (error) {
        setError({ type: 'PLAY_AUDIO_ERROR', message: error.message });
      }
    }
  }, [audioBuffer, stopAudio, isMuted]);

  return {
    isRecording,
    transcript,
    toggleRecording,
    playAudio,
    hasAudio: !!audioBuffer,
    isSpeaking,
    isIdle,
    responseData,
    isPlayingAudio,
    error,
    docId,
    audioBuffer,
    saveRecording,
    isSaving,
  };
};

export default useDeepgram;
//Test
