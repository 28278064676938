import { Stack } from '@mui/material';
import { SalesRepAdd } from './SalesRepAdd';
import { SalesRepList } from './SalesRepList';

export const Sales = () => {
  return (
    <Stack spacing={3}>
      <SalesRepAdd />
      <SalesRepList />
    </Stack>
  );
};
