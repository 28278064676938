import { Stack, useTheme } from '@mui/material';

export const ContentBox = ({ children, sx, ...props }) => {
  const theme = useTheme();
  const { palette } = theme;

  return (
    <Stack
      sx={{
        background: palette.background.paper,
        borderRadius: '10px',
        px: 2,
        py: 3,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};
