import { Edit } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { TableSkeleton } from 'components/Table';
import { DataTable } from 'components/Table/Table';
import ActionsDropdown from 'components/Table/TableActions';
import {
  useDialog,
  useTableSearch,
  useUserContext,
} from 'pages/Settings/context';
import { useEffect } from 'react';
import { colorPalette } from 'theme/colors';

import apis from 'api/api';
import { DateRangePicker } from 'components/Datepicker';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { parseDate } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import DeleteDialog from './components/DeleteDialog';
import EditDetailsDialog from './components/EditDialog';
import RevokeUser from './components/RevokeDialog';

const coachListTableColumns = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'invitationDate', label: 'Invited On' },
  { id: 'actions', label: 'Actions' },
];

const searchFields = [
  'firstName',
  'lastName',
  'email',
  'invitationDate',
  'status',
];
export const CoachList = () => {
  const { user: loggedUser } = useLoggedUser();
  const { openDialog } = useDialog();
  const { data, get, isFetching } = useUserContext();
  const users = data || [];

  const { searchTerm, setSearchTerm, filteredData, setStartDate, setEndDate } =
    useTableSearch({
      data: users,
      searchFields,
      dateRangeColumn: 'invitationDate',
    });

  useEffect(() => {
    get();
  }, []);

  const { call: resend, isLoading: resending } = useApi({
    fetcher: (data) => {
      return apis.resendInvitation({
        organizationId: loggedUser.organization,
        invitationId: data.id,
      });
    },
    successMessage: 'Coach invitation resent successfully',
  });

  const actions = (data) => {
    return [
      ...(data.status === 'ACCEPTED'
        ? [
            {
              label: 'View/Edit Details',
              icon: <Edit color="success" />,
              onClick: () => openDialog({ data, name: 'edit' }),
            },
            {
              label: 'Remove Coach',
              icon: <PersonOffIcon color="error" />,
              onClick: () => openDialog({ data, name: 'delete' }),
            },
          ]
        : []),
      ...(data.status === 'PENDING'
        ? [
            {
              label: 'Resend Request',
              icon: <ReplayIcon color="success" />,
              onClick: () => resend(data),
            },
            {
              label: 'Revoke Request',
              icon: <HighlightOffIcon color="error" />,
              onClick: () => openDialog({ data, name: 'revoke' }),
            },
          ]
        : []),
    ];
  };

  const coachSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        >
          <FormControlLabel value="" control={<Radio />} label="All" />
          <FormControlLabel
            value="PENDING"
            control={<Radio />}
            label="Pending"
          />
          <FormControlLabel
            value="ACCEPTED"
            control={<Radio />}
            label="Accepted"
          />
        </RadioGroup>
      </FormControl>

      <FormControl size="small">
        <DateRangePicker
          onDateChange={(selectedRange) => {
            const { startDate, endDate } = selectedRange;
            setStartDate(startDate);
            setEndDate(endDate);
          }}
        />
      </FormControl>

      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        padding: 2,
        borderRadius: 2,
      }}
    >
      {resending && <LoadingOverlay />}
      <Stack direction="column" spacing={5}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Coach Roster</Typography>
          {coachSearchBar}
        </Stack>
        {isFetching ? (
          <TableSkeleton column={coachListTableColumns} />
        ) : (
          <DataTable
            columns={coachListTableColumns}
            sort={true}
            data={
              users &&
              filteredData.map((item) => ({
                ...item,
                actions: <ActionsDropdown actions={actions(item)} />,
                name: `${item.firstName} ${item.lastName}`,
                invitationDate: getOriginalZonedDate({
                  date: parseDate(item.invitationDate),
                }),
                status: (
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
              }))
            }
          />
        )}
      </Stack>
      <EditDetailsDialog />
      <DeleteDialog />
      <RevokeUser />
    </Box>
  );
};

export default CoachList;
