import DownloadIcon from '@mui/icons-material/Download';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

export const MediaPlayerProgressBar = ({ audioSrc, width }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [maxDuration, setMaxDuration] = useState(0);

  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.onloadedmetadata = () => {
        setMaxDuration(audio.duration * 1000);
      };
    }
  }, [audioSrc]);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const toggleMute = () => {
    setIsMute((prev) => !prev);
  };

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        const audio = audioRef.current;
        if (audio) {
          setCurrentDuration(audio.currentTime * 1000);
          setProgress((audio.currentTime / audio.duration) * 100);
        }
      }, 100);
    }

    return () => clearInterval(interval);
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = isMute ? 0 : 1;
    }
  }, [isMute]);

  const handleProgressChange = (event, newValue) => {
    const newDuration = (newValue / 100) * maxDuration;
    setCurrentDuration(newDuration);
    setProgress(newValue);
    audioRef.current.currentTime = (newValue / 100) * audioRef.current.duration;
  };

  const formatTime = (timeInMs) => {
    const totalSeconds = Math.floor(timeInMs / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: `${width}`,
        padding: '0 16px',
        fontSize: '12px',
        color: '#5B706F',
        border: '1px solid #DAE8E7',
        borderRadius: '5px',
      }}
    >
      <IconButton onClick={togglePlayPause}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <Typography
        variant="body1"
        sx={{ fontSize: '12px', color: '#5B706F', minWidth: '80px' }}
      >
        {`${formatTime(currentDuration)} / ${formatTime(maxDuration)}`}
      </Typography>

      <Slider
        value={progress}
        onChange={handleProgressChange}
        aria-label="Progress"
        sx={{
          flexGrow: 1,
          margin: '0 16px',
          '& .MuiSlider-thumb': {
            display: 'none',
          },
          '& .MuiSlider-track': {
            height: 4,
            backgroundColor: '#8CA19F',
          },
          '& .MuiSlider-rail': {
            height: 4,
          },
        }}
      />
      <audio ref={audioRef} src={audioSrc} preload="metadata" />

      <IconButton onClick={toggleMute}>
        {isMute ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </IconButton>
      <IconButton href={audioSrc} download>
        <DownloadIcon />
      </IconButton>
      {/* <IconButton>
        <ArticleIcon />
      </IconButton>
      <IconButton>
        <MoreVertIcon />
      </IconButton> */}
    </Box>
  );
};

export default MediaPlayerProgressBar;
