import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HearingIcon from '@mui/icons-material/Hearing';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SpeedIcon from '@mui/icons-material/Speed';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import WrapTextIcon from '@mui/icons-material/WrapText';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { EmptyState } from 'components/CustomMUI';
import { useEffect, useState } from 'react';
import ExpandCollapseText from './ExpandCollapseText';
import MediaPlayerProgressBar from './MediaPlayerProgressBar';
import SummaryStats from './SummaryStats';

const isValidDescription = (description) => {
  return description && description.trim() !== ''; // Check if the description is neither null, undefined, nor empty
};

const renderSummaryStats = (
  icon,
  titleLeft,
  titleRight,
  descriptionLeft,
  descriptionRight
) => {
  if (
    isValidDescription(descriptionLeft) &&
    isValidDescription(descriptionRight)
  ) {
    return (
      <SummaryStats
        icon={icon}
        title_left={titleLeft}
        title_right={titleRight}
        description_left={descriptionLeft}
        description_right={descriptionRight}
        status="success"
      />
    );
  }
  return <EmptyState />; // Render EmptyState if any description is invalid
};

const Message = ({ data }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'flex-start',
        width: '98%',
        padding: '5px 10px',
        justifyContent:
          String(data.type).toLowerCase() === 'receiver'
            ? 'flex-end'
            : 'flex-start',
      }}
    >
      <Avatar
        src={data.avatar}
        alt={data.avatar}
        sx={{
          width: '24px',
          height: '24px',
          objectFit: 'cover',
          borderRadius: '50%',
          marginTop: '5px',
          order: String(data.type).toLowerCase() === 'receiver' ? 1 : 0,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          maxWidth: '92%',
          width: '100%',
          order: String(data.type).toLowerCase() === 'receiver' ? 0 : 1,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: '#5b706f',
            fontFamily: 'Lexend',
            fontSize: '8px',
            fontWeight: 300,
            alignSelf:
              String(data.type).toLowerCase() === 'receiver'
                ? 'flex-end'
                : 'flex-start',
          }}
        >
          {data.time}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '8px',
            backgroundColor:
              String(data.type).toLowerCase() === 'receiver'
                ? '#5b706f'
                : '#f3f8f8',
            color:
              String(data.type).toLowerCase() === 'receiver'
                ? 'white'
                : '#5b706f',
            padding: '1px 10px',
            borderRadius: '5px',
            boxShadow:
              '0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(49, 49, 49, 0.19)',
            zIndex: 2,
            width:
              String(data.type).toLowerCase() === 'receiver'
                ? '97%'
                : 'fit-content',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontWeight: 300,
              wordBreak: 'break-word',
            }}
          >
            {data.message}
          </Typography>
        </Box>
        {data.attributes && (
          <Box
            sx={{
              width: '90%',
              border: '3px solid #00706c',
              marginTop: '-10px',
              display: 'flex',
              flexDirection: 'column',
              padding: '15px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#243030',
                fontFamily: 'Lexend',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                textWrap: 'nowrap',
              }}
            >
              {data.attributes.title}
            </Typography>
            {data.attributes.data.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                {String(item.status).toLowerCase() === 'success' ? (
                  <CheckIcon sx={{ color: 'green' }} />
                ) : (
                  <CloseIcon sx={{ color: 'red' }} />
                )}
                <Typography
                  sx={{
                    color: '#5b706f',
                    fontFamily: 'Lexend',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ScoreCardTrackDrawer = ({ scorecardTrackData }) => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  return (
    <>
      <Button
        onClick={toggleDrawer(true)}
        sx={{
          backgroundColor: 'transparent',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          color: '#5b706f',
        }}
      >
        <SplitscreenIcon />
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: 800,
            height: '100vh',
            overflow: 'hidden',
          },
        }}
      >
        <Box sx={{ padding: 5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '10px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: '#141418',
                  fontFamily: 'Lexend',
                  fontSize: '28px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  textWrap: 'nowrap',
                }}
              >
                Scorecard Track
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Button
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    fontFamily: 'Lexend',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    textWrap: 'nowrap',
                    backgroundColor: 'transparent',
                    outline: 'none',
                    cursor: 'pointer',
                    lineHeight: 0,
                    padding: '2px 10px',
                    borderRadius: '5px',
                    color: '#5B706F',
                    border: '1px solid #5B706F',
                  }}
                >
                  <CloseIcon />
                  <Typography>Discard</Typography>
                </Button>
                <Button
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    fontFamily: 'Lexend',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    textWrap: 'nowrap',
                    backgroundColor: 'transparent',
                    outline: 'none',
                    cursor: 'pointer',
                    lineHeight: 0,
                    padding: '2px 10px',
                    borderRadius: '5px',
                    color: '#00706C',
                    border: '1px solid #00706C',
                  }}
                >
                  <CheckIcon />
                  <Typography>Confirm</Typography>
                </Button>
              </Box>
            </Box>
            <MediaPlayerProgressBar audioSrc={'/song.mp3'} width={'95%'} />
            <Box
              sx={{
                display: 'flex',
                width: '98%',
                padding: '10px',
                flexDirection: 'column',
                gap: '5px',
                maxHeight: '85vh',
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              {/* {scorecardTrackData.messages.map((item, index) => (
                <Message data={item} key={index} />
              ))} */}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const TotalScoreCard = ({ data, title, maxScore }) => {
  return (
    <Box
      sx={{
        width: '95%',
        padding: '20px',
        border: '1px solid #dae8e7',
        backgroundColor: '#fff',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '20px',
        marginLeft: '5px',
        my: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#243030',
            fontFamily: 'Lexend',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            textWrap: 'nowrap',
          }}
        >
          {title}
        </Typography>
        {data && (
          <Typography
            variant="h6"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              textWrap: 'nowrap',
            }}
          >
            {data.score} / {maxScore}
          </Typography>
        )}
      </Box>
      {data.feedback.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#5b706f',
            fontFamily: 'Lexend',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 300,
            textWrap: 'nowrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {item.present ? (
              <CheckIcon sx={{ color: 'green' }} />
            ) : (
              <CloseIcon sx={{ color: 'red' }} />
            )}
            <Typography>{item.text}</Typography>
          </Box>
          {/* <ScoreCardTrackDrawer scorecardTrackData={data} /> */}
        </Box>
      ))}
    </Box>
  );
};

export const ScoreCard = ({ data }) => {
  const [totalScore, setTotalScore] = useState(0);
  const [maxTotalScore, setMaxScore] = useState(0);

  const roundToNearest = (value) => {
    return Math.round(value * 10) / 10;
  };

  useEffect(() => {
    if (data && data.scoreCard) {
      const totalScore = data.scoreCard.reduce((total, section) => {
        return total + section.data.score;
      }, 0);
      setMaxScore(data.maxScore);
      setTotalScore(totalScore);
    }
  }, [data]);

  return (
    <Box
      sx={{
        width: '95%',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'start',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 300,
              textWrap: 'nowrap',
            }}
          >
            Feedback Summary
          </Typography>
          <Box
            sx={{
              width: '99.97%',
              border: '1px solid #dae8e7',
            }}
          />
          {data ? (
            <ExpandCollapseText text={data.summary?.feedback} maxLength={300} />
          ) : (
            <EmptyState />
          )}

          {data &&
            renderSummaryStats(
              <HearingIcon />,
              'Talk/Listen Ratio',
              'How much you spoke vs. how much you listened',
              data.summary.details.talkListenRatio?.text,
              data.summary.details.talkListenRatio?.feedback
            )}

          {data &&
            renderSummaryStats(
              <WrapTextIcon />,
              'Filler Words',
              "How many filler words like 'um' you used",
              data.summary.details.fillerWords?.text,
              data.summary.details.fillerWords?.feedback
            )}

          {data &&
            renderSummaryStats(
              <SpeedIcon />,
              'Talking Speed',
              'How fast you spoke',
              data.summary.details.talkingspeed?.text,
              data.summary.details.talkingspeed?.feedback
            )}

          {data &&
            renderSummaryStats(
              <RecordVoiceOverIcon />,
              'Longest Monologue',
              'The longest uninterrupted stretch of time for which you spoke',
              `${roundToNearest(
                data.summary.details.longestMonologue?.value
              )} sec`,
              data.summary.details.longestMonologue?.feedback
            )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: '#243030',
                fontFamily: 'Lexend',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 300,
                textWrap: 'nowrap',
              }}
            >
              Total Score
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: '#243030',
                fontFamily: 'Lexend',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 300,
                textWrap: 'nowrap',
              }}
            >
              {totalScore}/{maxTotalScore}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '99.97%',
              border: '1px solid #dae8e7',
            }}
          />
          {data && data.scoreCard ? (
            data.scoreCard.map((section, index) => (
              <TotalScoreCard
                key={index}
                data={section.data}
                title={section.title}
                maxScore={5}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ScoreCard;
