import React from 'react';
import StudentAdd from './StudentAdd';
import StudentList from './StudentList';
import { Stack } from '@mui/material';
// import { UserContext } from 'pages/Settings/context';
import { UserProvider } from '../../context';
const Students = () => {
  return (
    <UserProvider userType={'STUDENT'}>
      <Stack spacing={3}>
        <StudentAdd />
        <StudentList />
      </Stack>
    </UserProvider>
  );
};

export default Students;
