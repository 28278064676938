import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import organizationAPI from 'api/api';
import { MainContent } from 'components/sidebar';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles/Thankyou.module.css';

import LoadingOverlay from 'components/LoadingOverlay';

import { ArrowForward, Info } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Container } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { ContentBox } from 'components/Box';
import TipsCaterpillarIcon from './tip_caterpillar.png';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const assessmentId = location.pathname.split('/')[4];
  const [tips, setTips] = useState(null);
  const [seen, setSeen] = useState(false);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  const [user, setUser] = useState(null);

  const getAssessmentEvalutionTips = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentEvalutionTips(
        assessmentId
      );
      const temp = response.data.map((item) => {
        return { ...item, seen: false };
      });
      setTips(response.data);
      if (response.data.length === 0) {
        setSeen(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getAssessmentEvalutionTips();
  }, []);

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}

        <div className={styles.content}>
          {tips && !seen ? (
            tips.map((item, index) => {
              if (index === currentTipIndex) {
                return (
                  <ContentBox
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 5,
                      marginTop: '25%',
                    }}
                  >
                    <img
                      src={TipsCaterpillarIcon}
                      alt="Caterpillar Icon"
                      width={50}
                      height={50}
                    />
                    <Stack direction={'column'} spacing={2} width={'100%'}>
                      <Typography variant="h5" color="primary">
                        Tip #{index + 1}
                      </Typography>
                      <Typography variant="body2">{item.tipText}</Typography>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection={'column'}
                          alignItems="center"
                        >
                          <Typography variant="caption">
                            TIPS UNLOCKED: {index + 1} of {tips.length}
                          </Typography>
                          <Box width="100%">
                            <LinearProgress
                              variant="determinate"
                              value={((index + 1) / tips.length) * 100}
                              sx={{ height: 8, borderRadius: 4 }}
                            />
                          </Box>
                        </Box>

                        <Button
                          variant="outlined"
                          endIcon={<ArrowForward />}
                          onClick={() => {
                            setCurrentTipIndex(currentTipIndex + 1);
                            if (index === tips.length - 1) {
                              setSeen(true);
                            }
                          }}
                        >
                          Next Tip
                        </Button>
                      </Stack>
                    </Stack>
                  </ContentBox>
                );
              }
            })
          ) : (
            <ContentBox sx={{ alignItems: 'center' }}>
              <div className={styles.thankyou_section}>
                <CheckCircleOutline />
                <h3>Thank you!</h3>
              </div>
              <Container
                maxWidth="md"
                sx={{ textAlign: 'center', mt: 8, mb: 4 }}
              >
                <Typography variant="h5" color="primary" gutterBottom>
                  🎉 Success! You’ve Completed the Test! 🎉
                </Typography>

                <Typography variant="body1" color="textSecondary" paragraph>
                  Great job! You’re on the path to building stronger social and
                  emotional skills, and we’re excited to see your progress.
                </Typography>

                <Box
                  mt={4}
                  mb={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6" color="secondary">
                    Here’s what’s next:
                  </Typography>

                  {/* Check Inbox */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt={2}
                    >
                      <MailOutlineIcon color="error" sx={{ mr: 1 }} />
                      <Typography variant="body1" color="textPrimary">
                        Check your inbox – You’ll receive an email soon with
                        tips and insights.
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt={2}
                    >
                      <CalendarTodayIcon color="info" sx={{ mr: 1 }} />
                      <Typography variant="body1" color="textPrimary">
                        Mark your calendar – In two weeks, you’ll receive
                        another quick assessment with new tips.
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt={2}
                    >
                      <Info color="error" sx={{ mr: 1 }} />
                      <Typography variant="body1" color="textPrimary">
                        If you don't have email access – Return to dashboard and
                        take a screenshot of the tips.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Container>
              <Button
                sx={{ width: 'fit-content' }}
                variant="contained"
                onClick={() => {
                  navigate('/student/psych/dashboard');
                }}
              >
                Return to Dashboard
              </Button>
            </ContentBox>
          )}
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
