import { yupResolver } from '@hookform/resolvers/yup';
import { Send } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { addAdmin } from 'pages/Settings/Tabs/Admin/validation/schema';
import { FormField } from 'pages/Settings/Tabs/common';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
const blankAdminInfo = {
  firstName: '',
  lastName: '',
  email: '',
};

export const AdminAdd = () => {
  const [userInfo, setUserInfo] = useState(blankAdminInfo);
  const { user } = useLoggedUser();

  const { call: createAdmin, isLoading: adminCreating } = useApi({
    fetcher: apis.inviteUser,
    successMessage: 'Admin added successfully',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addAdmin),
    mode: 'onChange',
    defaultValues: { role: 'ADMIN' },
  });

  const adminAdd = (data) => {
    createAdmin({ ...data, organizationId: user.organization });
    reset();
  };

  const renderFormFields = () => {
    const fields = [
      { name: 'firstName', label: 'First Name', type: 'text' },
      { name: 'lastName', label: 'Last Name', type: 'text' },
      { name: 'email', label: 'Email', type: 'text' },
    ];

    return fields.map((field) => (
      <FormField
        formType="ADD"
        key={field.name}
        field={field}
        register={register}
        errors={errors}
        watch={watch}
        state={userInfo}
        stateUpdater={setUserInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <ContentBox component="form" onSubmit={handleSubmit(adminAdd)}>
      {adminCreating ? <LoadingOverlay /> : null}
      <Stack direction="column" spacing={4}>
        <Typography variant="h6">Invite New Admin</Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields()}
          <Button
            variant="contained"
            color="success"
            startIcon={<Send />}
            type="submit"
          >
            Send Invite
          </Button>
        </Stack>
      </Stack>
    </ContentBox>
  );
};
