import { CustomTextButton } from 'components/Button';
import styles from '../../Dashboard.module.css';
import { Box, FormControl } from '@mui/material';
import { CustomTextField } from 'components/TextField';
import {
  MultipleSelectCoach,
  SelectCourse,
  SelectRegion,
  SelectUserRole,
} from 'components/SelectField';
const { useState, useEffect } = require('react');

export const CSVInviteSection = ({
  item,
  index,
  id,
  inviteeData,
  setInviteeData,

  user,
  coachData,
  invitedEmailList,
  courseList,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [course, setCourse] = useState('');
  const [region, setRegion] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [selectedAsigneeIds, setSelectedAsigneeIds] = useState([]);
  const [recipientEmailErrorMessage, setrecipientEmailErrorMessage] =
    useState('');

  const validateEmail = (value) => {
    let currentEmails = value;
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!regex.test(currentEmails.replace(/\s/g, ''))) {
      setrecipientEmailErrorMessage(`Enter valid Email(s)`);
    } else if (invitedEmailList.includes(currentEmails)) {
      setrecipientEmailErrorMessage(`Email already invited`);
    } else {
      setrecipientEmailErrorMessage('');
    }
  };

  useEffect(() => {
    if (item) {
      setFirstName(item.firstName);
      setLastName(item.lastName);
      setEmail(item.email);
      setRole(item.role);

      if (item.role === 'COACH' || item.role === 'STUDENT') {
        setCourse(item.course);
        setRegion(item.region);
      }
    }
  }, []);

  useEffect(() => {
    if (email) validateEmail(email);
    else setrecipientEmailErrorMessage('');
  }, [email]);

  useEffect(() => {
    let isEnabled = Boolean(
      (firstName || item.firstName) &&
        (lastName || item.lastName) &&
        (email || item.email) &&
        (role || item.role)
    );

    if (role === 'COACH' || role === 'STUDENT') {
      isEnabled = Boolean(
        isEnabled && (course || item.course) && (region || item.region)
      );
    }

    if (recipientEmailErrorMessage) isEnabled = false;

    if (!role) return;

    const temp = inviteeData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          firstName,
          lastName,
          email,
          role,
          course,
          region,
          assigneeIds: selectedAsigneeIds,
        };
      }
      return item;
    });
    setInviteeData(temp);

    setSubmitEnabled(isEnabled);
  }, [firstName, lastName, email, role, course, region, selectedAsigneeIds]);

  return (
    <div className={styles.bulk_modal_invite_section}>
      <div className={styles.invite_bulk_section_header}>
        <p>
          Invitee {index + 1}
          {!submitEnabled && <span>* (Missing Field)</span>}
          {item.firstName}
        </p>

        {index > 0 && (
          <CustomTextButton
            onClick={() => {
              setInviteeData(inviteeData.filter((item) => item.id !== id));
            }}
          >
            - Remove
          </CustomTextButton>
        )}
      </div>
      <Box
        className={styles.form_menu}
        component="form"
        noValidate
        autoComplete="off"
      >
        <CustomTextField
          label="FIRST NAME"
          defaultValue={item.firstName}
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />

        <CustomTextField
          label="LAST NAME"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />

        <CustomTextField
          label="EMAIL ADDRESS"
          placeholder={'Email Address'}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          error={recipientEmailErrorMessage ? true : false}
          helperText={recipientEmailErrorMessage}
        />
      </Box>
      <div className={styles.input_section}>
        <Box
          className={styles.filter_menu}
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl className={styles.drop_down_select}>
            <SelectUserRole
              label={'ROLE'}
              input={'ROLE'}
              role={user.role}
              value={role}
              setValue={setRole}
              data={['ADMIN', 'COACH', 'STUDENT']}
            />
          </FormControl>

          {(role === 'STUDENT' || role === 'COACH') && courseList && (
            <FormControl
              sx={{
                width: '49%',
                ml: 1,
              }}
              className={styles.drop_down_select}
            >
              <SelectCourse
                courseList={courseList}
                label={'SELECT COURSE'}
                input={'COURSE'}
                value={course}
                setValue={setCourse}
              />
            </FormControl>
          )}
          {(role === 'STUDENT' || role === 'COACH') && (
            <FormControl
              sx={{
                width: '49%',
                ml: 1,
              }}
              className={styles.drop_down}
            >
              <SelectRegion
                label={'SELECT REGION'}
                input={'REGION'}
                value={region}
                setValue={setRegion}
              />
            </FormControl>
          )}
        </Box>
        {role === 'STUDENT' && (
          <div style={{ width: '33%' }}>
            <FormControl sx={{ width: '100%' }}>
              <MultipleSelectCoach
                input={'ASSIGN COACHES'}
                label={'Assign Coaches'}
                coachData={coachData}
                selectedAsigneeIds={selectedAsigneeIds}
                setSelectedAsigneeIds={setSelectedAsigneeIds}
              />
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
};
