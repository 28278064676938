import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingOverlay from 'components/LoadingOverlay';
import {
  useCourse,
  useDialog,
  useUser,
  useUserContext,
} from 'pages/Settings/context';
import { useForm } from 'react-hook-form';
import { EditDialogWrapper, FormField } from '../../common';
import { blankStudentInfo } from '../StudentAdd';
import { schema } from '../validation/schema';

const EditDetailsDialog = ({ onClose }) => {
  const { closeDialog, dialogData } = useDialog();
  const [userInfo, setUserInfo] = useState(blankStudentInfo);
  const { update, isUpdating } = useUserContext();

  const {
    get: fetchCoaches,
    options: coachOptions,
    isFetching: coachIsfetching,
  } = useUser({
    userType: 'COACH',
  });
  const {
    regions,
    schools,
    get: fetchCourses,
    options: courseOptions,
    isFetching: courseIsFetching,
  } = useCourse();

  const {
    register,
    formState: { errors },
    reset,
    trigger,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema({ type: 'EDIT' })),
    mode: 'onChange',
    defaultValues: { role: 'STUDENT' },
  });
  const updateUserInfo = async (data) => {
    const { role, ...withoutRole } = data;
    const selectedCourse = courseOptions.filter((o) => o.value === data.course);
    const courseName = selectedCourse[0]?.label || '';

    const updatedUserInfo = {
      ...withoutRole,
      course: courseName,
    };
    await update({ userId: dialogData.id, data: updatedUserInfo });
    reset();
    handleClose();
  };

  const handleClose = () => {
    if (onClose) onClose();
    closeDialog();
  };

  useEffect(() => {
    if (dialogData) {
      const initialUserInfo = {
        firstName: dialogData?.firstName,
        lastName: dialogData?.lastName,
        email: dialogData?.email,
        course: dialogData?.course,
        school: dialogData?.school,
        region: dialogData?.region,
        assigneeIds: dialogData?.assigneeIds || [],
        role: 'STUDENT',
      };

      setUserInfo({ ...initialUserInfo });
      reset({ ...initialUserInfo });
    }
  }, [dialogData, reset]);

  useEffect(() => {
    fetchCoaches();
    fetchCourses();
  }, []);

  const renderFormFields = () => {
    const fields = [
      {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email Address',
        type: 'text',
      },
      {
        name: 'region',
        label: 'Region',
        type: 'select',
        options: regions,
      },
      {
        name: 'school',
        label: 'School',
        type: 'select',
        options: schools,
      },
      {
        name: 'course',
        label: 'Course',
        type: 'select',
        options: courseOptions,
      },

      {
        name: 'assigneeIds',
        label: 'Assigned Coaches',
        type: 'multiSelect',
        options: coachOptions,
      },
    ];

    return fields.map((item) => (
      <FormField
        key={item.name}
        field={item}
        register={register}
        errors={errors}
        watch={watch}
        state={userInfo}
        stateUpdater={setUserInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
        loading={coachIsfetching || courseIsFetching}
      />
    ));
  };

  return (
    <>
      {isUpdating && <LoadingOverlay />}
      <EditDialogWrapper
        onSubmit={handleSubmit(updateUserInfo)}
        dialogType={'edit'}
        title={'Update Student Info'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {renderFormFields()}
        </Box>
      </EditDialogWrapper>
    </>
  );
};

export default EditDetailsDialog;
