import { Delete, Edit } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { DateRangePicker } from 'components/Datepicker';
import { TableSkeleton } from 'components/Table';
import { DataTable } from 'components/Table/Table';
import ActionsDropdown from 'components/Table/TableActions';
import {
  useCourseContext,
  useDialog,
  useTableSearch,
} from 'pages/Settings/context';
import React from 'react';
import { colorPalette } from 'theme/colors';

import { getOriginalZonedDate } from 'utils/date';
import DeleteDialog from './components/DeleteDialog';
import EditDetailsDialog from './components/EditDialog';

const courseListTableColumns = [
  { id: 'courseName', label: 'Course Code' },
  { id: 'assignedSchool', label: 'School' },
  { id: 'assignedRegion', label: 'Region' },
  { id: 'createdAt', label: 'Date Of Creation' },
  { id: 'actions', label: 'Actions' },
];

export const CourseList = () => {
  const { openEditDialog, openDeleteDialog } = useDialog();
  const { data, isLoading, get } = useCourseContext(); //useCourse();
  const courses = data?.courses || [];

  const searchFields = [
    'courseName',
    'assignedSchool',
    'assignedRegion',
    'createdAt',
  ];
  const { searchTerm, setSearchTerm, filteredData, setStartDate, setEndDate } =
    useTableSearch({
      data: courses,
      searchFields,
      dateRangeColumn: 'createdAt',
    });

  React.useEffect(() => {
    get();
  }, []);

  const courseActions = (data) => {
    return [
      {
        label: 'View/Edit Details',
        icon: <Edit color="primary" />,
        onClick: () => openEditDialog({ data }),
      },
      {
        label: 'Delete Course',
        icon: <Delete color="error" />,
        onClick: () => openDeleteDialog({ data }),
      },
    ];
  };

  const courseSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <DateRangePicker
          onDateChange={(selectedRange) => {
            const { startDate, endDate } = selectedRange;
            setStartDate(startDate);
            setEndDate(endDate);
          }}
        />
      </FormControl>

      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount-text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Stack direction="column" spacing={5}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Course List</Typography>
          {courseSearchBar}
        </Stack>

        {isLoading ? (
          <TableSkeleton column={courseListTableColumns} />
        ) : (
          <DataTable
            columns={courseListTableColumns}
            data={
              courses &&
              filteredData.map((item) => ({
                ...item,
                actions: <ActionsDropdown actions={courseActions(item)} />,
                createdAt: getOriginalZonedDate({ date: item.createdAt }),
              }))
            }
            sort={false}
          />
        )}
      </Stack>
      <DeleteDialog />
      <EditDetailsDialog />
    </Box>
  );
};

export default CourseList;
