import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useDialog, useUserContext } from 'pages/Settings/context';

export const RevokeDialog = () => {
  const { isDialogOpen, closeDialog, dialogData } = useDialog();
  const { user } = useLoggedUser();

  const role = dialogData?.role;
  const userRoleToShow = role ? role.toLowerCase() : 'user';

  const { revoke, isRevoking } = useUserContext();
  const handleRevoke = () => {
    const data = {
      organizationId: user.organization,
      invitationId: dialogData.id,
    };
    revoke(data);
    closeDialog();
  };

  return (
    <>
      {isRevoking && <LoadingOverlay />}
      <Dialog
        open={isDialogOpen.revoke}
        onClose={closeDialog}
        maxWidth="xs"
        sx={{ textAlign: 'center' }}
        PaperProps={{ sx: { padding: 1 } }}
      >
        <DialogTitle>
          <HighlightOffIcon style={{ color: '#F44336', fontSize: 50 }} />
          <Typography
            variant="h6"
            style={{ marginTop: '10px', textTransform: 'capitalize' }}
            component="div"
          >
            Revoke {userRoleToShow}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to revoke this {userRoleToShow}?
            <br />
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'center', paddingBottom: '20px' }}
        >
          <Button
            fullWidth
            color="error"
            variant="contained"
            onClick={handleRevoke}
          >
            Yes
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => closeDialog()}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RevokeDialog;
