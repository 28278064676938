import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { EmptyState } from 'components/CustomMUI';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from './../../../../hooks/useApi';
import DropdownButton from './DropdownButton';
import EditTeam from './EditTeam';

const cellStyles = {
  borderBottom: 'none',
  color: '#243030',
  fontWeight: 500,
  fontFamily: 'Lexend',
  fontSize: '14px',
  textTransform: 'capitalize',
  padding: '16px',
};

const ExpandableTableBody = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [callHistoryData, setCallHistoryData] = useState([]);
  const { call: getCallHistoryForOrganizationCall } = useApi({
    fetcher: organizationAPI.getCallHistoryForOrganization,
    successMessage: 'Fetched all Call History For Organization',
  });
  const memoizedCallHistoryForOrganization = useCallback(
    getCallHistoryForOrganizationCall,
    [getCallHistoryForOrganizationCall]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await memoizedCallHistoryForOrganization();
        if (response && response.data) {
          if (data) {
            const matchedTeam = response.data.find(
              (team) => team._id === data._id
            );

            if (matchedTeam && matchedTeam.users.length > 0) {
              const enrichedUsers = matchedTeam.users.map((user) => ({
                name: user.name,
                onboardedOn: 'September 14, 2024',
                aiCalls: user.aiCallCount,
                customerCalls: user.customerCallCount,
                status: 'Active',
                userId: user.userId,
              }));
              setCallHistoryData(enrichedUsers);
            } else {
              setCallHistoryData([]);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching teams', error);
      }
    };

    fetchData();
  }, [data]);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  if (data) {
    return (
      <Box
        sx={{
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '98%',
            padding: '5px',
            alignItems: 'center',
            gap: '10px',
            marginLeft: '10px',
            overflowX: 'hidden',
            maxWidth: '1500px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#40806A',
              fontFamily: 'Lexend',
              fontSize: '16px',
              fontWeight: 500,
              width: 'fit-content',
              textWrap: 'nowrap',
            }}
          >
            {data.name}
          </Typography>
          <Box
            sx={{
              border: '1px solid var(--global-outline-grey, #dae8e7)',
              width: '100%',
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <EditTeam data={data} />
            <IconButton sx={{ color: '#5B706F' }} onClick={handleToggle}>
              {expanded ? (
                <KeyboardArrowUpIcon
                  fontSize="small"
                  sx={{ fontSize: '18px' }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={{ fontSize: '18px' }}
                />
              )}
            </IconButton>
          </Box>
        </Box>
        {expanded && (
          <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
            <TableBody>
              {callHistoryData.length > 0 ? (
                callHistoryData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ ...cellStyles, width: '30%' }}>
                      {item.name}
                    </TableCell>
                    <TableCell sx={{ ...cellStyles, width: '25%' }}>
                      {item.aiCalls}
                    </TableCell>
                    <TableCell sx={{ ...cellStyles, width: '25%' }}>
                      {item.customerCalls}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...cellStyles,
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      <DropdownButton data={item} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyState />
              )}
            </TableBody>
          </Table>
        )}
      </Box>
    );
  } else {
    return <EmptyState />;
  }
};
export default ExpandableTableBody;
