import { Edit, PersonOff, Search } from '@mui/icons-material';
import {
  Chip,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import { ActionsDropdown, DataTable, TableSkeleton } from 'components/Table';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect } from 'react';
import { useConvoDashboardStore } from 'store';
import { getOriginalZonedDate } from 'utils/date';
import DeleteDialog from '../common/DeleteDialog';
import TeamEditDialog from './TeamEditDialog';

const teamListTableColumns = [
  { id: 'name', label: 'Team Name' },
  { id: 'createdAt', label: 'Created On' },
  { id: 'totalReps', label: 'Members' },
  { id: 'totalAICalls', label: 'AI Calls' },
  { id: 'totalCustomerCalls', label: 'Customer Calls' },
  { id: 'status', label: 'status' },
  { id: 'actions', label: 'Actions' },
];

export const TeamList = () => {
  const { user } = useLoggedUser();
  const changeEditDialogState = useConvoDashboardStore(
    (state) => state.setEditDialog
  );
  const changeDeleteDialogState = useConvoDashboardStore(
    (state) => state.setDeleteDialog
  );

  const {
    data,
    call: fetchUsers,
    isLoading: isFetching,
  } = useApi({
    fetcher: apis.getConvoTeam,
  });

  const { call: deleteTeam, isLoading: teamDeleting } = useApi({
    fetcher: apis.deleteConvoTeam,
    successMessage: 'Team deleted successfully',
  });

  const searchFields = ['teamName', 'status'];
  const { searchTerm, setSearchTerm, filteredData } = useTableSearch({
    data,
    searchFields,
  });

  useEffect(() => {
    fetchUsers(user.organization);
  }, []);

  const actions = (data) => {
    return [
      {
        label: 'View/Edit Details',
        icon: <Edit color="success" />,
        onClick: () =>
          changeEditDialogState({ isOpen: true, forItem: 'team', data }),
      },
      {
        label: 'Remove Team',
        icon: <PersonOff color="error" />,
        onClick: () =>
          changeDeleteDialogState({
            isOpen: true,
            forItem: 'team',
            onOk: () => deleteTeam({ teamId: data._id }),
          }),
      },
    ];
  };

  const teamSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <ContentBox>
      <Stack direction="column" spacing={5}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Admin Roster</Typography>
          {teamSearchBar}
        </Stack>
        {isFetching || teamDeleting ? (
          <TableSkeleton column={teamListTableColumns} />
        ) : (
          <DataTable
            columns={teamListTableColumns}
            sort={true}
            data={filteredData?.map((item) => ({
              ...item,
              actions: <ActionsDropdown actions={actions(item)} />,

              createdAt: getOriginalZonedDate({
                date: item.createdAt,
              }),
              status: (
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  color={item.status === 'ACTIVE' ? 'success' : 'info'}
                />
              ),
            }))}
          />
        )}
      </Stack>
      <DeleteDialog />
      <TeamEditDialog />
    </ContentBox>
  );
};
