import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import EmptyState from './EmptyState';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
export const RadarChart = ({ labels, datasets }) => {
  const hasZeroValue = datasets?.some((dataset) =>
    dataset.data.some((value) => value === 0)
  );

  if (hasZeroValue) {
    return <EmptyState />;
  }

  if (datasets && labels) {
    const data = {
      labels: labels,
      datasets: [
        {
          label: datasets[0].label,
          data: datasets[0].data,
          backgroundColor: 'rgba(0, 112, 108, 0.2)',
          borderColor: 'rgba(0, 112, 108, 1)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(0, 112, 118, 1)',
        },
        {
          label: datasets[1].label,
          data: datasets[1].data,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        },
      ],
    };

    const options = {
      scales: {
        r: {
          angleLines: {
            display: true,
          },
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            stepSize: 20,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
      },
    };

    return <Radar data={data} options={options} />;
  }
  return null;
};

export default RadarChart;
