import MicIcon from '@mui/icons-material/Mic';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

export const DeviceSelector = () => {
  const [defaultMic, setDefaultMic] = useState('Checking permissions...');
  const [defaultSpeaker, setDefaultSpeaker] = useState(
    'Checking permissions...'
  );
  const [hasPermission, setHasPermission] = useState(false);

  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      setHasPermission(true);
      return true;
    } catch (error) {
      console.error('Error requesting microphone permission:', error);
      setDefaultMic('Microphone permission denied');
      return false;
    }
  };

  const updateDevices = async () => {
    try {
      if (!hasPermission) {
        const permitted = await requestMicrophonePermission();
        if (!permitted) return;
      }

      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputs = devices.filter(
        (device) => device.kind === 'audioinput'
      );
      const defaultAudioInput =
        audioInputs.find((device) => device.deviceId === 'default') ||
        audioInputs.find((device) =>
          device.label.toLowerCase().includes('default')
        ) ||
        audioInputs[0];
      const audioOutputs = devices.filter(
        (device) => device.kind === 'audiooutput'
      );
      const defaultAudioOutput =
        audioOutputs.find((device) => device.deviceId === 'default') ||
        audioOutputs.find((device) =>
          device.label.toLowerCase().includes('default')
        ) ||
        audioOutputs[0];
      setDefaultMic(
        defaultAudioInput
          ? defaultAudioInput.label || 'Default Microphone'
          : 'No microphone found'
      );

      setDefaultSpeaker(
        defaultAudioOutput
          ? defaultAudioOutput.label || 'Default Speaker'
          : 'No speaker found'
      );
    } catch (error) {
      console.error('Error getting audio devices:', error);
      setDefaultMic('Unable to access microphone');
      setDefaultSpeaker('Unable to access speaker');
    }
  };

  useEffect(() => {
    updateDevices();
    const handleDeviceChange = () => {
      updateDevices();
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      );
    };
  }, [hasPermission]);

  return (
    <Box sx={{ width: '100%' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              mr: 2,
              border: '1px solid #C0D4D3FF',
              borderRadius: '40px',
              padding: '10px 20px',
              cursor: 'not-allowed',
            }}
          >
            <MicIcon sx={{ color: 'primary.main', mr: 1 }} />
            <Typography
              variant="body1"
              sx={{
                wordBreak: 'break-word',
                fontSize: '14px',
                color: '#878B8BFF',
              }}
            >
              {defaultMic}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              mr: 2,
              border: '1px solid #C0D4D3FF',
              borderRadius: '40px',
              padding: '10px 20px',
              cursor: 'not-allowed',
            }}
          >
            <VolumeUpIcon sx={{ color: 'primary.main', mr: 1 }} />
            <Typography
              variant="body1"
              sx={{
                wordBreak: 'break-word',
                fontSize: '14px',
                color: '#878B8BFF',
              }}
            >
              {defaultSpeaker}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Box>
  );
};

export default DeviceSelector;
