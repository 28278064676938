import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';

const ConfirmModal = ({
  open,
  onClose,
  icon: Icon,
  title,
  message,
  confirmText = 'Yes',
  cancelText = 'No',
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={24}
        sx={{
          position: 'relative',
          maxWidth: '400px',
          width: '90%',
          mx: 2,
          p: 3,
          outline: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {Icon && (
            <Box sx={{ mb: 2 }}>
              <Icon sx={{ fontSize: 48, color: '#E03838' }} />
            </Box>
          )}

          {title && (
            <Typography
              variant="h6"
              component="h2"
              sx={{ mb: 1, textAlign: 'center' }}
            >
              {title}
            </Typography>
          )}

          <Typography
            variant="body1"
            sx={{ mb: 3, textAlign: 'center', color: 'text.secondary' }}
          >
            {message}
          </Typography>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onConfirm?.();
                onClose();
              }}
              sx={{
                minWidth: '100px',
                '&:hover': {
                  backgroundColor: 'error.dark',
                },
              }}
            >
              {confirmText}
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                onCancel?.();
                onClose();
              }}
              sx={{
                minWidth: '100px',
                borderColor: 'grey.300',
                color: 'text.primary',
                '&:hover': {
                  backgroundColor: 'action.hover',
                  borderColor: 'grey.400',
                },
              }}
            >
              {cancelText}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const openModal = useCallback((props = {}) => {
    setModalProps(props);
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setModalProps({});
  }, []);

  const Modal = useCallback(
    (props) => (
      <ConfirmModal
        open={isOpen}
        onClose={closeModal}
        {...modalProps}
        {...props}
      />
    ),
    [isOpen, modalProps, closeModal]
  );

  return {
    isOpen,
    openModal,
    closeModal,
    Modal,
  };
};
export default useModal;
