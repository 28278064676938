import { useTheme } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register the required Chart.js components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export const ProgressChart = () => {
  const theme = useTheme();
  const { palette } = theme;
  const labels = [
    'JULY 2024',
    'AUGUST 2024',
    'SEPTEMBER 2024',
    'OCTOBER 2024',
    'NOVEMBER 2024',
    'DECEMBER 2024',
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: 200,
        ticks: {
          family: 'Lexend, sans-serif',
          stepSize: 25,
        },
        grid: {
          color: '#f0f0f0',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          family: 'Lexend, sans-serif',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 20,
        },
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: '#e0e0e0',
        borderWidth: 1,
        padding: 12,
        callbacks: {
          title: function (context) {
            return context[0].raw.title || '';
          },
          label: function (context) {
            const score = context.raw.y;
            if (context.raw.details) {
              return [
                `${score}/200`,
                context.raw.details.name,
                context.raw.details.date,
                `↑ ${context.raw.details.overBase} over base`,
                `↓ ${context.raw.details.fromPrevious} from previous`,
              ];
            }
            return `${score}`;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Student Progress',
        data: [
          { x: 'JULY 2024', y: 112 },
          { x: 'AUGUST 2024', y: 165 },
          {
            x: 'SEPTEMBER 2024',
            y: 103,
            title: 'Current Score',
            details: {
              name: 'Orwell Inspector Psych Eva',
              date: 'August 12, 2024',
              overBase: '+18.75%',
              fromPrevious: '-8.90%',
            },
          },
          { x: 'OCTOBER 2024', y: 145 },
          { x: 'NOVEMBER 2024', y: 178 },
        ],
        borderColor: palette.success.main,
        backgroundColor: 'white',
        pointBorderColor: palette.success.main,
        pointBackgroundColor: 'white',
        pointBorderWidth: 1,
        borderWidth: 1,
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0,
      },
      {
        label: 'Expected Progress Pattern',
        data: [
          { x: 'JULY 2024', y: 125 },
          { x: 'AUGUST 2024', y: 135 },
          { x: 'SEPTEMBER 2024', y: 145 },
          { x: 'OCTOBER 2024', y: 155 },
          { x: 'NOVEMBER 2024', y: 165 },
          { x: 'DECEMBER 2024', y: 175 },
        ],
        borderColor: '#e0e0e0',
        borderDash: [5, 5],
        pointRadius: 0,
        tension: 0.3,
      },
    ],
  };

  return (
    <div
      style={{
        background: palette.background.paper,
        height: '30vw',
        width: '100%',
        //maxWidth: '800px',
        //margin: 'auto',

        padding: '10px',
        borderRadius: '10px',
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default ProgressChart;
