import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const SummaryStats = ({
  icon,
  title_left,
  title_right,
  description_left,
  description_right,
  status,
}) => {
  const renderStatusIcon = () => {
    switch (status) {
      case 'warning':
        return <WarningAmberIcon style={{ color: 'orange' }} />;
      case 'success':
        return <CheckIcon style={{ color: 'green' }} />;
      case 'error':
        return <ErrorOutlineIcon style={{ color: 'red' }} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: '95%',
        padding: '10px',
        border: '1px solid #dae8e7',
        backgroundColor: '#fff',
        borderRadius: '5px',
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '5px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        {icon}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              color: '#5b706f',
              fontFamily: 'Lexend',
              fontSize: '12px',
              fontWeight: 300,
            }}
          >
            {title_left}
          </Typography>
          <Typography
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {description_left}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'flex-end',
        }}
      >
        <Typography
          sx={{
            color: '#5b706f',
            fontFamily: 'Lexend',
            fontSize: '12px',
            fontWeight: 300,
          }}
        >
          {title_right}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {renderStatusIcon()}
          <Typography
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontWeight: 500,
              wordBreak: 'break-word',
            }}
          >
            {description_right}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryStats;
