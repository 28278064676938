import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';

const statusColors = {
  neutral: '#00BF8C',
  brusque: '#E03838',
  default: '#802B2B',
};

export const TeamIndividulaCallHistory = ({ data, personaID }) => {
  const [expand, setExpand] = useState(false);
  const { selectPersona, selectedPersona } = usePersonaStore();
  const navigate = useNavigate();
  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  const imageUrl =
    data.avatar && data.avatar !== 'invalid'
      ? data.avatar
      : data.gender === 'male'
      ? maleImage
      : femaleImage;

  const handleNavigation = () => {
    navigate(`/airoleplaycall/ongoingcall/${data._id}`);
  };

  return (
    <Box
      sx={{
        border: `1px solid ${
          selectedPersona?._id === personaID ? '#00BF8C' : '#dae8e7'
        }`,
        borderRadius: '8px',
        width: '94%',
        height: '100%',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => selectPersona(personaID)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '10px',
            padding: '5px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                overflow: 'hidden',
              }}
            >
              <img
                src={imageUrl}
                alt={data?.name || 'Profile Image'}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {data?.name || 'Anonymous'}
              </Typography>
              <Typography
                sx={{
                  color: '#5b706f',
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontWeight: 300,
                }}
              >
                {data?.role ? `${data.role}` : ''}{' '}
                {data?.company ? `@ ${data.company}` : ''}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Typography
              sx={{
                padding: '3px 20px',
                backgroundColor: 'white',
                borderRadius: '20px',
                color:
                  statusColors[data?.mood?.toLowerCase()] ||
                  statusColors.default,
                border: `1px solid ${
                  statusColors[data?.mood?.toLowerCase()] ||
                  statusColors.default
                }`,
                fontFamily: 'Lexend',
                fontSize: '14px',
                fontWeight: 300,
                textTransform: 'capitalize',
              }}
            >
              {data?.mood || 'Neutral'}
            </Typography>
            <IconButton
              sx={{
                width: '32px',
                height: '32px',
                border: '1px solid #00706c',
                borderRadius: '50%',
                color: '#00706c',
                '&:hover': {
                  backgroundColor: '#00706c',
                  color: '#fff',
                },
              }}
              onClick={() => {
                selectPersona(data._id);
                handleNavigation();
              }}
            >
              <LocalPhoneIcon fontSize="small" />{' '}
            </IconButton>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: '#5b706f',
              fontFamily: 'Lexend',
              fontSize: '12px',
              fontWeight: 300,
              whiteSpace: 'nowrap',
            }}
          >
            {expand ? 'HIDE CALL HISTORY' : 'SHOW CALL HISTORY'}
          </Typography>
          <Divider sx={{ flexGrow: 1, backgroundColor: '#bfcecd' }} />{' '}
          <Button
            onClick={() => setExpand(!expand)}
            sx={{
              backgroundColor: 'transparent',
              outline: 'none',
              cursor: 'pointer',
              padding: 0,
              minWidth: '0',
              minHeight: '0',
              width: 'fit-content',
              height: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {expand ? (
              <ExpandLessIcon fontSize="small" />
            ) : (
              <ExpandMoreIcon fontSize="small" />
            )}
          </Button>
        </Box> */}
        {/* {expand && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              width: '98%',
              gap: '10px',
            }}
          >
            {data.callRecords.map((item, index) => (
              <CallInfo
                key={index}
                title={item.title}
                dateTime={item.dateTime}
                duration={item.duration}
              />
            ))}
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default TeamIndividulaCallHistory;
