import apis from 'api/api';
import { useApi } from 'hooks';

const { createContext, useContext, useState, useEffect } = require('react');

export const useStudentDashboard = () => {
  const {
    call: fetchLoggedUserInfo,
    data: loggedUserInfo,
    isLoading: userInfoLoading,
  } = useApi({
    fetcher: apis.getMe,
  });

  useEffect(() => {
    fetchLoggedUserInfo();
  }, []);

  return { loggedUserInfo, userInfoLoading };
};

export const StudentDashboardContext = createContext();
export const useStudentDashboardContext = () =>
  useContext(StudentDashboardContext);
export const StudentDashboardProvider = ({ children }) => {
  const [dashboardData, setDashboardData] = useState({});
  const { loggedUserInfo, userInfoLoading } = useStudentDashboard();
  return (
    <StudentDashboardContext.Provider
      value={{
        data: dashboardData,
        setData: setDashboardData,
        loggedUserInfo,
        userInfoLoading,
      }}
    >
      {children}
    </StudentDashboardContext.Provider>
  );
};
