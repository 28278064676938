import * as Yup from 'yup';

export const schema = ({ type }) =>
  Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    region: Yup.string().required('Region name is required'),
    school: Yup.string().required('School name is required'),
    course:
      type === 'ADD'
        ? Yup.string().required('Course name is required')
        : Yup.array()
            .of(Yup.string())
            .min(1, 'At least one course is required')
            .required('Course selection is required'),
  });
