import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Chip,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
} from '@mui/material';
import { forwardRef, useState } from 'react';

export const MultiSelect = forwardRef(
  (
    {
      options = [],
      selectedValues = [],
      handleSelectionChange,
      handleDelete,
      loading,
      disabled,
      error,
      errorMessage,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const handleChange = (e) => {
      handleSelectionChange(e);
      setOpen(false);
    };

    return (
      <>
        <Select
          {...rest}
          multiple
          ref={ref}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          error={error}
          value={selectedValues}
          onChange={handleChange}
          disabled={loading || disabled}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const selectedOption =
                  (options &&
                    options.length &&
                    options.find((option) => option.value === value)) ||
                  '';
                return selectedOption ? (
                  <Chip
                    key={value}
                    label={selectedOption.label}
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={(e) => e.preventDefault()}
                    deleteIcon={
                      <CancelIcon sx={{ ':hover': { fill: 'tomato' } }} />
                    }
                    onDelete={() => handleDelete(value)}
                  />
                ) : null;
              })}
            </Box>
          )}
        >
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
              <span style={{ marginLeft: 10 }}>Loading...</span>
            </MenuItem>
          ) : (
            options &&
            options.length &&
            options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))
          )}
        </Select>
        <FormHelperText error={error}>{errorMessage}</FormHelperText>
      </>
    );
  }
);

export default MultiSelect;
