import { Stack } from '@mui/material';
import { CourseProvider } from 'pages/Settings/context';
import CourseAdd from './CourseAdd';
import CourseList from './CourseList';

const Courses = () => {
  return (
    <CourseProvider>
      <Stack spacing={3}>
        <CourseAdd />
        <CourseList />
      </Stack>
    </CourseProvider>
  );
};

export default Courses;
