import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import CallInfo from './CallInfo';

export const IndividualCallHistory = ({ image, username, data }) => {
  const [expand, setExpand] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
        alignItems: 'start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          width: '100%',
        }}
      >
        <Avatar src={image} alt={username} sx={{ width: 25, height: 25 }} />
        <Typography
          color="#5b706f"
          fontSize={14}
          fontWeight={500}
          fontFamily="Lexend"
          noWrap
        >
          {username}
        </Typography>
        <Divider sx={{ flexGrow: 1, height: 1, backgroundColor: '#bfcecd' }} />
        <IconButton onClick={() => setExpand(!expand)} sx={{ padding: 0 }}>
          {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {data && expand ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            alignItems: 'start',
            width: '97%',
            ml: '30px',
            my: '10px',
          }}
        >
          {data &&
            data?.data.map((item, index) => (
              <CallInfo
                title={item.title}
                dateTime={item.createdAt}
                duration={item.duration}
                key={item.id}
                id={item.id}
              />
            ))}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default IndividualCallHistory;
