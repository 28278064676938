import { Stack } from '@mui/material';

import { UserProvider } from '../../context';
import AdminAdd from './AdminAdd';
import AdminList from './AdminList';

export const Admin = () => {
  return (
    <UserProvider userType={'ADMIN'}>
      <Stack spacing={3}>
        <AdminAdd />
        <AdminList />
      </Stack>
    </UserProvider>
  );
};

export default Admin;
