import Box from '@mui/material/Box';
import { useEffect, useMemo } from 'react';
import EmptyState from './EmptyState';
import Message from './Message';

const Transcript = ({ data }) => {
  const formatTime = useMemo(
    () => (timestamp) => {
      const date = new Date(timestamp);
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      return `${minutes}:${seconds}`;
    },
    []
  );

  const mapMessagesToTranscript = (messages) => {
    return messages.map((message) => {
      const formattedTime = formatTime(message.time);
      const userAvatar = '/user.jpg';
      const aiAvatar = '/female.jpg';

      if (message.sender === 'user') {
        return {
          type: 'human-response',
          userAvatar: userAvatar,
          time: formattedTime,
          message: message.message,
          aiGeneratedResponse: message.aiResponse
            ? {
                type: 'ai-generated-answer',
                time: formattedTime,
                message: message.aiResponse,
                score: message.aiScore,
              }
            : null,
        };
      } else if (message.sender === 'persona') {
        return {
          type: 'llm-response',
          userAvatar: aiAvatar,
          time: formattedTime,
          message: message.message,
        };
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formattedData =
    data && data.length > 0 ? mapMessagesToTranscript(data) : [];

  if (formattedData.length > 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          padding: '10px',
          border: '1px solid #dae8e7',
          borderRadius: '5px',
          width: '100%',
          height: '95%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {formattedData.map((item, index) => (
          <Message key={index} data={item} />
        ))}
      </Box>
    );
  } else {
    return <EmptyState />;
  }
};

export default Transcript;
