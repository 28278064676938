import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Popover, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import { Calendar as RDRCalendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

export const Calendar = forwardRef(
  (
    {
      label = 'Select Date',
      initialDate = new Date(),
      locale = 'enUS',
      onDateChange,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(initialDate);

    const handleInputClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDateChange = (date) => {
      setSelectedDate(date);
      if (onDateChange) {
        onDateChange(date);
      }
      handleClose();
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <TextField
          size="small"
          label={label}
          value={selectedDate ? selectedDate.toLocaleDateString() : ''}
          onClick={handleInputClick}
          readOnly
          InputProps={{
            endAdornment: !selectedDate ? (
              <IconButton size="small" onClick={handleInputClick}>
                <CalendarTodayIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={() => {
                  setSelectedDate(null);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <RDRCalendar
            ref={ref}
            locale={locales[locale]}
            date={selectedDate}
            onChange={(date) => handleDateChange(date)}
          />
        </Popover>
      </>
    );
  }
);
