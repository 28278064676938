import { create } from 'zustand';

const useNotificationStore = create((set) => ({
  notification: null,
  setNotification: (message, type) => set({ notification: { message, type } }),
  getNotification: () => set((state) => state.notification),
  clearNotification: () => set({ notification: null }),
}));

export default useNotificationStore;
