import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
export const SummaryInfo = ({ data }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
        height: '200px',
      }}
    >
      {data && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            mb: 2,
            height: '100%',
          }}
        >
          <Typography
            variant="h6"
            component="h3"
            sx={{
              color: '#243030',
              fontWeight: 500,
              textTransform: 'capitalize',
              marginBottom: '8px',
            }}
          >
            {data.title}
          </Typography>
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: '180px',
            }}
          >
            {data.analysis.length > 0 && (
              <List sx={{ padding: 0, margin: 0 }}>
                {data.analysis.map((_info, _index) => (
                  <ListItem key={_index} sx={{ padding: 0 }}>
                    <Typography
                      sx={{
                        color: '#5b706f',
                        fontWeight: 300,
                        lineHeight: '30px',
                      }}
                    >
                      {`${_index + 1}. ${_info}`}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SummaryInfo;
