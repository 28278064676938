import { Stack } from '@mui/material';
import { AdminAdd } from './AdminAdd';
import { AdminList } from './AdminList';

export const Admins = () => {
  return (
    <Stack spacing={3}>
      <AdminAdd />
      <AdminList />
    </Stack>
  );
};
