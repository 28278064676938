import { createContext, useContext, useState } from 'react';

const DialogContext = createContext(undefined);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

export const DialogProvider = ({ children }) => {
  const initialDialogStatus = {
    edit: false,
    delete: false,
    revoke: false,
    bulkInvite: false,
  };

  const [isDialogOpen, setDialogOpen] = useState(initialDialogStatus);
  const [dialogData, setDialogData] = useState(null);

  const openEditDialog = ({ data }) => {
    setDialogOpen({ ...initialDialogStatus, edit: true });
    setDialogData(data);
  };

  const openDeleteDialog = ({ data }) => {
    setDialogOpen({ ...initialDialogStatus, delete: true });
    setDialogData(data);
  };

  const openRevokeDialog = ({ data }) => {
    setDialogOpen({ ...initialDialogStatus, revoke: true });
    setDialogData(data);
  };

  const openBulkInviteDialog = ({ data }) => {
    setDialogOpen({ ...initialDialogStatus, bulkInvite: true });
    setDialogData(data);
  };

  const openDialog = ({ data, name }) => {
    setDialogOpen({ ...initialDialogStatus, [name]: true });
    setDialogData(data);
  };

  const closeDialog = () => {
    setDialogOpen(initialDialogStatus);
  };

  return (
    <DialogContext.Provider
      value={{
        isDialogOpen,

        openDialog,
        openEditDialog,
        openDeleteDialog,
        openRevokeDialog,
        openBulkInviteDialog,

        closeDialog,
        dialogData,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
