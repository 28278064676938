import { useAudioTranscription } from './useAudioTranscription';

export default function Test() {
 const {
   isRecording,
   isProcessing,
   transcript,
   error,
   toggleRecording,
   downloadMergedAudio,
   hasAudio,
 } = useAudioTranscription();

 return (
   <div>
     <button onClick={toggleRecording}>
       {isRecording ? 'Stop Recording' : 'Start Recording'}
     </button>
     {hasAudio && (
       <button onClick={downloadMergedAudio}>Download Conversation</button>
     )}
     {transcript && <p>Transcript: {transcript}</p>}
     {error && <p>Error: {error}</p>}
     {isProcessing && <p>Processing...</p>}
   </div>
 );
}
