import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

const BarContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  margin: 'auto',
  height: '100%',
  '& p': {
    color: '#5b706f',
    fontFamily: 'Lexend',
    fontSize: 14,
    fontWeight: 300,
  },
}));

const BarWrapper = styled(Box)(({ theme, progress }) => ({
  position: 'relative',
  backgroundColor: '#fff',
  borderRadius: 5,
  height: 40,
  margin: '10px 0',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  transition: 'background-color 1.5s ease',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: progress,
    backgroundColor: '#dae8e7',
    borderRadius: 5,
    transition: 'width 1.5s ease',
  },
}));

const BarInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  marginLeft: 10,
});

const Label = styled(Typography)({
  fontFamily: 'Lexend',
  fontSize: 16,
  fontWeight: 900,
  zIndex: 1,
  color: '#243030',
});

const Percentage = styled(Typography)({
  position: 'absolute',
  right: 10,
  fontFamily: 'Lexend',
  fontSize: 16,
  fontWeight: 500,
});

export const HorizontalBarChart = ({ data }) => {
  const [inView, setInView] = useState(false);
  const barContainerRef = useRef(null);

  // const avatar = '/female.jpg';
  // const imageUrl =
  //   data.avatar && data.avatar !== 'invalid' ? data.avatar : avatar;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (barContainerRef.current) {
      observer.observe(barContainerRef.current);
    }

    return () => {
      if (barContainerRef.current) {
        observer.unobserve(barContainerRef.current);
      }
    };
  }, []);

  return (
    <BarContainer ref={barContainerRef}>
      {data &&
        data.map((item, index) => {
          const avatar = '/user.jpg';
          const imageUrl =
            item.avatar && item.avatar !== 'invalid' ? item.avatar : avatar;

          return (
            <BarWrapper
              key={index}
              progress={inView ? `${item.percentage}%` : '0%'}
            >
              <BarInfo>
                <Avatar
                  src={imageUrl}
                  alt={item.name}
                  sx={{ width: 25, height: 25 }}
                />
                <Label>{item.name}</Label>
              </BarInfo>
              <Percentage>{`${item.percentage}%`}</Percentage>
            </BarWrapper>
          );
        })}
    </BarContainer>
  );
};

export default HorizontalBarChart;
