import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { EmptyState } from 'components/CustomMUI';
const BasicInformation = ({ data }) => {
  return (
    <Box
      sx={{
        width: '95%',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Typography variant="h6" sx={{ color: '#000' }}>
        Basic Information
      </Typography>
      <Divider sx={{ borderColor: '#DAE8E7', marginY: 1 }} />
      {data ? (
        <Box
          sx={{
            border: `1px solid #DAE8E7`,
            borderRadius: '8px',
            padding: '20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 4,
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 300 }}
            >
              NAME
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontFamily: 'Lexend', fontSize: '16px', fontWeight: 500 }}
            >
              {data.name}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 300 }}
            >
              CUSTOMER CALLS HANDLED
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontFamily: 'Lexend', fontSize: '16px', fontWeight: 500 }}
            >
              {data.customerCalls}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 300 }}
            >
              AI CALLS HANDLED
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontFamily: 'Lexend', fontSize: '16px', fontWeight: 500 }}
            >
              {data.aiCalls}
            </Typography>
          </Box>
        </Box>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
};

export default BasicInformation;
