import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import useNotificationStore from 'store/useNotificationStore';

const useNotification = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('info');
  const { clearNotification } = useNotificationStore();
  const show = useCallback((msg, notificationType = 'info') => {
    setMessage(msg);
    setType(notificationType);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    clearNotification();
  }, []);

  const notificationStyles = useMemo(
    () => ({
      error: { bgcolor: 'error.main', icon: <ErrorIcon /> },
      warning: { bgcolor: 'warning.main', icon: <WarningIcon /> },
      success: { bgcolor: 'success.main', icon: <CheckCircleIcon /> },
      info: { bgcolor: 'info.main', icon: <InfoIcon /> },
      default: { bgcolor: 'grey.500', icon: null },
    }),
    []
  );

  const currentStyle = useMemo(() => {
    return notificationStyles[type] || notificationStyles.default;
  }, [type, notificationStyles]);

  const NotificationComponent = useCallback(() => {
    const { bgcolor, icon } = currentStyle;

    return (
      <>
        <Backdrop
          open={open}
          sx={{
            zIndex: 1300,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            height: '100%',
          }}
        />
        <Snackbar
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          // autoHideDuration={6000}
          sx={{ width: '100%', height: '100%' }}
        >
          <Box
            sx={{
              bgcolor,
              color: 'white',
              p: 2,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1400,
            }}
          >
            {icon}
            <Typography variant="body1" sx={{ flexGrow: 1, ml: 1 }}>
              {message}
            </Typography>
            <IconButton color="inherit" onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Snackbar>
      </>
    );
  }, [open, message, currentStyle, handleClose]);

  return {
    show,
    NotificationComponent,
  };
};

export default useNotification;
