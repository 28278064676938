import { createContext, useContext } from 'react';
import { useUser } from './hooks/useUser';

export const UserContext = createContext();
export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children, userType }) => {
  const {
    data,

    isDeleting,
    isFetching,
    isLoading,
    isPosting,
    isUpdating,
    isRevoking,

    get,
    post,
    remove,
    revoke,
    update,
  } = useUser({ userType });
  return (
    <UserContext.Provider
      value={{
        data,

        isDeleting,
        isFetching,
        isLoading,
        isPosting,
        isUpdating,
        isRevoking,

        get,
        post,
        remove,
        revoke,
        update,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
