import { AddPhotoAlternate } from '@mui/icons-material';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';

const initialAvatars = [
  '/pattern.png', // Replace with actual avatar URLs
  '/pattern2.png',
];

export const AvatarSelector = () => {
  const [avatars, setAvatars] = useState(initialAvatars);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const handleAvatarClick = (index) => {
    setSelectedAvatar(index);
  };

  const handleUploadClick = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target.result) {
          setAvatars((prevAvatars) => [
            ...(prevAvatars || []),
            e.target.result,
          ]);
          setSelectedAvatar((prevAvatars) => prevAvatars?.length || 0);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography variant="subtitle1" sx={{ flex: 1 }} gutterBottom>
        Select a Team Avatar
      </Typography>
      <Box
        sx={{ flex: 1 }}
        display="flex"
        gap={2}
        padding={1}
        border={1}
        borderColor="grey.300"
        borderRadius={2}
      >
        {avatars.map((src, index) => (
          <Avatar
            key={index}
            src={src}
            sx={{
              width: 60,
              height: 60,
              border:
                selectedAvatar === index
                  ? '2px solid #4CAF50'
                  : '2px solid transparent',
              cursor: 'pointer',
            }}
            onClick={() => handleAvatarClick(index)}
          />
        ))}
        <label htmlFor="upload-button">
          <input
            type="file"
            id="upload-button"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleUploadClick}
          />
          <IconButton
            component="span"
            sx={{
              width: 60,
              height: 60,
              borderRadius: 1,
              border: '2px solid transparent',
              '&:hover': {
                border: '2px solid #4CAF50',
              },
            }}
          >
            <AddPhotoAlternate style={{ color: '#9E9E9E', fontSize: 40 }} />
          </IconButton>
        </label>
      </Box>
    </Box>
  );
};
