import React, { useEffect, useState } from "react";
import styles from "../../styles/LearnerDashboard.module.css";
import { MainContent, Sidebar } from "components/sidebar";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import LoadingOverlay from "components/LoadingOverlay";

import { ProgressIcon, DoneIcon } from "svg/CustomSVGIcon";

import organizationAPI from "api/api";
import { CustomTextField } from "components/TextField";
import { CustomButton } from "components/Button";
import { CustomLinearProgress } from "components/Progress";

const LearnerDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [canvasList, setCanvasList] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const getAllCanvas = async () => {
    setLoading(true);
    const response = await organizationAPI.getAllCanvasForCoach();

    setCanvasList(response.data);
    setLoading(false);
  };

  const getProfile = async () => {
    setLoading(true);
    const response = await organizationAPI.getUser();
    setUser(response.data.user);
    setLoading(false);
  };

  useEffect(() => {
    if (!user) getProfile();
  }, []);

  useEffect(() => {
    getAllCanvas();
  }, [user]);

  const convertDate = (dateString) => {
    return moment(dateString).format("dddd • MMMM D, YYYY ");
  };

  const handleCanvasNavigation = (canvasId) => (e) => {
    e.preventDefault();
    navigate(`/coach/learner/canvas/${canvasId}`);
  };
  return (
    <MainContent user={user} active={"canvas"}>
      {loading && <LoadingOverlay />}
      <h1>Welcome, {user && user.firstName + " " + user.lastName}</h1>

      <div className={styles.learner_header}>
        <h2>Lean Business Model Canvas Iterations</h2>
        <div className={styles.learner_header_buttons}>
          <CustomTextField
            className={styles.search}
            label={"Search"}
            placeholder={
              "Search for a student, keyword, ITR, date, year, month or time..."
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className={styles.coach_roaster}>
        <div className={styles.learner_table}>
          <table className="styled_table">
            <thead className="table_headers">
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Canvas Name</th>
                <th>Student Name</th>
                <th>Course</th>
                <th>Score</th>
                <th>Progress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {canvasList.length > 0 &&
                canvasList
                  .filter((item) => {
                    if (search === "" || search === undefined) {
                      return item;
                    } else if (
                      item.canvasName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      convertDate(item.createdAt)
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item.status.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item.canvasName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      `${item.userProfile.firstName} ${item.userProfile.lastName}`
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item.userProfile.course
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>
                          {convertDate(item.createdAt)}
                        </td>
                        <td className={styles.data}>
                          <div
                            className={
                              item.status === "progress"
                                ? styles.progress
                                : item.status === "review"
                                ? styles.review
                                : styles.done
                            }
                          >
                            {item.status === "progress" ? (
                              ""
                            ) : item.status === "review" ? (
                              <ProgressIcon />
                            ) : (
                              <DoneIcon />
                            )}

                            {item.status.toUpperCase()}
                          </div>
                        </td>
                        <td className={styles.data}>{item.canvasName}</td>
                        <td
                          className={styles.data}
                        >{`${item.userProfile.firstName} ${item.userProfile.lastName}`}</td>

                        <td className={styles.data}>
                          {item.userProfile.course}
                        </td>
                        <td className={styles.data}>
                          {item.gradings.length > 0
                            ? item.gradings[item.gradings.length - 1].tiles.map(
                                (tile) => {
                                  if (tile.name === "overall") {
                                    return tile.score;
                                  }
                                }
                              )
                            : "Not Graded Yet"}
                        </td>
                        <td className={styles.data}>
                          <CustomLinearProgress
                            value={item.progressPercentage}
                          />
                        </td>
                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <VisibilityOutlinedIcon />
                              <button
                                onClick={handleCanvasNavigation(item._id)}
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {canvasList.length === 0 && (
            <div className={styles.empty_table}>
              <p>No Business Canvas listed yet.</p>
            </div>
          )}
        </div>
      </div>
    </MainContent>
  );
};

export default LearnerDashboard;
