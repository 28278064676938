import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const DisabledRoleOptions = ({ selectedRole }) => (
  <FormControl sx={{ flex: 1 }} size="small">
    <InputLabel id="role">Role</InputLabel>
    <Select disabled labelId="role" id="role" value={selectedRole} label="Role">
      {[selectedRole].map((roleName) => {
        return (
          <MenuItem key={roleName} value={roleName}>
            {roleName}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
);
