import { createContext, useContext } from 'react';
import { useCourse } from './hooks/useCourse';

export const CourseContext = createContext();
export const useCourseContext = () => {
  return useContext(CourseContext);
};

export const CourseProvider = ({ children }) => {
  const {
    data,
    get,
    isDeleting,
    isFetching,
    isLoading,
    isPosting,
    isUpdating,
    post,
    remove,
    update,
  } = useCourse();
  return (
    <CourseContext.Provider
      value={{
        data,

        isDeleting,
        isFetching,
        isLoading,
        isPosting,
        isUpdating,

        get,
        post,
        remove,
        update,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};
