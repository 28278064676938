import { Stack } from '@mui/material';
import { UserProvider } from '../../context';
import CoachAdd from './CoachAdd';
import CoachList from './CoachList';
const Coaches = () => {
  return (
    <UserProvider userType={'COACH'}>
      <Stack spacing={3}>
        <CoachAdd />
        <CoachList />
      </Stack>
    </UserProvider>
  );
};

export default Coaches;
