import { useState, useMemo } from 'react';

export const useTableSearch = ({ data, searchFields, dateRangeColumn }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const filteredData = useMemo(() => {
    if (!data) return [];

    return data.filter((item) => {
      const itemDate = new Date(item[dateRangeColumn]);

      const isWithinDateRange =
        (!startDate || new Date(startDate) <= itemDate) &&
        (!endDate || new Date(endDate).setHours(23, 59, 59, 999) >= itemDate);

      if (!isWithinDateRange) return false;

      return searchFields.some((field) => {
        const fieldValue = item[field];

        // Check if the field is a valid date and matches the search term
        if (!isNaN(Date.parse(fieldValue))) {
          const formattedDate = new Date(fieldValue)
            .toISOString()
            .split('T')[0];
          return formattedDate.includes(searchTerm);
        }

        // General string match for non-date fields
        return fieldValue
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    });
  }, [data, searchTerm, searchFields, startDate, endDate, dateRangeColumn]);

  return {
    searchTerm,
    setSearchTerm,
    filteredData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
};
