import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import apis from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import { useUser } from 'pages/Settings/context';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useConvoDashboardStore } from 'store';
import { createSortedSelectOptions } from 'utils';
import EditDialogWrapper from '../common/EditDialogWrapper';
import { addTeam } from '../common/schema';
import { AvatarSelector } from './AvatarSelector';

const blankTeamInfo = {
  name: '',
  description: '',
  assignedReps: [],
};
const TeamEditDialog = () => {
  const [teamInfo, setTeamInfo] = useState(blankTeamInfo);

  const dialogState = useConvoDashboardStore((state) => state.editDialog);
  const changeDialogState = useConvoDashboardStore(
    (state) => state.setEditDialog
  );
  const { data } = dialogState;

  const {
    data: SRList,
    get: fetchAllSR,
    isFetching: srLoading,
  } = useUser({ userType: 'SALES_REP' });

  const {
    data: personaList,
    call: fetchAllPersonas,
    isFetching: personasLoading,
  } = useApi({ fetcher: apis.getAllPersonas });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addTeam),
    mode: 'onChange',
    defaultValues: { role: 'ADMIN' },
  });

  const updateTeamInfo = (data) => {
    console.log('❎ ~ data:', data);
  };

  useEffect(() => {
    console.log('❎ ~ data:', data);

    const initialUserInfo = {
      name: data?.name || '',
      description: data?.description || '',
      assignedReps: ['67345336484ee1accc72f9f1'],
      assignedPersonas: data?.assignedPersonas || [],
    };
    reset(data);
    setTeamInfo(initialUserInfo);
  }, [data, reset]);

  useEffect(() => {
    fetchAllSR();
    fetchAllPersonas();
  }, []);

  const renderFormFields = () => {
    const fields = [
      {
        name: 'name',
        label: 'Team Name',
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      {
        name: 'assignedReps',
        label: 'Sales Representatives',
        type: 'multiSelect',
        options: createSortedSelectOptions(SRList ?? [], 'id', `firstName`),
      },
      {
        name: 'assignedPersons',
        label: 'Assigned Personas',
        type: 'multiSelect',
        options: createSortedSelectOptions(personaList ?? [], '_id', 'name'),
      },
    ];

    return fields.map((item) => (
      <FormField
        loading={srLoading || personasLoading}
        key={item.name}
        field={item}
        register={register}
        errors={errors}
        watch={watch}
        state={teamInfo}
        stateUpdater={setTeamInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <>
      {srLoading ? (
        <LoadingOverlay />
      ) : (
        <EditDialogWrapper
          onSubmit={handleSubmit(updateTeamInfo)}
          dialogType={'edit'}
          title={'Update Team Info'}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <AvatarSelector />
            {renderFormFields()}
          </Box>
        </EditDialogWrapper>
      )}
    </>
  );
};

export default TeamEditDialog;
