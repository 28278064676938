import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useDialog, useUserContext } from 'pages/Settings/context';

export const DeleteDialog = () => {
  const { isDialogOpen, closeDialog, dialogData } = useDialog();
  const { remove } = useUserContext();

  const handleDelete = () => {
    remove({ userId: dialogData.id, email: dialogData.email });
    closeDialog();
  };

  return (
    <Dialog
      open={isDialogOpen.delete}
      onClose={closeDialog}
      maxWidth="xs"
      sx={{ textAlign: 'center' }}
      PaperProps={{ sx: { padding: 1 } }}
    >
      <DialogTitle>
        <RemoveCircleOutline style={{ color: '#F44336', fontSize: 50 }} />
        <Typography variant="h6" component="h6" style={{ marginTop: '10px' }}>
          Delete Student
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this student?
          <br />
          This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'center', paddingBottom: '20px' }}
      >
        <Button
          fullWidth
          color="error"
          variant="contained"
          onClick={handleDelete}
        >
          Yes
        </Button>
        <Button fullWidth variant="outlined" onClick={closeDialog} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
