import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { EmptyState } from 'components/CustomMUI';
import { useCallback, useEffect, useState } from 'react';
import useStore from 'store/useStore';
import CreateNewTeam from './CreateTeam';
import ExpandableTableBody from './ExpandableTableBody';

const cellStyles = {
  borderBottom: '1px solid #DAE8E7',
  color: '#243030',
  fontWeight: 500,
  fontFamily: 'Lexend',
  fontSize: '14px',
  textTransform: 'capitalize',
  padding: '16px',
};

const SalesRepresentativesTable = ({ setLoading }) => {
  const [team, setTeam] = useState([]);
  const { organizationId, dataUpdated, clearDataUpdated } = useStore();
  const fetchData = useCallback(async () => {
    if (!organizationId) return;

    try {
      setLoading(true);
      const response = await organizationAPI.getConvoTeam(organizationId);
      if (response && response.data) {
        setTeam((prevTeam) => {
          return JSON.stringify(prevTeam) !== JSON.stringify(response.data)
            ? response.data
            : prevTeam;
        });
        setLoading(false);
        clearDataUpdated();
      }
    } catch (error) {
      console.error('Error fetching teams', error);
      setLoading(false);
    }
  }, [organizationId]);

  useEffect(() => {
    fetchData();
  }, [organizationId, dataUpdated]);

  return (
    <Box
      sx={{
        width: '98%',
        border: '1px solid #DAE8E7',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#243030',
            fontFamily: 'Lexend',
            fontSize: '20px',
            fontWeight: 300,
          }}
        >
          Sales Representatives
        </Typography>
        <CreateNewTeam />
      </Box>
      <Box sx={{ border: '1px solid #DAE8E7', marginBottom: '20px' }} />
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 'none', border: 'none' }}
      >
        <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...cellStyles, width: '30%' }}>
                Sales Representative Name
              </TableCell>
              <TableCell sx={{ ...cellStyles, width: '25%' }}>
                AI Calls
              </TableCell>
              <TableCell sx={{ ...cellStyles, width: '25%' }}>
                Customer Calls
              </TableCell>
              <TableCell sx={{ ...cellStyles, width: '20%' }}></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {team && team.length > 0 ? (
          team.map((item, index) => (
            <ExpandableTableBody
              key={index}
              data={item}
              cellStyles={cellStyles}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </TableContainer>
    </Box>
  );
};

export default SalesRepresentativesTable;
