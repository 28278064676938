import Visibility from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CalendarIcon } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import { PhoneIcon } from 'svg/CustomSVGIcon';
export const CallInfo = ({ title, dateTime, duration, id }) => {
  const { setDocId } = usePersonaStore();
  const navigate = useNavigate();
  const handleShowScorecard = () => {
    if (id) {
      setDocId(id);
      navigate(`/airoleplaycall/calldetails`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Typography
        color="#92a7a5"
        fontSize={14}
        fontWeight={500}
        fontFamily="Lexend"
        noWrap
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <CalendarIcon sx={{ color: '#92a7a5', fontSize: '20px' }} />
          <Typography
            color="#92a7a5"
            fontSize={14}
            fontWeight={500}
            fontFamily="Lexend"
            noWrap
          >
            {dateTime}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <PhoneIcon fill={'#92a7a5'} />
          <Typography
            color="#92a7a5"
            fontSize={14}
            fontWeight={500}
            fontFamily="Lexend"
            noWrap
            sx={{ width: '70px' }}
          >
            {duration}
          </Typography>
        </Box>
        <IconButton style={{ color: '#00706C' }} onClick={handleShowScorecard}>
          <Visibility fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CallInfo;
