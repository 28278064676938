import apis from 'api/api';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';

export const useUser = ({ userType = '' }) => {
  const { user } = useLoggedUser();
  const [options, setOptions] = useState([]);

  const {
    data,
    call: fetchUsers,
    isLoading: isFetching,
  } = useApi({
    fetcher: () => {
      return apis.getUsersList(user.organization, userType);
    },
  });

  const { call: deleteCoach, isLoading: isDeleting } = useApi({
    fetcher: ({ userId, email }) => {
      const deleteparams = {
        organizationId: user.organization,
        userId,
        email,
      };
      return apis.deleteUser(deleteparams);
    },
    successMessage: `${userType.toLowerCase()} deleted successfully`,
  });

  const { call: postCoach, isLoading: isPosting } = useApi({
    fetcher: (data) => {
      const postData = {
        organizationId: user?.organization,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        role: data?.role,
        course: data?.course,
        region: data?.region,
        school: data?.school,
        assigneeIds: data?.assigneeIds,
      };
      return apis.inviteUser(postData);
    },
    successMessage: `${userType.toLowerCase()} created successfully`,
  });

  const { call: updateUser, isLoading: isUpdating } = useApi({
    fetcher: ({ userId, data }) => {
      return apis.updateUser({
        userId,
        organizationId: user.organization,
        data,
      });
    },
    successMessage: `${userType.toLowerCase()} updated successfully`,
  });

  const { call: revokeUser, isLoading: isRevoking } = useApi({
    fetcher: (data) => {
      return apis.revokeInvitation(data);
    },
    successMessage: `${userType.toLowerCase()} revoked successfully`,
  });

  const { call: resendInvitation } = useApi({
    fetcher: apis.resendInvitation,
    successMessage: 'Invitation resended successfully',
  });

  const get = async () => {
    await fetchUsers();
  };

  const post = async (data) => {
    await postCoach(data);
    get();
  };

  const update = async ({ userId, data }) => {
    await updateUser({ userId, data });
    get();
  };

  const remove = async ({ userId, email }) => {
    await deleteCoach({ userId, email });
    get();
  };

  const revoke = async ({ organizationId, invitationId }) => {
    await revokeUser({ organizationId, invitationId });
    get();
  };

  const resend = async ({ organizationId, invitationId }) => {
    resendInvitation({
      organizationId: organizationId,
      invitationId: invitationId,
    });
    get();
  };

  const isLoading =
    isDeleting || isPosting || isFetching || isUpdating || isRevoking;

  useEffect(() => {
    if (data && data.length) {
      const userOptions = data.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
      const sortedOptions = userOptions.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setOptions(sortedOptions);
    }
  }, [data]);

  return {
    data,
    options,

    isLoading,
    isDeleting,
    isPosting,
    isFetching,
    isUpdating,
    isRevoking,

    get,
    post,
    update,
    remove,
    revoke,
    resend,
  };
};
