import CheckIcon from '@mui/icons-material/Check';
import PhoneIcon from '@mui/icons-material/Phone';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';

const PersonaCard = ({ data }) => {
  const { selectPersona, selectedPersona } = usePersonaStore();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/airoleplaycall/ongoingcall/${data._id}`);
  };

  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  if (data) {
    const imageUrl =
      data.avatar && data.avatar !== 'invalid'
        ? data.avatar
        : data.gender === 'male'
        ? maleImage
        : femaleImage;

    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '25px 15px',
          borderRadius: '8px',
          border: `1px solid ${
            selectedPersona?._id === data._id ? '#00BF8C' : '#dae8e7'
          }`,
          width: 250,
          height: 270,
          boxShadow: 2,
        }}
        onClick={() => selectPersona(data._id)}
      >
        <Avatar
          src={imageUrl}
          alt={data.name}
          sx={{
            width: 100,
            height: 100,
            marginBottom: '10px',
          }}
        />

        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Lexend',
            fontWeight: 500,
            fontSize: '16px',
            color: 'black',
            marginBottom: '5px',
          }}
        >
          {data.name}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Lexend',
            fontWeight: 300,
            fontSize: '14px',
            color: 'black',
            textAlign: 'center',
            marginBottom: '15px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            height: '40px',
          }}
        >
          {data.description}
        </Typography>

        <Button
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            backgroundColor: data.completed ? '#8CA19F' : '#00706C',
            color: 'white',
            padding: '8px 16px',
            borderRadius: '5px',
            textTransform: 'none',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: data.completed ? '#8CA19F' : '#005F53',
            },
          }}
          onClick={() => {
            selectPersona(data._id);
            handleNavigation();
          }}
          disabled={data.completed}
        >
          {data.completed ? (
            <>
              <CheckIcon sx={{ marginRight: '8px' }} />
              Completed
            </>
          ) : (
            <>
              <PhoneIcon sx={{ marginRight: '8px' }} />
              Start Call
            </>
          )}
        </Button>
      </Card>
    );
  } else {
    return null;
  }
};

export default PersonaCard;
