import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { MainContent } from 'components/sidebar';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import styles from './styles/Dashboard.module.css';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Modal from '@mui/material/Modal';
import organizationAPI from 'api/api';
import ErrorOverlay from 'components/ErrorOverlay';
import LoadingOverlay from 'components/LoadingOverlay';
import { TickMarkIcon, UploadIcon } from 'svg/CustomSVGIcon';


const RemoveModal = ({
  open,
  setOpen,
  setLoading,
  getDocumentsByOrganization,
  documentId,
  setErrorVisible,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleDelete = async (e, documentId) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteDocumentAnalayzeById(
        documentId
      );
      await getDocumentsByOrganization();
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
       <h5>Remove</h5>
       <p>Are you sure you want to delete?</p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button
            onClick={(e) => {
              handleDelete(e, documentId);
              setOpen(false);
            }}
            className={styles.remove}
          >
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const DragAndDrop = ({ name, setName, onDrop }) => {
  const [files, setFiles] = useState([]);

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
      setFiles(acceptedFiles);
      setName(acceptedFiles[0].name);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
  });

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  return (
    <div className={styles.dropBox}>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          color: '#888',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <UploadIcon />
            <p>
              Click to attach your file, or drag and drop it here from your file
              browser. Please uplaod one file at  a time.
            </p>
          </>
        )}

        {files.length > 0 && (
          <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
            {files.length > 0 && (
              <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
                {files.map((file) => (
                  <li
                    key={file.name}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {name} - {(file.size / (1024 * 1024)).toFixed(2)} mb
                    </div>
                    <div>
                      <CloseOutlinedIcon onClick={() => removeFile(file)} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const DocumentDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [canvasList, setCanvasList] = useState([]);
  const [user, setUser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [documentUploadOpen, setDocumentUploadOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [startNew, setStartNew] = React.useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [owner, setOwner] = useState('');
  const [type, setType] = useState('');
  const [tags, setTags] = useState('');
  const [year, setYear] = useState('');

  const [buttonDisable, setButtonDisable] = useState(true);

  const [files, setFiles] = useState([{}]);

  const [deleteDocumentId, setDeleteDocumentId] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
  };

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const handleOpen = () => {
    setName('');
    setOpen(true);
  };

  const handleUploadDocument = async (e) => {
    e.preventDefault();
    setLoading(true);

    //Get ruleId from the backend
    const rule = await organizationAPI.getResumeRuleId();
    let ruleId = rule.data.ruleId;
    
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('ruleId', ruleId);
    formData.append('filename', name);

    try {
      await organizationAPI.documentAnalyzerUploadDocument(formData);
      await getDocumentsByUser();
      setLoading(false);
      setDocumentUploadOpen(true);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }

    setLoading(false);
  };

  const handleView = (e, documentId) => {
    e.preventDefault();
    navigate(`/student/resumeanalysis/view/${documentId}`);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (name) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [name]);

  const handleDrop = useCallback((acceptedFiles) => {
    // Do something with the dropped files, e.g. upload them to a server
    setFiles(acceptedFiles);
  }, []);

  const getDocumentsByUser = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getDocumentAnalyzeByUser();
      setCanvasList(
        response.data.reverse().map((item) => {
          if (item.status === 'coachReview') {
            return {
              ...item,
              status: 'Coach Review',
            };
          } else if (item.status === 'aiReview') {
            return {
              ...item,
              status: 'AI Review',
            };
          } else if (item.status === 'studentReview') {
            return {
              ...item,
              status: 'Student Review',
            };
          } else if (item.status === 'done') {
            return {
              ...item,
              status: 'Done',
            };
          } else if (item.status === 'pending') {
            return {
              ...item,
              status: 'Pending',
            };
          } else if (item.status === 'error') {
            return {
              ...item,
              status: 'Error',
            };
          } else {
            return item;
          }
        })
      );
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getDocumentsByUser();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}
      {errorVisible && <ErrorOverlay />}
      {errorVisible && (
        <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      )}
      <RemoveModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        setLoading={setLoading}
        getDocumentsByOrganization={getDocumentsByUser}
        documentId={deleteDocumentId}
        setErrorVisible={setErrorVisible}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_view}>
          <div className={styles.modal_content}>
            <h2>Upload New Document</h2>
            <DragAndDrop name={name} setName={setName} onDrop={handleDrop} />
            <div>
              <FormControl
                className={styles.dropdown_section}
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Document Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              {/* <FormControl
                className={styles.dropdown_section}
                sx={{
                  width: '100%',
                }}
              >
                <SelectResumeStatus
                  input={'STATUS'}
                  label={'STATUS'}
                  value={tags}
                  setValue={setTags}
                />
              </FormControl> */}
            </div>
            <button
              className={styles.start}
              disabled={buttonDisable}
              onClick={handleUploadDocument}
            >
              Confirm and Upload
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={documentUploadOpen}
        onClose={() => setDocumentUploadOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_view}>
          <div className={styles.modal_upload}>
            <TickMarkIcon />
            <h2>Document Uploaded</h2>
            <p>Your document has been successfully uploaded.</p>
            <button
              className={styles.ok_button}
              onClick={() => setDocumentUploadOpen(false)}
            >
              Ok
            </button>
          </div>
        </Box>
      </Modal>
      <MainContent active="resumeanalysis" user={user}>
        <h1>Welcome, {user && user.firstName + ' ' + user.lastName}</h1>
        <div className={styles.border}></div>
        <div className={styles.learner_header}>
          <h2>Documents</h2>
          <div className={styles.learner_header_buttons}>
            <button onClick={handleOpen} className={styles.iteration}>
              <AddIcon />
              Upload New Document
            </button>
          </div>
        </div>
        <div className={styles.coach_roaster}>
          <div className={styles.learner_table}>
            <table className="styled_table">
              <thead className="table_headers">
                <tr>
                  <th>Name</th>
                  <th>Uploader</th>
                  <th>Uploaded on</th>

                  {/* <th>Tags</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {canvasList.length > 0 &&
                  canvasList.map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>
                          {item.answers[0]?.answer?.title
                            ? item.answers[0]?.answer?.title
                            : item.filename}
                        </td>
                        <td className={styles.data}>{item.userName}</td>
                        <td className={styles.data}>
                          {convertDate(item.createdAt)}
                        </td>

                        {/* <td className={styles.tag_year}>
                          <span>TAG_EXAMPLE_1</span> <span>2024</span>
                        </td> */}
                        <td className={styles.data}>{item.status}</td>

                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <CloudDownloadOutlinedIcon />
                              <a
                                href={item.fileUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download
                              </a>
                            </div>
                            <div className={styles.resend}>
                              <VisibilityOutlinedIcon />
                              <button onClick={(e) => handleView(e, item._id)}>
                                Analysis
                              </button>
                            </div>

                            <div className={styles.resend}>
                              <DeleteOutlineOutlinedIcon />
                              <button
                                onClick={(e) => {
                                  setDeleteDocumentId(item._id);
                                  setDeleteModalOpen(true);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {canvasList.length === 0 && (
              <div className={styles.empty_table}>
                <p>No submissions have been made yet.</p>
                <p onClick={handleOpen} className={styles.link}>
                  Click here to make a new submission template.
                </p>
              </div>
            )}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default DocumentDashboard;
