import { Edit, Delete } from '@mui/icons-material';
import ActionsDropdown from 'components/Table/TableActions';

const courseActions = [
  {
    label: 'View/Edit Details',
    icon: <Edit style={{ color: '#4CAF50' }} />,
    onClick: () => console.log('View/Edit clicked'),
  },
  {
    label: 'Delete Course',
    icon: <Delete style={{ color: '#F44336' }} />,
    onClick: () => console.log('Delete clicked'),
  },
];

const courseListTableColumns = [
  { id: 'courseCode', label: 'Course Code', minWidth: 100 },
  { id: 'dateOfCreation', label: 'Date Of Creation', minWidth: 50 },
  { id: 'region', label: 'Region', minWidth: 100 },
  { id: 'actions', label: 'Actions', minWidth: 100 },
];

const courseList = [
  {
    courseCode: 'RusselJ-E1-24',
    dateOfCreation: new Date().toDateString(),
    region: 'New York',
    actions: <ActionsDropdown actions={courseActions} />,
  },
  {
    courseCode: 'RusselJ-E1-25',
    dateOfCreation: new Date().toDateString(),
    region: 'California',
    actions: <ActionsDropdown actions={courseActions} />,
  },
];

const Status = {
  active: 'Active',
  inactive: 'Inactive',
  pending: 'Pending',
  completed: 'Completed',
  cancelled: 'Cancelled',
};

const Roles = {
  admin: 'Administrator',
  instructor: 'Instructor',
  guest: 'Guest',
  observer: 'Observer',
};

const Courses = {
  math101: 'Mathematics 101',
  cs202: 'Computer Science 202',
  eng101: 'English 101',
  bio102: 'Biology 102',
  chem101: 'Chemistry 101',
  phy101: 'Physics 101',
  hist101: 'History 101',
  eco101: 'Economics 101',
  bus101: 'Business 101',
};

const Regions = {
  'us-west-2': 'US West (Oregon)',
  'us-east-1': 'US East (N. Virginia)',
  'us-west-1': 'US West (N. California)',
  'eu-west-1': 'EU (Ireland)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ca-central-1': 'Canada (Central)',
  'sa-east-1': 'South America (São Paulo)',
};

const Schools = {
  'stanford-university': 'Stanford University',
  'harvard-university': 'Harvard University',
  'princeton-university': 'Princeton University',
  'yale-university': 'Yale University',
};

const CourseInstructors = {
  'john-doe': 'John Doe',
  'jane-smith': 'Jane Smith',
};

const blankCourseInfo = {
  courseName: '',
  assignedRegion: '',
  assignedSchool: '',
  assignedCoaches: [],
  organizationId: '',
};

export {
  courseListTableColumns,
  courseList,
  Regions,
  Schools,
  CourseInstructors,
  Courses,
  Roles,
  Status,
  blankCourseInfo,
};
