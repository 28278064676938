import { ArrowOutward } from '@mui/icons-material';
import styles from '../../styles/AssessmentSummary.module.css';

export const Tips = ({ assessmentAnswer }) => {
  return (
    <div className={styles.grade_section}>
      {assessmentAnswer?.map((section, index) => (
        <div className={styles.assessment_answer_list} key={`tips-${index}`}>
          <div className={styles.assessment_tip}>
            <h2 className={styles.review_title_h2}>
              {`${section.question}`}
              <ArrowOutward />
            </h2>

            <p>{section.tipText}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
