import apis from 'api/api';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import styles from '../../Dashboard.module.css';
import { Modal } from '@mui/material';
import { CSVInviteSection } from './CSVInviteSection';
import {
  CustomButton,
  CustomTextButton,
  CustomWhiteButton,
} from 'components/Button';
import { Close } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { useCourse, useUser } from 'pages/Settings/context';
import { useApi } from 'hooks';
import LoadingOverlay from 'components/LoadingOverlay';

export const BulkCSVInviteModal = ({
  data: initialInviteeData,
  open,
  closeDialog,
  user,
}) => {
  //const user = useLoggedUser();
  const [error, setError] = useState('');
  const [inviteeData, setInviteeData] = useState(initialInviteeData);

  const {
    data: allUsers,
    get: fetchCoaches,
    isFetching: coachFetching,
  } = useUser({ userType: '' });

  const {
    data: courseList,
    get: fetchCourses,
    isFetching: courseFetching,
  } = useCourse();

  const { call: postCSVData, isLoading: dataPosting } = useApi({
    fetcher: (data) => apis.bulkInvitee(data),
    successMessage: 'Bulk Invite sent successfully',
  });

  useEffect(() => {
    if (initialInviteeData.length) setInviteeData(initialInviteeData);
  }, [initialInviteeData]);

  const [invitationSentTo, setInvitationSentTo] = useState([]);
  const [coaches, setCoaches] = useState([]);
  useEffect(() => {
    if (allUsers && allUsers.length) {
      const newInvitationSentTo = [];
      const newCoaches = [];

      allUsers.forEach((user) => {
        newInvitationSentTo.push(user.email);
        if (user.role === 'COACH') {
          newCoaches.push(user);
        }
      });

      setInvitationSentTo(newInvitationSentTo);
      setCoaches(newCoaches);
    }
  }, [allUsers]);

  useEffect(() => {
    fetchCoaches();
    fetchCourses();
  }, []);

  const close = () => {
    if (closeDialog) closeDialog();
  };

  const isLoading = coachFetching && courseFetching;

  const bulkInvite = async (e) => {
    e.preventDefault();
    const tempOld = inviteeData.map((item) => {
      if (item.role === 'ADMIN') {
        if (item.firstName && item.lastName && item.email && item.role) {
          return {
            ...item,
            isEnabled: true,
          };
        } else {
          return {
            ...item,
            isEnabled: false,
          };
        }
      }

      if (item.role === 'COACH' || item.role === 'STUDENT') {
        if (item.firstName && item.lastName && item.email && item.role) {
          return {
            ...item,
            isEnabled: true,
          };
        } else {
          return {
            ...item,
            isEnabled: false,
          };
        }
      }

      return item;
    });

    const error = tempOld.some((item) => !item.isEnabled);
    if (error) {
      setError('Please fill all the fields');
      return;
    } else {
      setError('');
    }

    const temp = tempOld.map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        role: item.role,
        course: item.course,
        region: item.region,
        assigneeIds: item.assigneeIds?.map((item) => item.id) || [],
      };
    });

    const data = {
      invitations: temp,
      organizationId: user.organization,
    };

    const hasMatchingEmail = temp.some((userInfo) =>
      invitationSentTo.includes(userInfo.email)
    );
    if (!hasMatchingEmail) {
      const response = await postCSVData(data);
      if (response.status === 200) {
        close();
        setInviteeData([]);
      } else {
        toast.error('Bulk Invite failed');
      }
    }
  };
  return (
    inviteeData &&
    !isLoading &&
    inviteeData.length > 0 && (
      <div>
        {dataPosting && <LoadingOverlay />}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          closeOnClick
          pauseOnHover
        />
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={styles.bulk_modal}>
            <div className={styles.invite_section_header}>
              <h5>
                Bulk CSV Invite Personnel<span>{error && `* ${error}`}</span>
              </h5>
            </div>

            <div className={styles.bulk_invite_scroll}>
              {inviteeData.map((item, index) => {
                return (
                  <div className={styles.bulk_invite_section} key={item.id}>
                    <CSVInviteSection
                      item={item}
                      invitedEmailList={invitationSentTo}
                      index={index}
                      id={item.id}
                      inviteeData={inviteeData}
                      setInviteeData={setInviteeData}
                      user={user}
                      coachData={coaches}
                      courseList={courseList}
                      //finalData={finalData}
                    />
                  </div>
                );
              })}
            </div>

            <div className={styles.bulk_modal_button}>
              <CustomTextButton
                onClick={() => {
                  setInviteeData([
                    ...inviteeData,
                    {
                      id: crypto.randomUUID(20).toString('hex'),
                      isEnabled: false,
                    },
                  ]);
                }}
              >
                + Add New Invitee
              </CustomTextButton>
              <div className={styles.bulk_modal_group_button}>
                <CustomButton onClick={bulkInvite}>
                  <SendIcon /> Send Invite
                </CustomButton>
                <CustomWhiteButton onClick={close}>
                  <Close /> Cancel
                </CustomWhiteButton>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};
