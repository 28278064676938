import organizationAPI from 'api/api';
import { useEffect, useState } from 'react';
import useStore from 'store/useStore';
export const useGetMe = ({ userProps }) => {
  const [loading, setLoading] = useState(userProps);
  const [user, setUser] = useState(null);
  const { setUserId, setOrganizationId } = useStore();
  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUserId(response.data.user._id);
      setOrganizationId(response.data.organization_id);
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!userProps) {
      getMe();
    }
  }, [userProps]);

  return { user, loading, setLoading };
};
