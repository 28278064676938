import { create } from 'zustand';

const useStore = create((set) => ({
  userId: localStorage.getItem('userId') || null,
  organizationId: localStorage.getItem('organizationId') || null,
  userData: localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData'))
    : null,

  dataUpdated: false,
  pathName: window.location.pathname || '/',

  setUserId: (userId) => {
    localStorage.setItem('userId', userId);
    set({ userId });
  },
  getUserId: () => set((state) => state.userId),
  clearUserId: () => {
    localStorage.removeItem('userId');
    set({ userId: null });
  },

  setOrganizationId: (organizationId) => {
    localStorage.setItem('organizationId', organizationId);
    set({ organizationId });
  },
  getOrganizationId: () => set((state) => state.organizationId),
  clearOrganizationId: () => {
    localStorage.removeItem('organizationId');
    set({ organizationId: null });
  },

  setUserData: (userData) => {
    localStorage.setItem('userData', JSON.stringify(userData));
    set({ userData });
  },
  getUserData: () => set((state) => state.userData),
  clearUserData: () => {
    localStorage.removeItem('userData');
    set({ userData: null });
  },

  setDataUpdated: () => set({ dataUpdated: true }),
  clearDataUpdated: () => set({ dataUpdated: false }),

  setPathName: (pathName) => set({ pathName }),
  clearPathName: () => set({ pathName: '/' }),
}));

export default useStore;
