import * as Yup from 'yup';

export const schema = ({ type }) =>
  Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    region: Yup.string().required('Region name is required'),
    school: Yup.string().required('School name is required'),
    course: Yup.string().required('Course name is required'),
    ...(type === 'EDIT'
      ? {
          assigneeIds: Yup.array()
            .of(Yup.string())
            .min(1, 'At least one assignee is required')
            .required('Assignees are required'),
        }
      : {}),
  });
