import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { EmptyState, SummaryInfo } from 'components/CustomMUI';
const PerformanceSummary = ({ data }) => {
  if (data) {
    return (
      <Box
        sx={{
          width: '95%',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography variant="h6" sx={{ color: '#000' }}>
          Performance Summary
        </Typography>
        <Divider sx={{ borderColor: '#DAE8E7', marginY: 1 }} />
        {data && <SummaryInfo data={data.strengths} />}
        {data && <SummaryInfo data={data.weaknesses} />}
      </Box>
    );
  } else {
    return <EmptyState />;
  }
};

export default PerformanceSummary;
