import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { ImageUpload, TagInput } from 'components/CustomMUI';
import { useCallback, useEffect, useState } from 'react';
import useStore from 'store/useStore';
import { useApi } from './../../../../hooks/useApi';

const EditTeam = ({ data }) => {
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    avatar: '',
    assignedReps: [],
    assignedPersonas: [],
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
    assignedReps: '',
    assignedPersonas: '',
  });

  const { organizationId, setDataUpdated } = useStore();
  const [acceptedUsers, setAcceptedUsers] = useState([]);
  const [personas, setPersonas] = useState([]);

  const { call: getAllPersonasCall } = useApi({
    fetcher: organizationAPI.getAllPersonas,
    successMessage: 'Successfully fetched all personas',
  });

  const { call: getAcceptedUserCall } = useApi({
    fetcher: organizationAPI.getAcceptedUser,
    successMessage: 'Successfully fetched all users',
  });

  const { call: updateConvoTeamCall } = useApi({
    fetcher: organizationAPI.updateConvoTeam,
    successMessage: 'Successfully updated team',
  });

  const memoizedAllPersonasCall = useCallback(getAllPersonasCall, [
    getAllPersonasCall,
  ]);

  const memoizedAcceptedUserCall = useCallback(getAcceptedUserCall, [
    getAcceptedUserCall,
  ]);

  useEffect(() => {
    const fetchAllPersonaData = async () => {
      try {
        const response = await memoizedAllPersonasCall();
        if (response && response.data) {
          setPersonas(response.data);
        }
      } catch (error) {
        console.error('Error fetching personas', error);
      }
    };

    const fetchAcceptedUsersData = async () => {
      try {
        const response = await memoizedAcceptedUserCall(organizationId);
        if (response && response.data) {
          setAcceptedUsers(response.data);
        }
      } catch (error) {
        console.error('Error fetching users', error);
      }
    };

    const setInitialFormData = () => {
      setFormData({
        name: data.name || '',
        description: data.description || '',
        avatar: data.avatar || '',
        assignedReps: data.assignedReps || [],
        assignedPersonas: data.assignedPersonas || [],
      });
    };

    if (organizationId) {
      fetchAllPersonaData();
      fetchAcceptedUsersData();
    }

    if (data) {
      setInitialFormData();
    }
  }, [organizationId, data]);

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  const handleFormChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const validateForm = () => {
    const { name, description } = formData;
    const newErrors = {
      name: '',
      description: '',
    };

    if (!name) newErrors.name = 'Team name is required';
    if (!description) newErrors.description = 'Description is required';

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const dataToSubmit = {};

    if (formData.name !== data.name) dataToSubmit.name = formData.name;
    if (formData.description !== data.description)
      dataToSubmit.description = formData.description;
    if (formData.avatar !== data.avatar) dataToSubmit.avatar = formData.avatar;

    const formattedAssignedReps = formData.assignedReps?.map((rep) => ({
      id: rep._id,
      name: rep.name,
    }));
    const formattedAssignedPersonas = formData.assignedPersonas?.map(
      (persona) => ({
        id: persona._id,
        name: persona.name,
      })
    );

    if (
      JSON.stringify(formattedAssignedReps) !==
      JSON.stringify(data.assignedReps)
    )
      dataToSubmit.assignedReps = formattedAssignedReps;

    if (
      JSON.stringify(formattedAssignedPersonas) !==
      JSON.stringify(data.assignedPersonas)
    )
      dataToSubmit.assignedPersonas = formattedAssignedPersonas;

    try {
      const response = await updateConvoTeamCall({
        data: dataToSubmit,
        teamId: data._id,
      });
      setDataUpdated();
      setOpen(false);
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <>
      <IconButton sx={{ color: '#5B706F' }} onClick={toggleDrawer(true)}>
        <CreateIcon fontSize="small" sx={{ fontSize: '18px' }} />
        <Typography
          variant="h6"
          sx={{
            color: '#5B706F',
            fontFamily: 'Lexend',
            fontSize: '16px',
            fontWeight: 500,
            textWrap: 'nowrap',
            marginLeft: '5px',
          }}
        >
          EDIT/ASSIGN
        </Typography>
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ '& .MuiDrawer-paper': { width: '50%' } }}
      >
        <Box sx={{ width: '95%', padding: 2 }}>
          <AppBar
            position="static"
            sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1, color: 'black' }}>
                Edit Team
              </Typography>
              <Button
                onClick={toggleDrawer(false)}
                sx={{
                  color: '#5B706F',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #5B706F',
                  borderRadius: '5px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#D6DAD9FF',
                  },
                }}
              >
                <CloseIcon />
                <Typography sx={{ marginLeft: 1 }}>Discard</Typography>
              </Button>
              <Button
                onClick={handleSubmit}
                sx={{
                  color: 'green',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 2,
                  border: '1px solid #00706C',
                  borderRadius: '5px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#D6FFF5FF',
                  },
                }}
              >
                <CheckIcon />
                <Typography sx={{ marginLeft: 1 }}>Confirm</Typography>
              </Button>
            </Toolbar>
          </AppBar>
          <Divider />
          <Box
            sx={{
              marginY: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}
          >
            <ImageUpload
              onImageChange={(base64Image) => {
                if (base64Image) {
                  handleFormChange('avatar', base64Image);
                }
              }}
              previewImage={formData ? formData.avatar : ''}
            />
            <TextField
              label="TEAM NAME"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={(e) => handleFormChange('name', e.target.value)}
              sx={{ marginTop: 2 }}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              label="DESCRIPTION"
              variant="outlined"
              fullWidth
              value={formData.description}
              onChange={(e) => handleFormChange('description', e.target.value)}
              sx={{ marginTop: 2 }}
              multiline
              rows={4}
              maxRows={6}
              error={!!errors.description}
              helperText={errors.description}
            />
            <TagInput
              label={'SALES REPRESENTATIVES'}
              onChange={(value) => handleFormChange('assignedReps', value)}
              value={formData.assignedReps}
              isInput={true}
              data={acceptedUsers.map((user) => ({
                _id: user._id,
                name: `${user.firstName} ${user.lastName}`,
              }))}
              error={errors.assignedReps}
            />
            <TagInput
              label={'ASSIGNED PERSONAS'}
              onChange={(value) => handleFormChange('assignedPersonas', value)}
              value={formData.assignedPersonas}
              isInput={true}
              data={personas.map((persona) => ({
                _id: persona._id,
                name: persona.name,
              }))}
              error={errors.assignedPersonas}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default EditTeam;
