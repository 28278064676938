import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
const RadioButton = ({ label, checked, onChange }) => {
  return (
    <FormControl
      component="fieldset"
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <FormControlLabel
        control={
          <Radio
            checked={checked}
            onChange={onChange}
            sx={{
              color: 'green',
              '&.Mui-checked': {
                color: 'green',
              },
            }}
          />
        }
        label={label}
        sx={{ marginLeft: '10px', textWrap: 'nowrap' }}
      />
    </FormControl>
  );
};

export default RadioButton;
