import { yupResolver } from '@hookform/resolvers/yup';
import { Send } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { addAdmin } from 'pages/Settings/Tabs/Admin/validation/schema';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createSortedSelectOptions } from 'utils';

const blankSRInfo = {
  firstName: '',
  lastName: '',
  email: '',
  role: 'SALES_REP',
  teamsAssigned: '',
};
export const SalesRepAdd = () => {
  const [userInfo, setUserInfo] = useState(blankSRInfo);
  const [teamOptions, setTeamOptions] = useState([]);
  const { user } = useLoggedUser();

  const { call: createSR, isLoading: SRCreating } = useApi({
    fetcher: apis.inviteUser,
    successMessage: 'Sales representative added successfully',
  });

  const {
    call: getTeams,
    data: teams,
    isLoading: teamsLoading,
  } = useApi({
    fetcher: apis.getConvoTeam,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addAdmin),
    mode: 'onChange',
    defaultValues: { role: 'SALES_REP' },
  });

  const postSR = (data) => {
    createSR({ ...data, organizationId: user.organization });
    reset();
  };

  useEffect(() => {
    getTeams(user.organization);
  }, []);

  useEffect(() => {
    if (teams && teams.length) {
      const options = createSortedSelectOptions(teams, 'name', 'name');
      setTeamOptions(options);
    }
  }, [teams]);

  const renderFormFields = () => {
    const fields = [
      [
        { name: 'firstName', label: 'First Name', type: 'text' },
        { name: 'lastName', label: 'Last Name', type: 'text' },
        { name: 'email', label: 'Email', type: 'text' },
      ],
      [
        {
          name: 'role',
          label: 'Role',
          type: 'select',
          options: [
            {
              label: 'Sales Representative',
              value: 'SALES_REP',
              selected: true,
            },
          ],
        },
        {
          name: 'teamIds',
          label: 'Assigned Team(s)',
          type: 'multiSelect',
          options: teamOptions,
        },
      ],
    ];

    const commonProps = {
      formType: 'ADD',
      register,
      errors,
      watch,
      state: userInfo,
      stateUpdater: setUserInfo,
      hookFormUpdater: setValue,
      hookFormTrigger: trigger,
      loading: teamsLoading,
    };

    const renderStack = (fieldGroup) =>
      fieldGroup.map((field) => (
        <FormField
          disabled={field.name === 'role'}
          key={field.name}
          field={field}
          {...commonProps}
        />
      ));

    return {
      firstStack: renderStack(fields[0]),
      secondStack: renderStack(fields[1]),
    };
  };

  return (
    <ContentBox component="form" onSubmit={handleSubmit(postSR)}>
      {SRCreating ? <LoadingOverlay /> : null}
      <Stack direction="column" spacing={4}>
        <Typography variant="h6">Invite New Sales Representative</Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields().firstStack}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields().secondStack}
          <Button
            variant="contained"
            color="success"
            startIcon={<Send />}
            type="submit"
          >
            Send Invite
          </Button>
        </Stack>
      </Stack>
    </ContentBox>
  );
};
