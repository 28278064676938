import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const ExpandCollapseText = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ width: '97%', padding: '10px' }}>
      <Typography
        sx={{
          color: '#5b706f',
          fontFamily: 'Lexend',
          fontSize: '16px',
          fontWeight: 300,
          textAlign: 'justify',
        }}
      >
        {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      </Typography>
      <Button
        onClick={toggleExpanded}
        startIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={{
          color: '#243030',
          fontFamily: 'Lexend',
          fontSize: '12px',
          fontWeight: 300,
          padding: '0',
          textTransform: 'none', 
          display: 'flex',
          alignItems: 'center',
          my: '10px',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {isExpanded ? 'Collapse' : 'Expand'}
      </Button>
    </Box>
  );
};

export default ExpandCollapseText;
