import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingOverlay from 'components/LoadingOverlay';
import { useCourse, useCourseContext, useDialog } from 'pages/Settings/context';
import { useForm } from 'react-hook-form';
import { EditDialogWrapper, FormField } from '../../common';
import { blankCourseInfo } from '../const/data';
import { courseAdd } from '../validations/addSchema';

const EditDetailsDialog = ({ onClose }) => {
  const { closeDialog, dialogData } = useDialog();
  const { update, isUpdating } = useCourseContext();
  const {
    regions,
    schools,
    get: getCourses,

    isFetching: courseIsFetching,
  } = useCourse();

  const [courseInfo, setCourseInfo] = useState(blankCourseInfo);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(courseAdd),
    mode: 'onChange',
  });

  const handleClose = () => {
    if (onClose) onClose();
    reset();
    closeDialog();
  };

  const updatePost = async (data) => {
    const { assignedCoaches, ...withoutCoaches } = data;
    const postData = {
      ...withoutCoaches,
    };

    await update(postData);
    handleClose();
  };

  useEffect(() => {
    const initialCourseInfo = {
      courseId: dialogData?._id || '',
      courseName: dialogData?.courseName || '',
      assignedRegion: dialogData?.assignedRegion || '',
      assignedSchool: dialogData?.assignedSchool || '',
      assignedCoaches: dialogData?.assignedCoaches || '',
    };
    reset(initialCourseInfo);
    setCourseInfo(initialCourseInfo);
  }, [dialogData, reset]);

  useEffect(() => {
    getCourses();
  }, []);

  const renderFormFields = () => {
    const fields = [
      {
        name: 'courseName',
        label: 'Course Code',
        type: 'text',
      },
      {
        name: 'assignedRegion',
        label: 'Assigned Region',
        type: 'select',
        options: regions,
      },
      {
        name: 'assignedSchool',
        label: 'School',
        type: 'select',
        options: schools,
      },
      {
        name: 'assignedCoaches',
        label: 'Assigned Instructors',
        type: 'multiSelect',
      },
    ];

    return fields.map((item) => (
      <FormField
        disabled={item.name === 'assignedCoaches'}
        key={item.name}
        field={item}
        register={register}
        errors={errors}
        watch={watch}
        state={courseInfo}
        stateUpdater={setCourseInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
        loading={courseIsFetching}
      />
    ));
  };
  return (
    <>
      {isUpdating && <LoadingOverlay />}
      <EditDialogWrapper
        onSubmit={handleSubmit(updatePost)}
        dialogType={'edit'}
        title={'Update Course Info'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {renderFormFields()}
        </Box>
      </EditDialogWrapper>
    </>
  );
};

export default EditDetailsDialog;
