import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const SearchBar = ({
  searchText,
  handleSearch,
  placeholder = 'Search ... ',
}) => {
  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      fullWidth
      InputProps={{
        style: {
          height: '43px',
          padding: '0 10px',
        },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={searchText}
      onChange={handleSearch}
      sx={{
        '& .MuiOutlinedInput-root': {
          height: '43px',
          '& input': {
            height: '43px',
            padding: '10px',
          },
        },
      }}
    />
  );
};

export default SearchBar;
