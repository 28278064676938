import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const cellStyles = {
  borderBottom: '1px solid #DAE8E7',
  color: '#243030',
  fontWeight: 500,
  fontFamily: 'Lexend',
  fontSize: '14px',
  textTransform: 'capitalize',
  padding: '16px',
};

const CallHistoryTable = () => {
  return (
    <Box sx={{ width: '98%', padding: '0 10px' }}>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 'none', border: 'none', width: '100%' }}
      >
        <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...cellStyles, width: '20%' }}>
                Persona/Recipient
              </TableCell>
              <TableCell sx={{ ...cellStyles, width: '20%' }}>
                Recipient Type
              </TableCell>
              <TableCell sx={{ ...cellStyles, width: '25%' }}>Date</TableCell>
              <TableCell sx={{ ...cellStyles, width: '20%' }}>
                Duration
              </TableCell>
              <TableCell sx={{ ...cellStyles, width: '15%' }}></TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CallHistoryTable;
