import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
function RaderChartLegend({ color, title }) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: color,
          marginRight: 1,
        }}
      />
      <Typography variant="body2">{title}</Typography>
    </Box>
  );
}

export default RaderChartLegend;
