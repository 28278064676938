import { Stack } from '@mui/material';
import { TeamAdd } from './TeamAdd';
import { TeamList } from './TeamList';

export const Teams = () => {
  return (
    <Stack spacing={3}>
      <TeamAdd />
      <TeamList />
    </Stack>
  );
};
