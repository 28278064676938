import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PeopleIcon from '@mui/icons-material/People';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { ImageUpload, TagInput } from 'components/CustomMUI';
import { useCallback, useEffect, useState } from 'react';
import useStore from 'store/useStore';
import { useApi } from './../../../../hooks/useApi';

const CreateNewTeam = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    avatar: '',
    assignedReps: [],
    assignedPersonas: [],
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
    assignedReps: '',
    assignedPersonas: '',
  });
  const [acceptedUsers, setAcceptedUsers] = useState([]);
  const [personas, setPersonas] = useState([]);

  const { organizationId, setDataUpdated } = useStore();

  const { call: getAllPersonasCall } = useApi({
    fetcher: organizationAPI.getAllPersonas,
    successMessage: 'Successfully fetched all personas',
  });

  const { call: getAcceptedUserCall } = useApi({
    fetcher: organizationAPI.getAcceptedUser,
    successMessage: 'Successfully fetched all users',
  });

  const { call: postConvoTeamCall } = useApi({
    fetcher: organizationAPI.postConvoTeam,
    successMessage: 'Successfully created team',
  });

  const memoizedAllPersonasCall = useCallback(getAllPersonasCall, [
    getAllPersonasCall,
  ]);

  const memoizedAcceptedUserCall = useCallback(getAcceptedUserCall, [
    getAcceptedUserCall,
  ]);

  useEffect(() => {
    const fetchAllPersonaData = async () => {
      try {
        const response = await memoizedAllPersonasCall();
        if (response && response.data) {
          setPersonas(response.data);
        }
      } catch (error) {
        console.error('Error fetching personas', error);
      }
    };

    const fetchAcceptedUsersData = async () => {
      try {
        const response = await memoizedAcceptedUserCall(organizationId);
        if (response && response.data) {
          setAcceptedUsers(response.data);
        }
      } catch (error) {
        console.error('Error fetching users', error);
      }
    };

    if (organizationId) {
      fetchAllPersonaData();
      fetchAcceptedUsersData();
    }
  }, [organizationId]);

  const handleFormChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const validateForm = () => {
    const { name, description } = formData;
    const newErrors = {
      name: '',
      description: '',
    };

    if (!name) newErrors.name = 'Team name is required';
    if (!description) newErrors.description = 'Description is required';

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formattedAssignedReps =
      formData.assignedReps && formData.assignedReps.length > 0
        ? formData.assignedReps.map((rep) => ({
            id: rep._id,
            name: rep.name,
          }))
        : undefined;

    const formattedAssignedPersonas =
      formData.assignedPersonas && formData.assignedPersonas.length > 0
        ? formData.assignedPersonas.map((persona) => ({
            id: persona._id,
            name: persona.name,
          }))
        : undefined;

    const data = {
      ...(formData.name && { name: formData.name }),
      ...(formData.description && { description: formData.description }),
      ...(formattedAssignedReps && { assignedReps: formattedAssignedReps }),
      ...(formattedAssignedPersonas && {
        assignedPersonas: formattedAssignedPersonas,
      }),
      ...(formData.avatar && { avatar: formData.avatar }),
      organizationId,
    };

    try {
      const response = await postConvoTeamCall(data);
      setDataUpdated();
      setOpen(false);
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={toggleDrawer(true)}
        sx={{
          border: `1px solid #00706C`,
          padding: '0 15px',
          borderRadius: '5px',
          width: 'fit-content',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          background: 'none',
          outline: 'none',
          height: '43px',
          color: `#00706C`,
          fontFamily: 'Lexend',
          fontSize: '16px',
          fontWeight: 500,
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: `#0A5754FF`,
            color: '#fff',
          },
        }}
      >
        <PeopleIcon />
        <Typography>Create New Team</Typography>
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ '& .MuiDrawer-paper': { width: '50%' } }}
      >
        <Box sx={{ width: '95%', padding: 2 }}>
          <AppBar
            position="static"
            sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1, color: 'black' }}>
                Create Team
              </Typography>
              <Button
                onClick={toggleDrawer(false)}
                sx={{
                  color: '#5B706F',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #5B706F',
                  borderRadius: '5px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#D6DAD9FF',
                  },
                }}
              >
                <CloseIcon />
                <Typography sx={{ marginLeft: 1 }}>Discard</Typography>
              </Button>
              <Button
                onClick={handleSubmit}
                sx={{
                  color: 'green',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 2,
                  border: '1px solid #00706C',
                  borderRadius: '5px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#D6FFF5FF',
                  },
                }}
              >
                <CheckIcon />
                <Typography sx={{ marginLeft: 1 }}>Confirm</Typography>
              </Button>
            </Toolbar>
          </AppBar>
          <Divider />
          <Box
            sx={{
              marginY: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
            }}
          >
            <ImageUpload
              onImageChange={(base64Image) =>
                handleFormChange('avatar', base64Image)
              }
            />
            <TextField
              label="TEAM NAME"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={(e) => handleFormChange('name', e.target.value)}
              sx={{ marginTop: 2 }}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              label="DESCRIPTION"
              variant="outlined"
              fullWidth
              value={formData.description}
              onChange={(e) => handleFormChange('description', e.target.value)}
              sx={{ marginTop: 2 }}
              multiline
              rows={4}
              maxRows={6}
              error={!!errors.description}
              helperText={errors.description}
            />
            <TagInput
              label={'SALES REPRESENTATIVES'}
              onChange={(value) => handleFormChange('assignedReps', value)}
              value={formData.assignedReps}
              isInput={true}
              data={acceptedUsers.map((user) => ({
                _id: user._id,
                name: `${user.firstName} ${user.lastName}`,
              }))}
              error={errors.assignedReps}
            />
            <TagInput
              label={'ASSIGNED PERSONAS'}
              onChange={(value) => handleFormChange('assignedPersonas', value)}
              value={formData.assignedPersonas}
              isInput={true}
              data={personas.map((persona) => ({
                _id: persona._id,
                name: persona.name,
              }))}
              error={errors.assignedPersonas}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CreateNewTeam;
