import { EmptyState, TeamCallHistoryCard } from 'components/CustomMUI';
import styles from './styles/Style.module.css';

const BuyerPersonas = ({ data }) => {
  if (data && data.length > 0) {
    return (
      <div className={styles.buyer_persona}>
        <TeamCallHistoryCard title={'Team Alpha Omega'} data={data} />
        {/* <TeamCallHistoryCard title={'Team Phi Omicron'} data={CallHistoryData} /> */}
      </div>
    );
  } else {
    <EmptyState />;
  }
};

export default BuyerPersonas;
