import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronRight, RemoveCircleOutline } from '@mui/icons-material';
import {
  Button,
  Collapse,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import { Calendar } from 'components/Datepicker';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useStudentDashboardContext } from './context/dashboardContext';

const incomeOptions = [
  { value: 'under_25000', label: 'Under $25,000' },
  { value: '25000_49999', label: '$25,000 - $49,999' },
  { value: '50000_74999', label: '$50,000 - $74,999' },
  { value: '75000_99999', label: '$75,000 - $99,999' },
  { value: '100000_149999', label: '$100,000 - $149,999' },
  { value: '150000_199999', label: '$150,000 - $199,999' },
  { value: '200000_249999', label: '$200,000 - $249,999' },
  { value: '250000_299999', label: '$250,000 - $299,999' },
  { value: '300000_399999', label: '$300,000 - $399,999' },
  { value: '400000_499999', label: '$400,000 - $499,999' },
  { value: '500000_or_more', label: '$500,000 or more' },
  { value: 'prefer_not_to_say', label: 'Prefer not to say' },
];

const ethnicityOptions = [
  {
    value: 'american_indian_alaska_native',
    label: 'American Indian or Alaska Native',
  },
  { value: 'asian', label: 'Asian' },
  { value: 'black_african_american', label: 'Black or African American' },
  { value: 'hispanic_latino', label: 'Hispanic or Latino' },
  {
    value: 'native_hawaiian_pacific_islander',
    label: 'Native Hawaiian or Other Pacific Islander',
  },
  { value: 'white', label: 'White' },
  {
    value: 'middle_eastern_north_african',
    label: 'Middle Eastern or North African',
  },
  { value: 'multiracial', label: 'Multiracial' },
  { value: 'other', label: 'Other' },
  { value: 'prefer_not_to_say', label: 'Prefer not to say' },
];

export const DemographicInfo = () => {
  const [show, setShow] = useState(undefined);
  const theme = useTheme();
  const { setData, loggedUserInfo } = useStudentDashboardContext();

  const schema = Yup.object({
    dob: Yup.string(),
    gender: Yup.string(),
    raceEthnicity: Yup.string(),
    householdIncome: Yup.string(),
    zipCode: Yup.string()
      .optional()
      .matches(/^\d{5}$/, {
        message: 'Zip Code must be a 5-digit number',
        excludeEmptyString: true,
      }),
    isSkipped: Yup.boolean(),
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const formValues = watch();
  const isAnyFieldSet = Object.values(formValues).some((value) => value);

  const { call: postDemographicInfo } = useApi({
    fetcher: (data) => apis.updateDemographics({ data }),
    successMessage: 'Demographic information updated successfully',
  });

  useEffect(() => {
    if (loggedUserInfo?.user) {
      const demographics = loggedUserInfo?.user?.demographics;

      const isSkipped = demographics?.isSkipped === true;
      const { isSkipped: _, ...demographicsData } = demographics || {};

      const hasDemographicData = Object.values(demographicsData).some(
        (value) => value
      );

      if (isSkipped || hasDemographicData) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  }, [loggedUserInfo]);

  const postData = async (data) => {
    const withoutEmptyItems = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '')
    );
    await postDemographicInfo(withoutEmptyItems);

    reset();
    setShow(false);
  };

  const handleSkip = () => {
    setValue('isSkipped', true);
    handleSubmit(postData)();
  };

  useEffect(() => {
    setData((prev) => ({ ...prev, hasDemographicData: !show }));
  }, [show]);

  return (
    <Collapse in={show === true}>
      <ContentBox
        mt={3}
        spacing={3}
        component="form"
        onSubmit={handleSubmit(postData)}
        sx={{
          p: 3,
          border: 1,
          borderRadius: 1,
          borderColor: theme.palette.success.main,
        }}
      >
        <Stack justifyContent="space-between" direction="row">
          <Typography component="p" variant="h6">
            Demographic Information
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              type="button"
              variant="outlined"
              startIcon={<RemoveCircleOutline />}
              onClick={handleSkip}
            >
              Skip
            </Button>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ChevronRight />}
              disabled={!isAnyFieldSet}
            >
              Save and Continue
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <FormControl size="small" sx={{ flex: 1 }}>
              <InputLabel id="demo-simple-select-label">GENDER</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={watch('gender') || ''}
                label="GENDER"
                {...register('gender')}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
                <MenuItem value="non_binary">Prefer not to say</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ flex: 1 }}>
              <InputLabel id="demo-simple-select-label">ETHNICITY</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                {...register('raceEthnicity')}
                value={watch('raceEthnicity') || ''}
                label="ETHNICITY"
              >
                {ethnicityOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Calendar
                {...register('dob')}
                label="DATE OF BIRTH"
                initialDate={null}
                locale="enUS"
                onDateChange={(date) =>
                  setValue('dob', new Date(date).toISOString())
                }
              />
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl size="small" sx={{ flex: 1 }}>
              <InputLabel id="demo-simple-select-label">
                HOUSEHOLD INCOME
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="HOUSEHOLD INCOME"
                value={watch('householdIncome') || ''}
                {...register('householdIncome')}
              >
                {incomeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ flex: 1 }}>
              <TextField
                {...(register('zipCode') || '')}
                label="Zip Code"
                size="small"
                error={!!errors['zipCode']}
                helperText={errors['zipCode'] ? errors['zipCode'].message : ''}
                inputProps={{
                  maxLength: 5,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </FormControl>
          </Stack>
        </Stack>
      </ContentBox>
    </Collapse>
  );
};
