import Papa from 'papaparse';

export const parseBulkInviteFile = (event, onComplete) => {
  const file = event.target.files[0];
  if (!file) return;
  Papa.parse(event.target.files[0], {
    header: true,
    skipEmptyLines: true,

    complete: function (results) {
      const data = results.data.map((item) => {
        return {
          id: crypto.randomUUID(20), //.toString('hex'),
          isEnabled: false,
          firstName: item['FirstName'],
          lastName: item['LastName'],
          email: item['Email'],
          role: item['Role'],
          course: item['Course'],
          region: item['Region'],
        };
      });
      onComplete(data);
      event.target.value = null;
    },
  });
};
