import {
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Chip,
  Box,
} from '@mui/material';

import _without from 'lodash/without';

import CancelIcon from '@mui/icons-material/Cancel';
import { inputStyle } from './TextField';

const inputLableStyle = {
  fontFamily: 'Lexend',
  fontSize: '14px',
  fontWeight: '300',
  color: '#8CA19F',
  '&.MuiInputLabel-shrink': {
    color: '#243030',
  },
};

const selectStyle = {
  fontFamily: 'Lexend',
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DAE8E7',
    },
    '&:hover fieldset': {
      borderColor: '#4C12A1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4C12A1',
    },
  },
};

const menuStyle = {
  fontFamily: 'Lexend',
  fontSize: '14px',
  fontWeight: '300',
  color: '#243030',
};

const listTextStyle = {
  fontFamily: 'Lexend',
};

const checkboxStyle = {
  color: '#4C12A1',
  '&.Mui-checked': {
    color: '#4C12A1',
  },
};

const chipStyle = {
  fontFamily: 'Lexend',
  fontWeight: '300',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const course = [
  'All',
  'BrownN-E1-24',
  'CushnieT-E1-24',
  'GraciaC-E1-24',
  'KummerlenK-E1-24',
  'McNairD-E1-24',
  'PadillaF-E1-24',
  'ParrisR-E1-24',
  'RussellJ-E1-24',
  'SilvermanJ-E1-24',
  'TapiaM-E1-24',
  'TapiaM-E5-24',
  'VazquezK-E1-24',
  'VincutonisS-E1-24',
];

const region = [
  'All',
  'Capital',
  'Mid-Atlantic',
  'Midwest',
  'New England',
  'South',
  'Southeast',
  'Global',
]
  .slice()
  .sort((a, b) => a.localeCompare(b));

const country = [
  'United States',
  'Canada',
  'Mexico',
  'Brazil',
  'Argentina',
  'United Kingdom',
];

const timeAllowed = [
  'All',
  '1 Hour',
  '2 Hours',
  '3 Hours',
  '4 Hours',
  '5 Hours',
];

const assessmentStatus = [
  'Progress',
  'Review',
  'Done',
  'Archived',
  'Published',
  'Closed',
];

const questionOptions = ['Basic 4-option MCQ'];

const college = ['Adelphi University', 'Auburn University'];
const state = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California'];

const filter = ['All', 'Starred', 'Closed', 'Open', 'NFTE AI', 'Coach'];

const dateRange = [
  { value: 'lastWeek', label: 'Last Week' },
  { value: 'lastTwoWeeks', label: 'Last Two Weeks' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'lastThreeMonths', label: 'Last Three Months' },
  { value: 'lastSixMonths', label: 'Last Six Months' },
  { value: 'thisYear', label: 'This Year' },
  { value: 'lastYear', label: 'Last Year' },
];

const docName = ['RussellJ-E1-24', 'BrownN-E1-24', 'GraciaC-E1-24'];
const owner = ['RussellJ', 'BrownN', 'GraciaC'];
const type = ['png', 'jpeg', 'pdf'];
const tags = ['tag1', 'tag2', 'tag3'];
const resumeStatus = ['In Review', 'In Progress', 'Error', 'Complete'];
const gradeStatus = ['Graded', 'Ungraded'];
const documentType = ['All', 'Active', 'Inactive'];
const documentAnalysisStatus = [
  'All',
  'Error',
  'Pending',
  'Coach Review',
  'Student Review',
  'AI Review',
  'Done',
];

const documentAnalysisBulkAction = ['Student Review', 'Done'];

const DocumentType = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {documentType.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectUserRole = ({ input, label, value, setValue, role, data }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {data.map((item, index) => {
          if (role === 'Admin' && item === 'Admin') {
            return (
              <MenuItem key={index} value={item}>
                {item.toUpperCase()}
              </MenuItem>
            );
          }

          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item.toUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectCourse = ({ courseList, input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {courseList && courseList.length
          ? courseList.map((item, index) => {
              return (
                <MenuItem key={index} value={item} sx={menuStyle}>
                  {item}
                </MenuItem>
              );
            })
          : course.map((item, index) => {
              return (
                <MenuItem key={index} value={item} sx={menuStyle}>
                  {item}
                </MenuItem>
              );
            })}
      </Select>
    </>
  );
};

const SelectRegion = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {region
          .slice(0)
          .sort()
          .map((item, index) => {
            return (
              <MenuItem key={index} value={item} sx={menuStyle}>
                {item}
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

const SelectCountry = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {country.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectState = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {state.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectCollege = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {college.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectGradeStatus = ({ input, label, value, onChange, disabled }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={onChange}
        disabled={disabled}
      >
        {gradeStatus.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectDocName = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {docName.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectOwner = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {owner.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectType = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {type.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectTags = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {tags.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectResumeStatus = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {resumeStatus.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectDateRange = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {dateRange.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value} sx={menuStyle}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const MultipleSelectCoach = ({
  label,
  input,
  coachData,
  selectedAsigneeIds,
  setSelectedAsigneeIds,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setSelectedAsigneeIds(duplicateRemoved);
  };

  return (
    <>
      <InputLabel>{input}</InputLabel>
      <Select
        multiple
        value={selectedAsigneeIds}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected.map((x) => `${x.firstName} ${x.lastName}`).join(', ')
        }
        MenuProps={MenuProps}
        sx={selectStyle}
      >
        {coachData &&
          coachData
            .slice(0)
            .sort(function (a, b) {
              let firstNameComparison = a.firstName
                .toLowerCase()
                .localeCompare(b.firstName.toLowerCase());

              if (firstNameComparison === 0) {
                return a.lastName
                  .toLowerCase()
                  .localeCompare(b.lastName.toLowerCase());
              }

              return firstNameComparison;
            })
            .map((coach) => (
              <MenuItem key={coach.id} value={coach} sx={menuStyle}>
                <Checkbox
                  sx={checkboxStyle}
                  checked={
                    selectedAsigneeIds.findIndex(
                      (item) => item.id === coach.id
                    ) >= 0
                  }
                />
                <ListItemText
                  primaryTypographyProps={listTextStyle}
                  primary={`${coach.firstName} ${coach.lastName}`}
                />
              </MenuItem>
            ))}
      </Select>
    </>
  );
};

const MultipleSelectCourse = ({
  courseList,
  value,
  setValue,
  input,
  label,
}) => {
  const handleChange = (e) => {
    const tempSelectedCourse = courseList.filter((course) =>
      e.target.value.includes(course)
    );

    setValue(tempSelectedCourse);
  };

  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.map((x) => `${x}`).join(', ')}
        MenuProps={MenuProps}
        sx={selectStyle}
      >
        {courseList
          ? courseList.map((course) => (
              <MenuItem key={course} value={course} sx={menuStyle}>
                <Checkbox
                  sx={checkboxStyle}
                  checked={value.some((item) => item === course)}
                />
                <ListItemText
                  primaryTypographyProps={listTextStyle}
                  primary={`${course}`}
                />
              </MenuItem>
            ))
          : course.map((course) => (
              <MenuItem key={course} value={course} sx={menuStyle}>
                <Checkbox
                  sx={checkboxStyle}
                  checked={value.some((item) => item === course)}
                />
                <ListItemText
                  primaryTypographyProps={listTextStyle}
                  primary={`${course}`}
                />
              </MenuItem>
            ))}
      </Select>
    </>
  );
};

const MultipleSelectRegion = ({ value, setValue, input, label }) => {
  const handleChange = (e) => {
    const tempSelectedRegion = region.filter((region) =>
      e.target.value.includes(region)
    );

    setValue(tempSelectedRegion);
  };

  return (
    <>
      <InputLabel id="demo-multiple-checkbox-label" sx={inputLableStyle}>
        {input}
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.map((x) => `${x}`).join(', ')}
        MenuProps={MenuProps}
        sx={selectStyle}
      >
        {region
          .slice(0)
          .sort()
          .map((item) => (
            <MenuItem key={item} value={item} sx={menuStyle}>
              <Checkbox
                sx={checkboxStyle}
                checked={value.some(
                  (selectedRegion) => selectedRegion === item
                )}
              />
              <ListItemText
                primaryTypographyProps={listTextStyle}
                primary={`${item}`}
              />
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

const MultipleFilterSelect = ({ value, setValue }) => {
  const handleQueryChange = (e) => {
    setValue(
      // On autofill we get a stringified value.
      typeof e.target.value === 'string'
        ? e.target.value.split(',')
        : e.target.value
    );
  };
  const handleDelete = (e, value) => {
    e.preventDefault();
    console.log('clicked delete');
    setValue((current) => _without(current, value));
  };

  return (
    <>
      <InputLabel id="demo-multiple-chip-label" sx={inputLableStyle}>
        Filter
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleQueryChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        sx={selectStyle}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                sx={chipStyle}
                key={value}
                label={value}
                clickable
                deleteIcon={
                  <CancelIcon
                    sx={{ color: '#4C12A1', cursor: 'pointer' }}
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={(e) => handleDelete(e, value)}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {filter.map((name) => (
          <MenuItem key={name} value={name} sx={menuStyle}>
            <Checkbox checked={value.includes(name)} sx={checkboxStyle} />
            {name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const MultipleSelectCoachForStudent = ({
  coachData,
  selectedCoachIdForStudent,
  setSelectedCoachIdForStudent,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setSelectedCoachIdForStudent(duplicateRemoved);
  };
  return (
    <>
      <InputLabel id="demo-multiple-checkbox-label" sx={inputStyle}>
        Filter by Coach
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label "
        id="demo-multiple-checkbox"
        multiple
        value={selectedCoachIdForStudent}
        onChange={handleChange}
        input={<OutlinedInput label="Select Coach" />}
        renderValue={(selected) =>
          selected.map((x) => `${x.firstName} ${x.lastName}`).join(', ')
        }
        MenuProps={MenuProps}
        sx={selectStyle}
      >
        {coachData
          .slice(0)
          .sort(function (a, b) {
            let firstNameComparison = a.firstName
              .toLowerCase()
              .localeCompare(b.firstName.toLowerCase());

            if (firstNameComparison === 0) {
              return a.lastName
                .toLowerCase()
                .localeCompare(b.lastName.toLowerCase());
            }

            return firstNameComparison;
          })
          .map((coach, index) => (
            <MenuItem key={coach.id} value={coach} sx={menuStyle}>
              <Checkbox
                checked={
                  selectedCoachIdForStudent.findIndex(
                    (item) => item.id === coach.id
                  ) >= 0
                }
                sx={checkboxStyle}
              />
              <ListItemText
                primaryTypographyProps={listTextStyle}
                primary={`${coach.firstName} ${coach.lastName}`}
              />
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

const MultipleSelectDocument = ({ value, setValue, input, label }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {documentType.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectDocumentAnalysisStatus = ({ value, setValue, input, label }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {documentAnalysisStatus.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectDocumentAnalysisBulkAction = ({
  value,
  setValue,
  input,
  label,
}) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {documentAnalysisBulkAction.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectTimeAllowed = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {timeAllowed.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectAssementStatus = ({ input, label, value, setValue }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select
        sx={selectStyle}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {assessmentStatus.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const SelectQuestionFormatTemplate = ({ input, label, value, onChange }) => {
  return (
    <>
      <InputLabel sx={inputLableStyle}>{input}</InputLabel>
      <Select sx={selectStyle} value={value} label={label} onChange={onChange}>
        {questionOptions.map((item, index) => {
          return (
            <MenuItem key={index} value={item} sx={menuStyle}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export {
  college,
  country,
  region,
  inputLableStyle,
  selectStyle,
  menuStyle,
  listTextStyle,
  checkboxStyle,
  SelectUserRole,
  SelectCourse,
  SelectRegion,
  SelectDateRange,
  SelectCountry,
  SelectState,
  SelectCollege,
  SelectGradeStatus,
  MultipleSelectCoach,
  MultipleSelectCourse,
  MultipleSelectRegion,
  MultipleFilterSelect,
  MultipleSelectCoachForStudent,
  SelectDocName,
  SelectOwner,
  SelectType,
  SelectTags,
  SelectResumeStatus,
  DocumentType,
  MultipleSelectDocument,
  SelectDocumentAnalysisStatus,
  SelectDocumentAnalysisBulkAction,
  SelectTimeAllowed,
  SelectAssementStatus,
  SelectQuestionFormatTemplate,
};
