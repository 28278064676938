import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { InputAdornment, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { MainContent } from 'components/sidebar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/Dashboard.module.css';

import LoadingOverlay from 'components/LoadingOverlay';

import organizationAPI from 'api/api';

const LearnerDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [admissionList, setAdmissionList] = useState([]);
  const [user, setUser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [startNew, setStartNew] = React.useState(false);
  const navigate = useNavigate();

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
  };

  const handleStartNew = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      templateName,
    };

    const response = organizationAPI.createAdmissionTemplate(data);
    setOpen(false);

    setLoading(false);
  };

  const handleEdit = (e, admissionId) => {
    e.preventDefault();
    navigate(`/coach/admission/submission/${admissionId}`);
  };

  const handleView = (e, admissionId) => {
    e.preventDefault();
    navigate(`/coach/admission/essay/${admissionId}`);
  };
  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const getAdmissionTemplate = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAllAdmissionTemplates();
      setAdmissionList(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getAdmissionTemplate();
  }, []);

  return (
    <MainContent active="admission" user={user}>
      {loading && <LoadingOverlay />}

      <div className={styles.content}>
        <h1>Welcome, {user && user.firstName + ' ' + user.lastName}</h1>
        <p>You last logged in at 9:01 PM on May 2, 2023.</p>
        <div className={styles.border}></div>
        <div className={styles.learner_header}>
          <h2>Admission Tier Assessment Submissions</h2>
          <div className={styles.learner_header_buttons}>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.modal_view}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Create New Submission Template
                </Typography>

                <div className={styles.border}></div>
                <div className={styles.modal_content}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="NAME OF SUBMISSION TEMPLATE"
                    placeholder="Enter Submission Template Name"
                    variant="outlined"
                    multiline
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    className={styles.name_input}
                  />

                  <button onClick={handleStartNew} className={styles.start}>
                    Start Now
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <div className={styles.coach_roaster}>
          <div className={styles.learner_table}>
            <table className="styled_table">
              <thead className="table_headers">
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Essays</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {admissionList.length > 0 &&
                  admissionList.map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>{item.templateName}</td>
                        <td className={styles.data}>
                          {convertDate(item.createdAt)}
                        </td>
                        <td className={styles.data}>{item.totalEssay}</td>

                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <EditIcon />
                              <button onClick={(e) => handleEdit(e, item._id)}>
                                View Profile
                              </button>
                            </div>
                            <div className={styles.resend}>
                              <VisibilityOutlinedIcon />
                              <button onClick={(e) => handleView(e, item._id)}>
                                View Essays
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {admissionList.length === 0 && (
              <div className={styles.empty_table}>
                <p>No submissions have been made yet.</p>
                <p onClick={handleOpen} className={styles.link}>
                  Click here to make a new submission template.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default LearnerDashboard;
