import { Message } from 'components/CustomMUI';
import { useEffect, useRef } from 'react';
import styles from './styles/Style.module.css';
const SkeletonLoader = ({ left }) => {
  return (
    <section
      className={`${styles.chat} ${
        left ? styles.alignLeft : styles.alignRight
      }`}
    >
      <figure className={`${styles.chatAvatar} ${styles.loading}`}></figure>
      <div
        className={`${styles.chatContent} ${
          left ? styles.alignStart : styles.alignEnd
        }`}
      >
        <div className={`${styles.chatTextSmall} ${styles.loading}`}></div>
        <div className={`${styles.chatTextLarge} ${styles.loading}`}></div>
      </div>
    </section>
  );
};

const Chatbox = ({ data }) => {
  const chatContainerRef = useRef(null);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [data]);

  if (data && data.length > 0) {
    return (
      <div ref={chatContainerRef} className={styles.ai_chat_container}>
        {data.map((item, index) => (
          <Message key={index} data={item} />
        ))}
      </div>
    );
  } else {
    return (
      <div className={styles.ai_chat_container}>
        <SkeletonLoader left={true} />
        <SkeletonLoader left={false} />
        <SkeletonLoader left={true} />
        <SkeletonLoader left={false} />
        <SkeletonLoader left={true} />
      </div>
    );
  }
};

export default Chatbox;
