import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Stack, Typography } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { useUserContext } from 'pages/Settings/context';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { colorPalette } from 'theme/colors';
import { FormField } from '../common';
import { DisabledRoleOptions } from '../common/components/DisabledRoleOptions';
import { addAdmin } from './validation/schema';
const blankAdminInfo = {
  firstName: '',
  lastName: '',
  email: '',
  role: 'ADMIN',
  course: '',
  region: '',
};

export const AdminAdd = () => {
  const [userInfo, setUserInfo] = useState(blankAdminInfo);
  const { post, isPosting } = useUserContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addAdmin),
    mode: 'onChange',
    defaultValues: { role: 'ADMIN' },
  });

  const adminAdd = async (data) => {
    await post(data);
    reset();
  };

  const renderFormFields = () => {
    const fields = [
      { name: 'firstName', label: 'First Name', type: 'text' },
      { name: 'lastName', label: 'Last Name', type: 'text' },
      { name: 'email', label: 'Email', type: 'text' },
    ];

    return fields.map((field) => (
      <FormField
        formType="ADD"
        key={field.name}
        field={field}
        register={register}
        errors={errors}
        watch={watch}
        state={userInfo}
        stateUpdater={setUserInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        px: 2,
        py: 4,
        borderRadius: 2,
      }}
      component="form"
      onSubmit={handleSubmit(adminAdd)}
    >
      {isPosting && <LoadingOverlay />}
      <Stack direction="column" spacing={4}>
        <Typography variant="h6">Invite Personnel</Typography>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields()}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DisabledRoleOptions selectedRole={'ADMIN'} />
          <Button
            variant="contained"
            color="success"
            startIcon={<SendIcon />}
            type="submit"
          >
            Send Invite
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AdminAdd;
