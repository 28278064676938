import { useCallback, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

const TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3MGYwNjc3M2M0NzY1NTUzYTYxZmMwYiIsInR5cGUiOiJBRE1JTiIsIm9yZ2FuaXphdGlvbklkIjoiNjcwZjA1ZTMzYzQ3NjU1NTNhNjFmYmZmIiwiaWF0IjoxNzI5ODMyNzMyLCJleHAiOjE3MzI0MjQ3MzJ9.R0Wh4KI_rEN347f7esbz7NfUS4kADjw15ageG4MSbaE';
const SERVER_URL = 'http://localhost:5000';

export function useAudioTranscription() {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const socketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const conversationSegmentsRef = useRef([]);

  // Socket initialization and listeners remain the same
  useEffect(() => {
    const initializeSocket = () => {
      try {
        console.log('Initializing socket connection...');
        socketRef.current = io(SERVER_URL, {
          transports: ['websocket'],
          auth: { token: TOKEN },
          reconnection: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000,
        });

        setupSocketListeners();

        return () => cleanup();
      } catch (err) {
        setError('Socket initialization error: ' + err.message);
        console.error('Socket initialization error:', err);
      }
    };

    initializeSocket();
  }, []);

  const setupSocketListeners = () => {
    if (!socketRef.current) return;

    socketRef.current.on('connect', () => {
      console.log('Socket connected successfully');
      setError(null);
    });

    socketRef.current.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
      setError('Connection error: ' + err.message);
    });

    socketRef.current.on('transcript', (newTranscript) => {
      console.log('Received transcript:', newTranscript);
      if (newTranscript?.trim()) {
        setTranscript((prev) => (prev + ' ' + newTranscript).trim());
      }
    });

    socketRef.current.on('llm-response', async (data) => {
      console.log('Received LLM response:', data);
      if (data?.answer) {
        await processServerResponse(data.answer);
      }
    });
  };

  const cleanup = () => {
    console.log('Cleaning up resources...');

    if (mediaRecorderRef.current?.state === 'recording') {
      mediaRecorderRef.current.stop();
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }

    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    conversationSegmentsRef.current = [];
    setIsRecording(false);
  };

  const processServerResponse = async (text) => {
    try {
      console.log('Processing server response:', text);
      setIsProcessing(true);

      const response = await fetch(
        `${SERVER_URL}/api/v1/conversationalAI/getAudio`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
          body: JSON.stringify({ text, model: 'aura-asteria-en' }),
        }
      );

      if (!response.ok) {
        throw new Error(`Server response error: ${response.status}`);
      }

      const audioBlob = await response.blob();
      console.log('Received audio response of size:', audioBlob.size);

      // Store the server's audio response
      conversationSegmentsRef.current.push({
        type: 'server',
        timestamp: Date.now(),
        audio: audioBlob,
      });

      await playAudio(audioBlob);
    } catch (err) {
      console.error('Server response error:', err);
      setError('Server response error: ' + err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const playAudio = async (audioBlob) => {
    try {
      console.log('Playing audio...');
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      await audio.play();
      URL.revokeObjectURL(audioUrl);
    } catch (err) {
      console.error('Audio playback error:', err);
    }
  };

  const getMicrophone = useCallback(async () => {
    try {
      console.log('Requesting microphone access...');
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      streamRef.current = stream;

      // Create MediaRecorder with MP3 or WebM format
      const recorder = new MediaRecorder(stream);

      recorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          console.log('Received audio chunk of size:', event.data.size);

          // Store the audio chunk
          conversationSegmentsRef.current.push({
            type: 'user',
            timestamp: Date.now(),
            audio: event.data,
          });

          // Send to server
          socketRef.current?.emit('packet-sent', event.data);
        }
      };

      mediaRecorderRef.current = recorder;
      return recorder;
    } catch (err) {
      console.error('Microphone access error:', err);
      setError('Microphone access error: ' + err.message);
      return null;
    }
  }, []);

  const toggleRecording = useCallback(async () => {
    try {
      console.log('Toggling recording state...');
      if (isRecording) {
        console.log('Stopping recording...');
        mediaRecorderRef.current?.stop();
        streamRef.current?.getTracks().forEach((track) => track.stop());
        setIsRecording(false);
        socketRef.current?.emit('call-initiation', 'end-of-call');
      } else {
        console.log('Starting recording...');
        const mic = await getMicrophone();
        if (mic) {
          conversationSegmentsRef.current = [];
          setTranscript('');
          setError(null);
          mic.start(1000);
          setIsRecording(true);
          socketRef.current?.emit('call-initiation', 'start-of-call');
          socketRef.current?.emit('persona-id', '670f0d75703fd573ac01018e');
        }
      }
    } catch (err) {
      console.error('Recording toggle error:', err);
      setError('Recording toggle error: ' + err.message);
    }
  }, [isRecording, getMicrophone]);

  const createEmptyAudioFile = async (duration) => {
    const sampleRate = 44100;
    const numberOfChannels = 2;
    const frameCount = sampleRate * duration;

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const buffer = audioContext.createBuffer(
      numberOfChannels,
      frameCount,
      sampleRate
    );

    const blob = await new Promise((resolve) => {
      const mediaStreamDestination =
        audioContext.createMediaStreamDestination();
      const mediaRecorder = new MediaRecorder(mediaStreamDestination.stream);
      const chunks = [];

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () =>
        resolve(new Blob(chunks, { type: 'audio/webm' }));

      mediaRecorder.start();
      setTimeout(() => mediaRecorder.stop(), 100);
    });

    return blob;
  };

  const downloadMergedAudio = useCallback(async () => {
    try {
      console.log('Starting download process...');
      if (conversationSegmentsRef.current.length === 0) {
        setError('No audio available to download');
        return;
      }

      // Sort segments by timestamp
      const sortedSegments = [...conversationSegmentsRef.current].sort(
        (a, b) => a.timestamp - b.timestamp
      );

      console.log('Number of segments to merge:', sortedSegments.length);

      // Add small silence between segments to prevent audio overlapping
      const silenceBlob = await createEmptyAudioFile(0.5); // 0.5 seconds of silence

      // Create array of audio blobs with silence in between
      const audioBlobs = [];
      for (let i = 0; i < sortedSegments.length; i++) {
        audioBlobs.push(sortedSegments[i].audio);
        if (i < sortedSegments.length - 1) {
          audioBlobs.push(silenceBlob);
        }
      }

      // Create final blob with all segments
      const finalBlob = new Blob(audioBlobs, { type: 'audio/webm' });
      console.log('Final blob size:', finalBlob.size);

      // Download the file
      const url = URL.createObjectURL(finalBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `conversation-${Date.now()}.webm`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Download error:', err);
      setError('Download error: ' + err.message);
    }
  }, []);

  return {
    isRecording,
    isProcessing,
    transcript,
    error,
    toggleRecording,
    downloadMergedAudio,
    hasAudio: conversationSegmentsRef.current.length > 0,
  };
}
