import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TagInput } from 'components/CustomMUI';
import { useState } from 'react';

const EditSalesRepresentative = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    type: '',
    team: [],
  });

  const handleSave = (e) => {
    e.preventDefault();
    onClose();
    console.log(formData);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleTeamChange = (team) => {
    setFormData((prevState) => ({
      ...prevState,
      team,
    }));
  };

  const handleTypeChange = (type) => {
    setFormData((prevState) => ({
      ...prevState,
      type,
    }));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { width: '50%' } }}
    >
      <Box sx={{ width: '95%', padding: 2 }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1, color: 'black' }}>
              Edit Sales Representative
            </Typography>
            <Button
              onClick={onClose}
              sx={{
                color: '#5B706F',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #5B706F',
                borderRadius: '5px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#D6DAD9FF',
                },
              }}
            >
              <CloseIcon />
              <Typography sx={{ marginLeft: 1 }}>Discard</Typography>
            </Button>
            <Button
              onClick={handleSave}
              sx={{
                color: 'green',
                display: 'flex',
                alignItems: 'center',
                marginLeft: 2,
                border: '1px solid #00706C',
                borderRadius: '5px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#D6FFF5FF',
                },
              }}
            >
              <CheckIcon />
              <Typography sx={{ marginLeft: 1 }}>Save</Typography>
            </Button>
          </Toolbar>
        </AppBar>
        <Divider />
        <Box
          sx={{
            marginY: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <TextField
            label="Firstname"
            variant="outlined"
            fullWidth
            value={formData.firstName}
            onChange={(e) => handleInputChange('firstName', e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Lastname"
            variant="outlined"
            fullWidth
            value={formData.lastName}
            onChange={(e) => handleInputChange('lastName', e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            sx={{ marginBottom: 2 }}
            type="email"
          />
          <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
            <InputLabel id="select-option-1-label" sx={{ color: 'black' }}>
              Type
            </InputLabel>
            <Select
              labelId="select-option-1-label"
              value={formData.type}
              onChange={(e) => handleTypeChange(e.target.value)}
              label="Select Option 1"
              sx={{ color: 'black' }}
            >
              <MenuItem value="" disabled>
                Select User Type
              </MenuItem>
              <MenuItem value={'ADMIN'}>ADMIN</MenuItem>
              <MenuItem value={'SALES REPRESENTATIVE'}>
                SALES REPRESENTATIVE
              </MenuItem>
            </Select>
          </FormControl>
          <TagInput
            label={'Select Team'}
            isInput={false}
            onChange={handleTeamChange}
            value={formData.team}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditSalesRepresentative;
