import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

const SearchSelect = ({ title, options, onChange, _value = null }) => {
  const [value, setValue] = useState(_value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        marginTop: 4,
        marginBottom: 4,
      }}
    >
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option) => option.label || ''}
        renderInput={(params) => <TextField {...params} label={title} />}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: 2,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
              borderColor: '#bdbdbd',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1976d2',
            },
          },
        }}
      />
    </Box>
  );
};

export default SearchSelect;
