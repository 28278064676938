import Diversity2Icon from '@mui/icons-material/Diversity2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EmptyState } from 'components/CustomMUI';
import useFormattedTime from 'hooks/useFormatTime';

const RaisedQuestionCard = ({ data }) => {
  const { formatTime } = useFormattedTime();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '15px',
          alignItems: 'center',
          my: '10px',
        }}
      >
        <Box
          component="img"
          src="/user.jpg"
          alt={data.time}
          sx={{
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
        <Typography
          sx={{
            color: '#00706c',
            fontFamily: 'Lexend',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
          }}
        >
          {formatTime(data.time)}
        </Typography>
        <Typography
          sx={{
            color: '#00706c',
            fontFamily: 'Lexend',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
          }}
        >
          {data.message}
        </Typography>
      </Box>
      <Box
        sx={{
          border: '1px solid #dae8e7',
          backgroundColor: '#fff',
          borderRadius: '5px',
          width: '96%',
          padding: '15px',
          alignSelf: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            color: '#40806a',
            fontFamily: 'Lexend',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
          }}
        >
          <Diversity2Icon />
          <Typography>Peer Answer Comparisons</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '10px',
            my: '10px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontStyle: 'normal',
              lineHeight: 1,
            }}
          >
            Your Answer
          </Typography>
          <Typography
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
            }}
          >
            {data.userResponse}
          </Typography>
          <Typography
            sx={{
              color: '#5b706f',
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: '5px',
              marginBottom: '5px',
            }}
          >
            Answers to this question from peers have included:
          </Typography>
        </Box>
        {data.peerResponses &&
          data.peerResponses.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: '10px',
                margin: '15px 0',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  color: '#40806a',
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  marginTop: '5px',
                  lineHeight: 0,
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  marginTop: '5px',
                }}
              >
                {item.response}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

const QuestionRaised = ({ data, audioUrl }) => {
  return (
    <Box
      sx={{
        width: '97%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {/* {audioUrl && <MediaPlayerProgressBar audioSrc={audioUrl} width="50%" />} */}

        {/* <Typography sx={{
          color: '#5b706f',
          fontFamily: 'Lexend',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 300,
          textWrap: 'nowrap',
        }}>
          Click on specific question points to quickly jump to that question.
        </Typography> */}
      </Box>
      {data && data.length > 0 ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'start',
          }}
        >
          {data.map((item, index) => (
            <RaisedQuestionCard key={index} data={item} />
          ))}
        </Box>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
};

export default QuestionRaised;
