import { ChevronLeft, Download, Replay } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Header = ({ assessmentName, createdBy }) => {
  const navigate = useNavigate();
  return (
    <Stack spacing={2} direction="column" mb={5}>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography>View Assessment Script</Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={() => {
              navigate(-1);
            }}
          >
            Return to Candidate List
          </Button>
          <Button variant="outlined" startIcon={<Download />}>
            Export PDF
          </Button>
          <Button variant="outlined" startIcon={<Replay />}>
            Enable Retaking
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={10}>
        <TextField
          sx={{ flex: 1 }}
          size="small"
          label="Assessment Name"
          value={assessmentName ?? ''}
          disabled
        />
        <TextField
          sx={{ flex: 1 }}
          size="small"
          label="Created By"
          value={createdBy ?? ''}
          disabled
        />
      </Stack>
    </Stack>
  );
};

export default Header;
