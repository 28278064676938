import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import useStore from 'store/useStore';
import { useApi } from './../../hooks/useApi';
import EmptyState from './EmptyState';
import SearchSelect from './SearchSelect';

const SUPPORTED_FORMATS = ['.wav', '.mp3'];

const UploadAudio = () => {
  const { userId, organizationId } = useStore();
  const [open, setOpen] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [salesReps, setSalesReps] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioPreview, setAudioPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { setDocId } = usePersonaStore();
  const [submitted, setSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const { call: getAllPersonaCall } = useApi({
    fetcher: organizationAPI.getAllPersonas,
    successMessage: 'All persona fetched successfully',
  });

  const { call: getAllSalesRepsCall } = useApi({
    fetcher: organizationAPI.getAllSalesReps,
    successMessage: 'Fetched all teams successfully',
  });

  const { call: analyzeRecordingCall } = useApi({
    fetcher: organizationAPI.analyzeRecording,
    successMessage: 'Recording Uploaded successfully',
  });

  useEffect(() => {
    const fetchPersonasData = async () => {
      try {
        const response = await getAllPersonaCall();
        if (response?.data) {
          const filteredPersonas = response.data.filter(
            (persona) => String(persona.type).toLowerCase() === 'buyer'
          );

          const mappedPersonas = filteredPersonas.map((persona) => ({
            id: persona._id,
            label: persona.name,
          }));
          setPersonas(mappedPersonas);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSalesRepData = async () => {
      try {
        const response = await getAllSalesRepsCall(organizationId);
        if (response?.data) {
          const allSalesReps = response.data.map((salesRep) => ({
            id: salesRep._id,
            label: salesRep.firstName + ' ' + salesRep.lastName,
          }));

          setSalesReps(allSalesReps);

          const defaultSalesRep = allSalesReps.find((rep) => rep.id === userId);
          if (defaultSalesRep) {
            setSelectedSalesRep(defaultSalesRep);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPersonasData();
    fetchSalesRepData();
  }, [organizationId]);

  const validateFile = (file) => {
    const extension = '.' + file.name.split('.').pop().toLowerCase();
    return SUPPORTED_FORMATS.includes(extension);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (validateFile(file)) {
        setSelectedFile(file);
        setAudioPreview(URL.createObjectURL(file));
      } else {
        alert('Please upload a supported audio file format');
      }
    }
  };

  const handleFileInput = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (validateFile(file)) {
        setSelectedFile(file);
        setAudioPreview(URL.createObjectURL(file));
      } else {
        alert('Please upload a supported audio file format');
      }
    }
  };

  const handlePersonChange = (newPerson) => {
    setSelectedPerson(newPerson);
  };

  const handleSalesRepChange = (newSalesRep) => {
    setSelectedSalesRep(newSalesRep);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setAudioPreview(null);
  };

  const handleSubmit = async () => {
    if (!selectedFile || !selectedPerson) {
      setErrorMessage(
        'Please make sure both an audio file and a persona are selected!'
      );
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('personaId', selectedPerson.id);
    formData.append('userId', selectedSalesRep.id);
    console.log(formData);
    setSubmitted(true);
    setIsSuccess(false);
    setIsError(false);
    setErrorMessage(null);

    try {
      const response = await analyzeRecordingCall({
        data: formData,
      });

      if (response.status === 200) {
        setIsSuccess(true);
        if (response && response.data) {
          setDocId(response.data.docId);
          navigate(`/airoleplaycall/calldetails`);
        }
      } else {
        setIsError(true);
        setErrorMessage(
          'There was an error uploading the file. Please try again.'
        );
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsError(true);
      setErrorMessage('An unexpected error occurred. Please try again later.');
    } finally {
      setSubmitted(false);
    }
  };

  const togglePlayPause = () => {
    const audioElement = document.getElementById('audioPreview');
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const isButtonDisabled = !selectedFile || !selectedPerson;

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        startIcon={<FileUploadIcon />}
        sx={{
          color: '#00706c',
          border: '1px solid #00706c',
          borderRadius: '5px',
          textTransform: 'none',
          py: 1,
          px: 2,
          height: '43px',
          fontSize: '14px',
          fontWeight: 500,
          '&:hover': {
            borderColor: '#0284C7',
            backgroundColor: 'rgba(14, 165, 233, 0.04)',
          },
        }}
      >
        Upload Call
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            p: 2,
            maxWidth: '500px',
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 0,
            mb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'inherit',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#333',
            }}
          >
            Upload Call Recording
          </Typography>
          <IconButton
            onClick={() => setOpen(false)}
            size="small"
            sx={{
              color: '#637381',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          {errorMessage && (
            <Box
              sx={{ color: 'red', fontSize: '14px', fontWeight: 'bold', mb: 2 }}
            >
              {errorMessage}
            </Box>
          )}

          {!selectedFile ? (
            <Box
              component="label"
              htmlFor="file-input"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              sx={{
                border: '2px dashed',
                borderColor: dragActive ? '#2AA87E' : '#E5E7EB',
                borderRadius: 1,
                p: 4,
                pb: 8,
                mb: 4,
                backgroundColor: '#F9FAFB',
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                '&:hover': {
                  backgroundColor: '#F3F4F6',
                },
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <input
                id="file-input"
                type="file"
                accept={SUPPORTED_FORMATS.join(',')}
                onChange={handleFileInput}
                style={{ display: 'none' }}
              />
              <AttachFileIcon sx={{ fontSize: 40, color: '#637381', mb: 1 }} />
              <Typography sx={{ color: '#637381', fontSize: '14px' }}>
                Click here to attach your call recording,
                <br />
                or drag and drop it here from your file browser.
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#637381',
                  color: 'white',
                  py: 1.5,
                  px: 2,
                  fontSize: '13px',
                  width: 'calc(100% + 6px)',
                  position: 'absolute',
                  bottom: -2,
                  left: -2,
                }}
              >
                Supported formats: .wav, .mp3
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                width: '100%',
              }}
            >
              <audio id="audioPreview" src={audioPreview} preload="auto" />
              <IconButton onClick={togglePlayPause}>
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
              <Typography sx={{ fontSize: '14px', color: '#333' }}>
                {selectedFile.name}
              </Typography>
              <IconButton onClick={handleRemoveFile}>
                <CloseIcon sx={{ color: '#f44336' }} />
              </IconButton>
            </Box>
          )}

          {personas && personas.length > 0 ? (
            <SearchSelect
              title="Select Person"
              options={personas}
              onChange={handlePersonChange}
            />
          ) : (
            <EmptyState />
          )}

          {salesReps && salesReps.length > 0 ? (
            <SearchSelect
              title="Select Sales Rep"
              options={salesReps}
              _value={selectedSalesRep}
              onChange={handleSalesRepChange}
            />
          ) : (
            <EmptyState />
          )}

          <Button
            fullWidth
            variant="outlined"
            startIcon={
              submitted ? (
                <CircularProgress size={20} sx={{ color: '#4CAF50' }} />
              ) : isSuccess ? (
                <CheckCircleIcon sx={{ color: '#4CAF50' }} />
              ) : (
                <FileUploadIcon sx={{ color: '#fff' }} />
              )
            }
            onClick={handleSubmit}
            disabled={submitted || isButtonDisabled}
            sx={{
              color: '#fff',
              borderColor: '#fff',
              backgroundColor:
                submitted || isButtonDisabled ? '#fff' : '#00706c',
              borderRadius: '5px',
              height: '43px',
              textTransform: 'none',
              py: 1,
              fontSize: '14px',
              fontWeight: 500,
              '&:hover': {
                borderColor: '#0284C7',
                backgroundColor: '#184241FF',
                color: '#fff',
              },
            }}
          >
            {submitted
              ? 'Analysing Audio ...'
              : isSuccess
              ? 'Audio Successfully Analysed'
              : 'Upload Call Recording'}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadAudio;
