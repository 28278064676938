import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import IndividualCallHistory from './IndividualCallHistory';

export const ExpandableUserCallHistory = ({ data }) => {
  const [expand, setExpand] = useState(false);

  if (data) {
    const avatar = '/female.jpg';
    const imageUrl =
      data.avatar && data.avatar !== 'invalid' ? data.avatar : avatar;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
          alignItems: 'start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            width: '100%',
          }}
        >
          <Avatar
            src={imageUrl}
            alt={imageUrl}
            sx={{ width: 25, height: 25 }}
          />
          <Typography
            color="#5b706f"
            fontSize={14}
            fontWeight={500}
            fontFamily="Lexend"
            noWrap
          >
            {data.name}
          </Typography>
          <Divider
            sx={{ flexGrow: 1, height: 1, backgroundColor: '#bfcecd' }}
          />
          <IconButton onClick={() => setExpand(!expand)} sx={{ padding: 0 }}>
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        {expand ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {data.data.map((item, index) => (
              <IndividualCallHistory key={index} data={item} />
            ))}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  } else {
    return null;
  }
};

export default ExpandableUserCallHistory;
