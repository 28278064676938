import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useUser } from 'pages/Settings/context';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useConvoDashboardStore } from 'store';
import EditDialogWrapper from '../common/EditDialogWrapper';
import { addSR } from '../common/schema';

const blankSRInfo = {
  firstName: '',
  lastName: '',
  email: '',
  // teamsAssigned: '',
};

export const SalesRepEditDialog = () => {
  const [userInfo, setUserInfo] = useState(blankSRInfo);

  const dialogState = useConvoDashboardStore((state) => state.editDialog);
  const changeDialogState = useConvoDashboardStore(
    (state) => state.setEditDialog
  );
  const { data } = dialogState;
  const { update, isUpdating } = useUser({ userType: 'sales_rep' });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addSR),
    mode: 'onChange',
    defaultValues: { role: 'ADMIN' },
  });

  const updateSRInfo = async (data) => {
    const updatedUserInfo = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    };
    await update({ userId: data._id, data: updatedUserInfo });
    reset();
    changeDialogState({ isOpen: false, forItem: 'admin' });
  };

  useEffect(() => {
    const initialUserInfo = {
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      email: data?.email || '',
    };
    reset(data);
    setUserInfo(initialUserInfo);
  }, [data, reset]);

  const renderFormFields = () => {
    const fields = [
      {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email Address',
        type: 'text',
      },
      // {
      //   name: 'assignedTeams',
      //   label: 'Assigned Teams',
      //   type: 'multiSelect',
      //   options: [
      //     { value: 'alpha', label: 'Team Alpha' },
      //     { value: 'beta', label: 'Team Beta' },
      //   ],
      // },
    ];

    return fields.map((item) => (
      <FormField
        key={item.name}
        field={item}
        register={register}
        errors={errors}
        watch={watch}
        state={userInfo}
        stateUpdater={setUserInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <>
      {/* {isUpdating && <LoadingOverlay />} */}
      <EditDialogWrapper
        onSubmit={handleSubmit(updateSRInfo)}
        dialogType={'edit'}
        title={'Update SR Info'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {renderFormFields()}
        </Box>
      </EditDialogWrapper>
    </>
  );
};
