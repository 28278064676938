import PersonOffIcon from '@mui/icons-material/PersonOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import useModal from 'hooks/useConfirmationModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from 'store/useStore';
import EditSalesRepresentative from './EditSalesRepresentative';

const DropdownButton = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useStore();
  const { openModal, Modal } = useModal();
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleViewClick = () => {
    setAnchorEl(null);
    setUserData(data);
    navigate(`/view-sales-representative/`);
  };

  const handleEditClick = () => {
    setIsEditDrawerOpen(true);
    handleMenuClose();
  };

  const handleDrawerClose = () => {
    setIsEditDrawerOpen(false);
  };

  const handleDeleteClick = () => {
    console.log('Delete clicked');
    handleMenuClose();
    openModal({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this item?',
      icon: PersonOffIcon,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: () => console.log('Confirmed deletion'),
      onCancel: () => {
        console.log('Confirmed Canceled');
      },
    });
  };

  return (
    <Box sx={{ width: 'fit-content' }}>
      <Modal />
      <IconButton
        onClick={handleViewClick}
        sx={{
          color: '#40806A',
          display: 'flex',
          alignItems: 'center',
          border: 'none',
          boxShadow: 'none',
        }}
      >
        <VisibilityIcon sx={{ marginRight: '8px', fontSize: '16px' }} />
      </IconButton>

      <EditSalesRepresentative
        open={isEditDrawerOpen}
        onClose={handleDrawerClose}
      />
    </Box>
  );
};

export default DropdownButton;
