import { ErrorOutlineRounded } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/StudentAnalysis.module.css';
const Analysis = ({
  documentStatus,
  documentId,
  missingSlides,
  slides,
  topicCount,
  correctSequence,
  title,
  rubric,
  getDocumentById,
}) => {
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [loading, setLoading] = useState(false);
  const collaboratorList = [{ name: 'Photography' }, { name: 'Music' }];
  const [receivers, setReceivers] = useState([]); // [
  const [file, setFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  const [Back, setBack] = useState([]);

  const handleUploadDocument = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const rule = await organizationAPI.getResumeRuleId();
    let ruleId = rule.data.ruleId;;

    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('ruleId', ruleId);
    formData.append('documentId', documentId);

    const response = await organizationAPI.documentAnalyzerUploadDocument(
      formData
    );
    await getDocumentById();

    setLoading(false);
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.container_head}>
        <p>DOCUMENT ANALYSIS AND SUMMARY</p>
        <h2>{title}</h2>
      </div>
      <div className={styles.border}></div>

      <div
        className={
          documentStatus === 'pass' ? styles.success_text : styles.error_text
        }
      >
        <div
          className={
            documentStatus === 'pass'
              ? styles.success_header
              : styles.error_section
          }
        >
          <h3>
            {documentStatus === 'pass' ? (
              <>
                <CheckCircleIcon /> Congratulations, your document is complete!
              </>
            ) : (
              <>
                <ErrorOutlineRounded /> There are problems with the submitted
                document.
              </>
            )}
          </h3>
        </div>
        <p
          className={
            documentStatus === 'pass' ? styles.success_icon : styles.error_icon
          }
        >
          {documentStatus === 'pass' ? (
            <>
              {' '}
              <CheckCircleOutlineIcon /> 11 of 11 required slides are present
              and accounted for
            </>
          ) : (
            <>
              {' '}
              <ErrorIcon /> {11 - topicCount} of 11 slides are missing
            </>
          )}
        </p>
        <p
          className={correctSequence ? styles.success_icon : styles.error_icon}
        >
          {correctSequence ? (
            <>
              <CheckCircleOutlineIcon />
              All slides are in the correct order
            </>
          ) : (
            <>
              <ErrorIcon /> The slides are not in the correct order
            </>
          )}
        </p>
        <p
          className={
            missingSlides.length === 0 ? styles.success_icon : styles.error_icon
          }
        >
          {missingSlides.length === 0 ? (
            <>
              <CheckCircleOutlineIcon /> All slides are correctly filled out
            </>
          ) : (
            <>
              <ErrorIcon /> {missingSlides.length} slides are missing
            </>
          )}
        </p>
      </div>

      {documentStatus === 'fail' && (
        <div className={styles.error_header}>
          <h3>
            <ErrorIcon /> There are problems with the submitted document.
          </h3>

          <input
            type="file"
            onChange={handleUploadDocument}
            ref={hiddenFileInput}
            style={{ display: 'none' }} // Make the file input element invisible
          />

          <button
            className={styles.reupload_button}
            onClick={() => hiddenFileInput.current.click()}
          >
            <FileUploadIcon />
            Reupload Fixed Document for Reanalysis
          </button>
        </div>
      )}

      <div className={styles.intro}>
        {rubric &&
          rubric?.slides?.map((slide) => {
            return (
              <>
                <h4>{slide.criteriaName}</h4>
                <p>{slide.feedback}</p>
              </>
            );
          })}
        {/* <h4>Problem/Opportunity</h4>
        <h5>The Power of Language</h5>
        <p>
          Words are your paintbrush, emotions your canvas. Craft vivid imagery,
          evoke feelings, and shape perceptions. Use rhythm, repetition, and
          figures of speech to captivate.
        </p>
        <h5>Building Your Argument</h5>
        <p>
          Logic is your foundation, but emotion connects. Appeal to reason, but
          tug at heartstrings with anecdotes and metaphors. Anticipate
          objections, address them head-on, and leave no doubt.
        </p>
        <h4>Delivery Mechanisms</h4>
        <h5>Reaching Your Audience</h5>
        <p>
          Tailor your message to resonate with your listeners. Understand their
          values, beliefs, and concerns. Speak their language, find common
          ground, and invite them to your perspective.
        </p> */}
      </div>
    </>
  );
};

export default Analysis;
