import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import organizationAPI from 'api/api';
import { getBaseUrl } from 'api/config';
import { DeviceSelector } from 'components/CustomMUI';
import LoadingOverlay from 'components/LoadingOverlay';
import { MainContent } from 'components/sidebar';
import { useGetMe } from 'hooks';
import useDeepgram from 'hooks/useDeepgram';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Chatbox from './Chatbox';
import DeepgramCall from './DeepgramCall';
import styles from './styles/Style.module.css';

const OngoingCall = () => {
  const token = organizationAPI.getTokenFromLocalStorage();
  const { user, loading, setLoading } = useGetMe({});
  const baseUrl = getBaseUrl();
  const socketUrl = baseUrl;
  const [isMuted, setIsMuted] = useState(false);
  const location = useLocation();
  const personaId = location.pathname.split('/').pop();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  const {
    isRecording,
    transcript,
    toggleRecording,
    playAudio,
    responseData,
    isPlayingAudio,
    isIdle,
    error,
    docId,
    saveRecording,
  } = useDeepgram(token, socketUrl, isMuted, personaId);

  return (
    <MainContent active={'query'} user={user}>
      {loading ? <LoadingOverlay></LoadingOverlay> : null}
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              <h1>AI Roleplay Call </h1>
              <div className={styles.nav_right}>
                <button className={styles.nav_btn2} onClick={handleBackClick}>
                  <ChevronLeftIcon />
                  <p>Return</p>
                </button>
              </div>
            </div>
            <div className={styles.border}></div>
          </div>
          <div className={styles.container}>
            <div className={styles.container_split}>
              <div className={styles.container_left}>
                <DeepgramCall
                  toggleRecording={toggleRecording}
                  transcript={transcript}
                  playAudio={playAudio}
                  isRecording={isRecording}
                  isPlayingAudio={isPlayingAudio}
                  setIsMuted={setIsMuted}
                  isMuted={isMuted}
                  isIdle={isIdle}
                  error={error}
                  docId={docId}
                  user={user}
                  saveRecording={saveRecording}
                />
                <DeviceSelector />
              </div>
              <div className={styles.container_right}>
                <Chatbox data={responseData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default OngoingCall;
