import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
export const CandidateInfo = () => {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <Stack
      direction="row"
      color=""
      sx={{
        background: palette.background.paper,
        px: 4,
        py: 2,
        borderRadius: '10px',
      }}
      spacing={3}
    >
      <Box sx={{ flex: 1 }}>
        <Stack direction="column" spacing={2}>
          <Box>
            <Typography
              variant="caption"
              sx={{ color: palette.text.secondary }}
            >
              CANDIDATE
            </Typography>
            <Typography variant="subtitle2">Jane Doe</Typography>
            <Typography
              variant="caption"
              sx={{ color: palette.text.secondary }}
            >
              jd@hotmail.com
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="caption"
              sx={{ color: palette.text.secondary }}
            >
              TOTAL SCORE
            </Typography>
            <Typography variant="subtitle2">115/200</Typography>
          </Box>
        </Stack>
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Box sx={{ flex: 1 }}>
        <Stack direction="column" spacing={1}>
          <Typography variant="caption" sx={{ color: palette.text.secondary }}>
            RESULT
          </Typography>
          <Typography variant="subtitle2">
            Moderate Character Development
          </Typography>
          <Typography variant="caption" sx={{ color: palette.text.secondary }}>
            The respondent frequently displays positive character traits. They
            may have areas to work on but generally have good resilience,
            relationships, and a sense of responsibility.
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
