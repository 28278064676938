import apis from 'api/api';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { createSortedSelectOptions } from 'utils';

export const useCourse = () => {
  const [options, setOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [schools, setSchools] = useState([]);
  const {
    data,
    call: fetchCourses,
    isLoading: isFetching,
  } = useApi({
    fetcher: apis.getAllCourses,
  });

  const { call: deleteCourse, isLoading: isDeleting } = useApi({
    fetcher: (id) => apis.deleteCourse(id),
    successMessage: 'Course deleted successfully',
  });

  const { call: postCourse, isLoading: isPosting } = useApi({
    fetcher: (data) => {
      return apis.createCourse(data);
    },
    successMessage: 'Course created successfully',
  });

  const { call: updateCourse, isLoading: isUpdating } = useApi({
    fetcher: (data) => {
      return apis.updateCourse(data);
    },
    successMessage: 'Course updated successfully',
  });

  const get = async () => {
    await fetchCourses();
  };

  const post = async (data) => {
    await postCourse(data);
    get();
  };

  const update = async (data) => {
    await updateCourse(data);
    get();
  };

  const remove = async (id) => {
    await deleteCourse(id);
    get();
  };

  const isLoading = isDeleting || isPosting || isFetching || isUpdating;

  useEffect(() => {
    if (data && data.courses) {
      const options = createSortedSelectOptions(
        data.courses,
        'courseName',
        'courseName'
      );
      setOptions(options);
    }

    if (data && data.regions) {
      const options = createSortedSelectOptions(
        data.regions,
        'regionName',
        'regionName'
      );
      setRegions(options);
    }
    if (data && data.schools) {
      const options = createSortedSelectOptions(
        data.schools,
        'schoolName',
        'schoolName'
      );
      setSchools(options);
    }
  }, [data]);

  return {
    data,
    options,

    schools,
    regions,

    isLoading,
    isDeleting,
    isPosting,
    isFetching,
    isUpdating,

    get,
    post,
    update,
    remove,
  };
};
