import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import styles from '../../styles/AssessmentSummary.module.css';

export const AssessmentAnswer = ({ assessmentAnswer }) => {
  return (
    <div className={styles.grade_section}>
      {assessmentAnswer?.map((section) => (
        <div className={styles.assessment_answer_list}>
          <div className={styles.assessment_body}>
            <div className={styles.assement_answer_header}>
              <h3>{section.title}</h3>
              {/* <p>Score Subtotal: 17/20</p> */}
            </div>
            <div className={styles.border}></div>
          </div>
          {section.questions.map((question, index) => (
            <div className={styles.assessment_mcq}>
              <h2 className={styles.review_title_h2}>
                {`${index + 1}. ${question.question}`}
              </h2>

              <FormControl className={styles.mcq_radio} disabled>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={question.userAnswer}
                >
                  {question.options.map((option, index) => (
                    <FormControlLabel
                      value={option}
                      control={<Radio size="small" />}
                      label={option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
