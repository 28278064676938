import { createTheme } from '@mui/material/styles';
import { colorPalette } from './colors';

export const theme = createTheme({
  palette: colorPalette,
  typography: {
    fontFamily: '"Lexend", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colorPalette.success.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected .MuiTab-iconWrapper': {
            color: colorPalette.success.main,
          },
          minHeight: 37,
        },
      },
    },
  },
});
