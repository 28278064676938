import styles from '../../styles/AssessmentSummary.module.css';

export const GradeSummary = ({ gradeSummary }) => {
  return (
    <div className={styles.grade_section}>
      <div className={styles.grade_section_header_card}>
        <div className={styles.header_card_left}>
          <div>
            <h5>CANDIDATE</h5>
            <p>{`${gradeSummary?.user.firstName} ${gradeSummary?.user.lastName}`}</p>
            <span>{gradeSummary?.user.email}</span>
          </div>

          <div>
            <h5>TOTAL SCORE</h5>
            <p>
              {gradeSummary?.totalScore}/{gradeSummary?.maxTotalScore}
            </p>
          </div>
        </div>
        <div className={styles.header_card_right}>
          <h5>RESULT</h5>
          <p>{gradeSummary?.scoringBracket?.title}</p>
          <h4>{gradeSummary?.scoringBracket?.description}</h4>
        </div>
      </div>

      <div className={styles.grade_section_body}>
        {gradeSummary?.sectionScores.map((section, index) => (
          <>
            <div className={styles.grade_body_item}>
              <div className={styles.grade_body_item_header}>
                <h5>{section.sectionScoringBracket?.title}</h5>
                <p>
                  Score Subtotal: {section.sectionScore}/
                  {section.sectionMaxScore}
                </p>
              </div>
              <p>{section.sectionScoringBracket?.description}</p>
            </div>
            {index !== gradeSummary.sectionScores.length - 1 && (
              <div className={styles.border}></div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};
