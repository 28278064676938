import { Box, Typography, useTheme } from '@mui/material';

const SectionHeader = ({ title }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="h6"
      component="h2"
      color={theme.palette.text.secondary}
      sx={{ marginBottom: 2 }}
    >
      {title}
    </Typography>
  );
};

const SectionContent = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography variant="body1" paragraph color={theme.palette.text.secondary}>
      {children}
    </Typography>
  );
};

const ListItem = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography
      component="li"
      variant="body1"
      color={theme.palette.text.secondary}
    >
      {children}
    </Typography>
  );
};

const ReportSection = ({ title, children }) => (
  <Box sx={{ marginBottom: 3 }}>
    <SectionHeader title={title} />
    {children}
  </Box>
);

const KeyIdentifiersList = ({ items }) => {
  const theme = useTheme();

  return (
    <Box
      component="ul"
      sx={{
        paddingLeft: 2,
        marginBottom: 2,
        color: theme.palette.text.secondary,
      }}
    >
      {items.map((item, index) => (
        <ListItem key={index}>{item}</ListItem>
      ))}
    </Box>
  );
};

export const ProgressReport = ({ studentData }) => {
  const theme = useTheme();

  // You could pass these as props in a real application
  const keyIdentifiers = [
    'Improvement in analytical skills, particularly between Tests 1 and 4.',
    'Notable increase in performance after addressing weaknesses post-Test 3.',
    'Inconsistencies observed in attention to detail and focus between Tests 2 and 3.',
    'Final test score shows the highest level of achievement, reflecting adaptation and better strategies.',
  ];

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        px: 4,
        py: 2,
        borderRadius: '10px',
      }}
    >
      {/* Main Header */}
      <Typography
        variant="h5"
        component="h1"
        color={theme.palette.text.primary}
        sx={{ marginBottom: 3 }}
      >
        Progress Report
      </Typography>

      {/* Overview Section */}
      <ReportSection title="Overview">
        <SectionContent>
          The student participated in four assessments, showing a progression of
          performance across the tests. This report highlights the key areas of
          strength, areas needing improvement, and overall trends in their
          performance.
        </SectionContent>
      </ReportSection>

      {/* Growth Section */}
      <ReportSection title="Growth">
        <SectionContent>
          A steady improvement was observed, particularly in the final test. The
          student consistently performed well in areas requiring analytical
          thinking, with notable progress from Test 1 to Test 4.
        </SectionContent>
      </ReportSection>

      {/* Areas of Improvement Section */}
      <ReportSection title="Possible Areas of Improvement">
        <SectionContent>
          There were fluctuations in performance, especially between Tests 2 and
          3. Areas involving attention to detail showed some inconsistency,
          which impacted the results in Test 3.
        </SectionContent>
      </ReportSection>

      {/* Key Identifiers Section */}
      <ReportSection title="Key Identifiers">
        <KeyIdentifiersList items={keyIdentifiers} />
      </ReportSection>

      {/* Performance Trends Section */}
      <ReportSection title="Performance Trends">
        <SectionContent>
          Overall, the student improved by 77 points from Test 1 to Test 4,
          demonstrating increased understanding and concept application.
          However, a slight dip in Test 3 revealed issues with focus, which were
          addressed in the strong recovery seen in Test 4, with a 56-point
          boost. This reflects both areas of progress and occasional setbacks.
        </SectionContent>
      </ReportSection>

      {/* Recommendations Section */}
      <ReportSection title="Recommendations">
        <SectionContent>
          Further practice in areas requiring detailed analysis is suggested.
          Additionally, maintaining consistent study habits that contributed to
          the upward trend will ensure sustained progress.
        </SectionContent>
      </ReportSection>
    </Box>
  );
};

export default ProgressReport;
