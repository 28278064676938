export const createSelectOptions = (
  items = [],
  valueKey = 'id',
  labelKey = 'name'
) => {
  return items.map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
  }));
};
export const createSortedSelectOptions = (
  items = [],
  valueKey = 'id',
  labelKey = 'name'
) => {
  const options = createSelectOptions(items, valueKey, labelKey);
  return options.sort((a, b) => a.label.localeCompare(b.label));
};
