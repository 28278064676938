import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {
  DecreaseIcon,
  ExternalLinkIcon,
  IncreaseIcon,
} from 'svg/CustomSVGIcon';

export const Widget = ({
  title,
  info,
  percentage,
  increase = true,
  date,
  externalLink,
}) => {
  const checkInfo = (info) => {
    if (info === null || info === '' || info === undefined) {
      return true;
    }
    if (typeof info === 'number') {
      if (info === 0 || Math.abs(info) < Number.EPSILON) {
        return true;
      }
    }

    if (typeof info === 'string' && info.includes('/')) {
      const [numerator, denominator] = info.split('/').map(Number);
      if (denominator !== 0 && numerator === 0) {
        return true;
      }
    }

    return false;
  };

  return (
    <Box
      sx={{
        border: '1px solid #dae8e7',
        borderRadius: '8px',
        padding: '10px 30px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        gap: '10px',
        backgroundColor: '#fff',
      }}
    >
      <Typography
        color="#5b706f"
        fontSize={14}
        fontWeight={300}
        fontFamily="Lexend"
        sx={{
          marginTop: '15px',
        }}
      >
        {title}
      </Typography>

      {checkInfo(info) ? (
        <Typography
          color="#5B706F"
          fontSize={24}
          fontWeight={300}
          fontFamily="Lexend"
          lineHeight={1.3}
        >
          No Data Available
        </Typography>
      ) : (
        <>
          {info && (
            <Typography
              color="#000"
              fontSize={30}
              fontWeight={300}
              fontFamily="Lexend"
              lineHeight={1.3}
            >
              {info}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              {percentage && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  {increase ? <IncreaseIcon /> : <DecreaseIcon />}
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    sx={{ color: increase ? '#00706c' : '#e03838' }}
                  >
                    {percentage}% {increase ? 'increase' : 'decrease'}
                  </Typography>
                </Box>
              )}
              {date && (
                <Typography
                  fontSize={14}
                  color="#5b706f"
                  fontWeight={300}
                  sx={{ marginLeft: '6px', marginTop: '-5px' }}
                >
                  vs {date}
                </Typography>
              )}
            </Box>
            {externalLink && (
              <Link
                href={
                  externalLink.startsWith('http')
                    ? externalLink
                    : `https://${externalLink}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton>
                  <ExternalLinkIcon fill={'#00706C'} />
                </IconButton>
              </Link>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Widget;
