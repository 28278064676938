import { create } from 'zustand';

export const useConvoDashboardStore = create((set) => ({
  deleteDialog: { isOpen: false, forItem: '', onOk: () => {} },
  editDialog: { isOpen: false, forItem: '', onOk: () => {}, data: {} },

  setDeleteDialog: ({ isOpen, forItem, onOk }) => {
    set((state) => ({
      deleteDialog: {
        ...state.deleteDialog,
        isOpen,
        forItem,
        onOk,
      },
    }));
  },
  setEditDialog: ({ isOpen, forItem, onOk, data }) => {
    set((state) => ({
      editDialog: {
        ...state.deleteDialog,
        isOpen,
        forItem,
        onOk,
        data,
      },
    }));
  },
}));
