import { Cloud, LinkOff } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MainContent } from 'components/sidebar';
import { Tab } from 'components/Tab/Tab';
import { TabItems } from './common/const';

export const ConvoDashboard = () => {
  const Header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Dashboard</Typography>
      <Button
        color="error"
        variant="outlined"
        startIcon={<LinkOff />}
        endIcon={<Cloud color="info" />}
      >
        Disconnect Salesforce
      </Button>
    </Stack>
  );

  return (
    <MainContent active={'convo'}>
      <Stack direction={'column'} gap={5}>
        {Header}
        <Box sx={{ width: '100%' }}>
          <Tab tabItems={TabItems} />
        </Box>
      </Stack>
    </MainContent>
  );
};
