import { Dialog, DialogContent } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { Acknowledgement } from '../Acknowledgement';
import { DemographicInfo } from '../DemographicInfo';
import { useStudentDashboardContext } from '../context/dashboardContext';

export const ConsentDialog = () => {
  const { data, userInfoLoading } = useStudentDashboardContext();

  return userInfoLoading ? (
    <LoadingOverlay />
  ) : (
    <Dialog
      open={!data.isAcknowledged || !data.hasDemographicData}
      maxWidth="md"
      PaperProps={{ sx: { padding: 1 } }}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Acknowledgement />
        <DemographicInfo />
      </DialogContent>
    </Dialog>
  );
};
