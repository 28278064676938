import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

export const TagInput = ({ label, onChange, value = [], data = [] }) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleTagChange = (event, newTags) => {
    setInternalValue(newTags);
    if (typeof onChange === 'function') {
      onChange(newTags);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      const selectedTeam = data.find((team) => team.name === inputValue);
      if (
        selectedTeam &&
        !internalValue.some((team) => team._id === selectedTeam._id)
      ) {
        const newTags = [...internalValue, selectedTeam];
        handleTagChange(null, newTags);
        setInputValue('');
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Box sx={{ width: '100%', mx: 'auto' }}>
      <Autocomplete
        multiple
        options={data}
        value={internalValue}
        onChange={handleTagChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((tag) => {
            const { key, ...chipProps } = getTagProps({ index: tag._id });
            return (
              <Chip
                key={tag._id} 
                {...chipProps}
                label={tag.name}
                sx={{
                  backgroundColor: '#CDEEEEFF',
                  '&:hover': { backgroundColor: '#AECECEFF' },
                }}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder="Type a name..."
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: '14px',
                fontWeight: 300,
                fontFamily: 'Lexend, sans-serif',
                color: '#243030',
              },
            }}
            InputLabelProps={{
              style: {
                fontSize:
                  isFocused || internalValue.length > 0 ? '18px' : '14px',
                fontFamily: 'Lexend, sans-serif',
                color: '#243030',
              },
            }}
          />
        )}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#bdbdbd',
            },
            '&:hover fieldset': {
              borderColor: '#9e9e9e',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2196f3',
            },
          },
          '& .MuiInputLabel-outlined': {
            '&.MuiInputLabel-shrink': {
              backgroundColor: 'white',
              padding: '0 8px',
            },
          },
        }}
      />
    </Box>
  );
};

export default TagInput;
